import React from "react";
import "./ActivateSubscription.css";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useHistory } from "react-router-dom";
import { Button } from "@mui/material";
import theme from "../../utils/theme";

const PaymentScreen = () => {
  const history = useHistory();

  return (
    <div>
      <div
        style={{
          backgroundColor: "#fff",
          boxShadow: "-2px 0px 5px rgba(22, 107, 142, 0.25)",
          color: "black",
          display: "flex",
          width: "100%",
          alignItems: "center",
          gap: "1rem",
          flexDirection: "row",
          paddingLeft: "1rem",
          position: "fixed",
          top: 0,
        }}
        // className="pro-head"
      >
        <ArrowBackIcon
          onClick={() => history.push("/my-profile")}
          style={{
            color: "black",
          }}
        />
        <p style={{ color: "black" }}>Activate Subscription</p>
      </div>
      <div style={{marginTop:"7rem",display:"flex",alignItems:"center",justifyContent:"center",flexDirection:"column"}} >
        <img src="/images/payment.png" alt="not found" />

        <div style={{display:"flex",alignContent:"center",justifyContent:"center",gap:"1rem",marginTop:"2rem",width:"100%"}} >
          <img style={{width:"30px",height:"30px"}} src="/images/atm.png" alt="not found" />

          <div style={{display:"flex",alignItems:"center",justifyContent:"center"}} >
            <p style={{margin:0}} >Pay</p>
            <span style={{marginLeft:".8rem",fontSize:"28px"}} >₹6000</span>
          </div>


        </div>
          <p>to Confirm Payment for Premium Subscription</p>
          <Button onClick={()=>history.push("/Payment-Fail")} variant="contained" style={{width:"11rem",height:"2.8rem",borderRadius:"12px",marginTop:"1rem"}} >Pay Now</Button>
          <p style={{fontSize:".8rem",marginTop:"2rem"}} >*Clicking this button will redirect to payment screen</p>
      </div>

      <div style={{position:"fixed",bottom:0,display:"flex",alignItems:"center",justifyContent:"center",width:"100%",height:"8rem",backgroundColor:"#2182B1"}} >
        <div style={{width:"100%",height:"8rem",display:"flex",justifyContent:"center",alignItems:"center",color:"#fff",flexDirection:"column"}} >
          <p style={{margin:0}} >If  you need help for registration, <br/> Please Contact: 8943 999 997 </p>
          <hr style={{width:"90%"}} />
          <div style={{display:"flex",gap:"1rem"}} >
            <Button variant="contained" style={{backgroundColor:theme.palette.secondary.main }} >Whatsapp</Button>
            <Button variant="contained" style={{backgroundColor:theme.palette.secondary.main }} >Call Us</Button>
          </div>
        </div>
      </div>

    </div>
  );
};

export default PaymentScreen;
