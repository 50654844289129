import DataService from "../Dataservice";

const getDistrcit = async (id) => {
  let url = `common/districts/?state_id=${id}`;
  const data = await DataService.getWithoutToken(url);
  return data?.data;
};

const getCities = async (id) => {
  let url = `/common/cities/?state_id=${id}`;
  const data = await DataService.getWithoutToken(url);
  return data?.data;
};

const getState = async () => {
  let url = `/common/states/?country_id=${1}`;
  const data = await DataService.getWithoutToken(url);
  return data?.data;
};

const getCategory = async ({ search, limit, offset }) => {
  let url = "";
  if (search && limit) {
    url = `/common/categories/?search=${search}&limit=${limit}&offset=${offset}`;
  } else if (limit) {
    url = `/common/categories/?limit=${limit}&offset=${offset}`;
  }
  const data = await DataService.getWithoutToken(url);
  return data?.data;
};

const getNewsChannel = async ({ limit, offset }) => {
  let url = `/common/news_channel/list/?limit=${limit}&offset=${offset}`;
  const data = await DataService.getWithoutToken(url);

  return data?.data;
};

const getNewsPaper = async ({ limit, offset }) => {
  let url = `/common/news_paper/list/?limit=${limit}&offset=${offset}`;
  const data = await DataService.getWithoutToken(url);
  return data?.data;
};

const getSubCategory = async ({ id, search, limit, offset }) => {
  let url = "";
  if (id && search && limit && offset) {
    url = `/common/sub_categories/?category_id=${id}&search=${search}&limit=${limit}&offset=${offset}`;
  } else if (limit && id && offset) {
    url = `/common/sub_categories/?limit=${limit}&category_id=${id}&offset=${offset}`;
  } else if (limit && id && search) {
    url = `/common/sub_categories/?category_id=${id}&limit=${limit}&search=${search}`;
  } else if (limit && search && offset) {
    url = `/common/sub_categories/?limit=${limit}&search=${search}&offset=${offset}`;
  } else if (limit && id) {
    url = `/common/sub_categories/?limit=${limit}&category_id=${id}&offset=${offset}`;
  } else if (limit && offset) {
    url = `/common/sub_categories/?limit=${limit}&offset=${offset}`;
  } else if (limit && search) {
    url = `/common/sub_categories/?limit=${limit}&search=${search}&offset=${offset}`;
  } else {
    url = `/common/sub_categories/?limit=${limit}&offset=${offset}`;
  }

  const data = await DataService.getWithoutToken(url);
  return data?.data;
};

const getPublicServiceCategory = async ({ limit, offset }) => {
  let url = `/common/public_service_category/?limit=${limit}&offset=${offset}`;
  const data = await DataService.getWithoutToken(url);
  return data?.data;
};

const getServiceLink = async ({ limit, offset }) => {
  let url = `/common/service_links/list/?limit=${limit}&offset=${offset}`;
  const data = await DataService.getWithoutToken(url);
  return data?.data;
};

export {
  getDistrcit,
  getCities,
  getState,
  getCategory,
  getNewsChannel,
  getNewsPaper,
  getSubCategory,
  getPublicServiceCategory,
  getServiceLink,
};
