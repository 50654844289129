import React from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useHistory } from "react-router-dom";
import { Button } from "@mui/material";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
const HelpSupport = () => {
  const history = useHistory();

  return (
    <div>
      <div
        style={{
          backgroundColor: "#fff",
          boxShadow: "-2px 0px 5px rgba(22, 107, 142, 0.25)",
          color: "black",
          display: "flex",
          width: "100%",
          alignItems: "center",
          gap: "1rem",
          flexDirection: "row",
          padding: "1rem",
        }}
      >
        <ArrowBackIcon
          onClick={() => history.push("/my-profile")}
          style={{
            color: "black",
          }}
        />
        <p style={{ color: "black" }}>Customer Support</p>
      </div>

      <div
        style={{
          width: "100%",
          height: "80vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          gap: "2rem",
        }}
      >
        <img src="/images/help.png" alt="" />
        <a href={`https://wa.me/91${8943999997}?text=Hello`} style={{width:"60%",textDecoration:"none"}}>
          <Button
            variant="outlined"
            style={{
              width: "100%",
              borderRadius: "24px",
              color: "white",
              backgroundColor: "rgba(33, 130, 177, 1)",
              textTransform: "none",
            }}
            startIcon={<WhatsAppIcon />}
          >
            Whatsapp
          </Button>
        </a>
      </div>
    </div>
  );
};

export default HelpSupport;
