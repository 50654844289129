import React from "react";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { Box, Modal, Typography } from "@mui/material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "250px",
  background: "white",
  borderRadius: "15px",
  boxShadow: 24,
  p: 4,
};

function UploadModal({ open, handleClose }) {
  return (
    <Modal open={open} onClose={handleClose}>
      <Box sx={style}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <CloudUploadIcon sx={{ fontSize: "60px", color: "green" }} />
          <Typography
            sx={{ fontSize: "20px", fontWeight: "600", color: "blue" }}
          >
            Image Uploading...
          </Typography>
        </Box>
      </Box>
    </Modal>
  );
}

export default UploadModal;
