import React from "react";
import { Route, Switch } from "react-router-dom";
import LanguageScreen from "../pages/Language screen/LanguageScreen";
import AuthHome from "../pages/auth/authHome/authHome";
import SignIn from "../pages/auth/Sign In/SignIn";
import SignUp from "../pages/auth/Sign Up/SignUp";
import SignInOtp from "../pages/auth/Sign In/signInOtp/SignInOtp";
import SignUpOtp from "../pages/auth/Sign Up/signUpOtp/SignUpOtp";
import CompleteRegistration from "../pages/auth/Sign Up/Complete Registration/CompleteRegistration";
import Home from "../pages/home/Home";
import CategoryListView from "../pages/Category list/CategoryListView";
import ProductDetails from "../pages/Product Details/ProductDetails";
import MyListing from "../pages/myListing/Dashboard";
import MyProfile from "../pages/Profile/MyProfile";
import Profile from "../pages/Profile/Profile";
import HelpSupport from "../pages/Profile/Help&support";
import AddBusiness from "../pages/myListing/Listing/Add business/AddBusiness";
import ActivateSubscription from "../pages/Subscription/ActivateSubscription";
import PaymentConfirmation from "../components/alert/Payment Confirmation/PaymentConfirmation";
import PaymentFailed from "../components/alert/Payment Failed/PaymentFailed";
import EditCoverPhoto from "../pages/myListing/Listing/Edit business/Edit Cover Photo/EditCoverPhoto";
import EditCatalogueGold from "../pages/myListing/Listing/Edit business/Edit Catalogue gold/EditCatalogueGold";
import SubscriptionPlan from "../pages/Subscription/subscription plan/SubscriptionPlan";
import LocationSearch from "../pages/Location Search/LocationSearch";
import NewsPaper from "../pages/news/NewsPaper/NewsPaper";
import NewsChannel from "../pages/news/NewsChannel/NewsChannel";
import SplashScreen from "../components/splash screen/SplashScreen";
import EditDetails from "../pages/myListing/Listing/Edit business/EditDetails";
import EditService from "../pages/myListing/Listing/Edit business/EditService";
import SubCategory from "../pages/Category list/SubCategory";
import CategoryDetails from "../pages/Category list/CategoryDetails";
import SearchScreen from "../pages/SearchScreen/Index";
import PublicServiceCenter from "../pages/PublicServiceCenter/Index";
import EditShopImage from "../pages/myListing/Listing/Edit business/Edit Shop Image/Index";
import CategorySearch from "../pages/Category list/CategorySearch";
import ServiceLink from "../pages/ServiceLink/Index";

const IndexRouter = () => {
  return (
    <>
      <Switch>
      <Route exact path="/" component={Home} />
      <Route exact path="/auth" component={AuthHome} />
   
        <Route exact path="/sign-in" component={SignIn} />
        <Route exact path="/sign-up" component={SignUp} />
        <Route exact path="/sign-in-otp" component={SignInOtp} />
        <Route exact path="/sign-up-otp" component={SignUpOtp} />
        <Route
          exact
          path="/complete-registration"
          component={CompleteRegistration}
        />
        <Route exact path="/product-details/:id" component={ProductDetails} />
        <Route exact path="/category-list-view" component={CategoryListView} />
        <Route exact path="/my-list" component={MyListing} />
        <Route exact path="/publicservice/:id" component={PublicServiceCenter} />
        <Route exact path="/my-profile" component={MyProfile} />
        <Route exact path="/profile" component={Profile} />
        <Route exact path="/help-support" component={HelpSupport} />
        <Route exact path="/add-business" component={AddBusiness} />
        <Route exact path="/service-link" component={ServiceLink} />
        <Route exact path="/subcategory-search" component={CategorySearch} />
        <Route
          exact
          path="/payment-screen"
          component={ActivateSubscription}
        />{" "}
        <Route
          exact
          path="/category/:id?/subCategory/:name"
          component={CategoryDetails}
        />
        <Route exact path="/searchscreen" component={SearchScreen} />
        <Route
          exact
          path="/Payment-confirmation"
          component={PaymentConfirmation}
        />
        <Route exact path="/Payment-Fail" component={PaymentFailed} />
        <Route exact path="/subscription-plan" component={SubscriptionPlan} />
        <Route exact path="/location-search" component={LocationSearch} />
        <Route exact path="/news-paper" component={NewsPaper} />
        <Route exact path="/news-channel" component={NewsChannel} />
        <Route exact path="/splash-screen" component={SplashScreen} />
        <Route exact path="/subcategory/:id" component={SubCategory} />
        <Route exact path="/edit-details/:id" component={EditDetails} />
        <Route exact path="/edit-coverimages/:id" component={EditCoverPhoto} />
        <Route exact path="/edit-shopimage/:id" component={EditShopImage} />
        <Route
          exact
          path="/edit-catelogueimages/:id"
          component={EditCatalogueGold}
        />
        <Route exact path="/edit-service/:id" component={EditService} />
      </Switch>
    </>
  );
};

export default IndexRouter;
