import { Box, Button, Typography } from "@mui/material";
import React from "react";
import theme from "../../../../utils/theme";
import "../../ActivateSubscription.css";
const PriorityListing = () => {
  return (
    <div
      style={{
        marginTop: "1rem",
        marginBottom: "1rem",
        width: "100%",
        border: "solid 3px #B1E2DB",
        borderRadius: "20px",
        paddingTop: "10px",
        paddingRight:"10px"
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <img src="/images/RewardBadge.png" alt="not found" />
        <h4>Standard Plan</h4>
      </div>
      <div
        style={{
          display: "flex",
        paddingLeft:"15px"
        }}
      >
        <Button
          variant="contained"
          style={{
            backgroundColor: theme.palette.primary.main,
            borderRadius: "10px",
            marginTop: "1rem",
          }}
        >
          Benefits
        </Button>
      </div>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <div
          style={{
            display: "flex",
            alignItems: "baseline",
            flexDirection: "column",
            marginTop: "1rem",
            marginBottom: "1rem",
            paddingLeft: "1rem",
          }}
        >
          <Box className="Box-container">
            <Box className="listing"></Box>Shop Name
          </Box>
          <Box className="Box-container">
            <Box className="listing"></Box>Place
          </Box>
          <Box className="Box-container">
            <Box className="listing"></Box>Shop Image
          </Box>
          <Box className="Box-container">
            <Box className="listing"></Box>Category
          </Box>
          <Box className="Box-container">
            <Box className="listing"></Box>All Address
          </Box>
          <Box className="Box-container">
            <Box className="listing"></Box>Phone number
          </Box>
          <Box className="Box-container">
            <Box className="listing"></Box>Google location
          </Box>
          <Box className="Box-container">
            <Box className="listing"></Box>Email
          </Box>
          <Box className="Box-container">
            <Box className="listing"></Box>
            WhatsApp Facebook & etc..
          </Box>
          <Box className="Box-container">
            <Box className="listing"></Box>About Us
          </Box>
          <Box className="Box-container">
            <Box className="listing"></Box>
            Add up to 6 Service details
          </Box>
          <Box className="Box-container">
            <Box className="listing"></Box>
            Add up to 6 Images in Catalogue
          </Box>
          <Box className="Box-container">
            <Box className="listing"></Box>
            Add up to 4 Images in Cover
          </Box>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
            <Box className="typo_container">
            <Typography className="typo_box_old">
              ₹ <Typography className="typo_old">2400 </Typography>
            </Typography>
            <Typography className="typo_box">
              ₹ <Typography  className="typo-new">1200 </Typography>
            </Typography>
          </Box>
          <Button
            variant="contained"
            style={{
              backgroundColor: theme.palette.primary.main,
              borderRadius: "10px",
              marginTop: "1rem",
              width:"136px",
              textTransform:"none"
            }}
          >
            Activate Now
          </Button>
        </div>
      </Box>
    </div>
  );
};

export default PriorityListing;
