import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "300px",
  background: "white",
  borderRadius: "15px",
  boxShadow: "inset 0 0 10px 6px rgb(165 156 162 / 88%)",
  padding: "25px",
};

function About({ open, handleClose }) {
  return (
    <Modal open={open} onClose={handleClose}>
      <Box sx={style}>
        <Typography
          sx={{
            textAlign: "center",
            fontWeight: "700",
            fontSize: "20px",
            marginBottom: "15px",
          }}
        >
          About Us
        </Typography>
        <Typography>
          The main motive for starting MasterClick is to establish a unified
          platform for businesses and consumers to interact effectively.
          Additionally, we aim to assist both small and large business owners in
          establishing their online presence. For those unable to create a
          business website, MasterClick will provide a platform to showcase
          their business and build an online presence.
        </Typography>
      </Box>
    </Modal>
  );
}

export default About;
