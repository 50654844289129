import React from 'react'
import MyListingCard from '../../../components/myListingCard/MyListingCard'
import ListNotFound from './ListNotFound/ListNotFound'

const Listing = () => {
  return (
    <div style={{height:"100%"}}>
     
      <MyListingCard/>
    </div>
  )
}

export default Listing