import React, { useEffect, useRef, useState } from "react";
import useCommonQuery from "../../store/common/useCommonQuery";
import {
  Box,
  InputAdornment,
  TextField,
  Typography,
  Grid,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useHistory } from "react-router-dom";
import useStyles from "../../SharedClass/sharedClass";
import SearchIcon from "@mui/icons-material/Search";
import CategoreyCard from "../../components/CategoreyCard";

function CategorySearch() {
  const history = useHistory();
  const classes = useStyles();

  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const [height, setHeight] = useState("");

  const inputRef = useRef();

  const query = {
    search,
    limit: 2000,
    id: "",
    offset: 0,
  };
  const { data: subcategoryList, isSuccess } =
    useCommonQuery.Get_subcategory(query);

  const handleClick = (name) => {
    history.push(`/category/subCategory/${name}`);
  };
  // window.addEventListener("scroll", function () {
  //   setHeight(window.pageYOffset);
  // });
  // console.log({ height });

  // const onScroll = () => {
  //   const scrollTop = document.documentElement.scrollTop;
  //   const scrollHeight = document.documentElement.scrollHeight;
  //   const clientHeight = document.documentElement.clientHeight;

  //   if (
  //     scrollTop + clientHeight >= scrollHeight &&
  //     !loading &&
  //     items.length !== subcategoryList?.data?.count
  //   ) {
  //     setLoading(true);
  //     setCurrentPage((prev) => prev + 1);
  //   }
  // };
  // useEffect(() => {
  //   window.addEventListener("scroll", onScroll);
  //   return () => window.removeEventListener("scroll", onScroll);
  // }, [height]);

  useEffect(() => {}, [search]);

  return (
    <div
      id="parent-div"
      ref={inputRef}
      style={{ height: "100vh", overflowY: "scroll" }}
    >
      <div
        style={{
          backgroundColor: "#fff",
          boxShadow: "-2px 0px 5px rgba(22, 107, 142, 0.25)",
          color: "black",
          padding: "10px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            width: "100%",
            alignItems: "center",
            gap: "1rem",
            marginBottom: "20px",
          }}
        >
          <CloseIcon
            onClick={() => history.push("/")}
            style={{
              color: "black",
            }}
          />
          <Typography style={{ fontSize: "16px", fontWeight: "600" }}>
            SubCategory
          </Typography>
        </Box>
        <TextField
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          className={classes.searchBar}
          placeholder="Search"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />{" "}
        <div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              paddingLeft: "1rem",
              paddingRight: "1rem",
              marginTop: "10px",
            }}
          >
            <h4>All Subcategories</h4>
          </div>
        </div>
        <Grid container spacing={2} style={{ padding: "1rem" }}>
          {subcategoryList &&
            subcategoryList?.data?.results?.map((items, idx) => {
              return (
                <CategoreyCard
                  id={items.id}
                  title={items.name}
                  handleClick={handleClick}
                  image={items.image}
                  key={idx}
                />
              );
            })}
        </Grid>
      </div>
    </div>
  );
}

export default React.memo(CategorySearch);
