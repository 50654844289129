import React from 'react'
import "./SplashScreen.css"
import masterclicksvg from "../../assets/masterclicksvg.svg"
const SplashScreen = () => {
  return (
    <div style={{display:"flex",alignItems:"center",flexDirection:"column",gap:"5rem"}} >
        <img style={{marginTop:"7rem"}} src={masterclicksvg} alt='not found' />
        <img src='/SplashScreen/111.svg' alt='not found' />
    </div>
  )
}

export default SplashScreen