import React from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useHistory } from "react-router-dom";
import { Button } from "@mui/material";
import theme from "../../../utils/theme";
import BasicPlan from "./Components/BasicPlan";
import PriorityListing from "./Components/PriorityListing";
import PremiumListing from "./Components/PremiumListing";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import PhoneTwoToneIcon from "@mui/icons-material/PhoneTwoTone";
const SubscriptionPlan = () => {
  const history = useHistory();

  return (
    <div>
      <div
        style={{
          backgroundColor: "#fff",
          boxShadow: "-2px 0px 5px rgba(22, 107, 142, 0.25)",
          color: "black",
          display: "flex",
          width: "100%",
          alignItems: "center",
          gap: "1rem",
          flexDirection: "row",
          padding: "1rem",
          position: "fixed",
          top: 0,
          zIndex: "1000",
        }}
      >
        <ArrowBackIcon
          onClick={() => history.push("/")}
          style={{
            color: "black",
          }}
        />
        <p style={{ color: "black" }}>Activate Subscription</p>
      </div>

      <div style={{ padding: "0px 10px" }}>
        <BasicPlan />
        <PriorityListing />
        <PremiumListing />
      </div>
      <div
        style={{
          position: "fixed",
          bottom: 0,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
          height: "7rem",
          backgroundColor: "#2182B1",
        }}
      >
        <div
          style={{
            width: "100%",
            height: "6rem",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: "#fff",
            flexDirection: "column",
            paddingBottom: "1a0px",
          }}
        >
          <p style={{ margin: 0 }}>
            If you need help for registration
          </p>
          <hr style={{ width: "90%" }} />
          <div style={{ display: "flex", gap: "1rem",marginTop:"10px" }}>
            <Button
              variant="contained"
              style={{
                backgroundColor: theme.palette.secondary.main,
                textTransform: "none",
              }}
              startIcon={<WhatsAppIcon />}
            >
              <a
                style={{ color: "white", textDecoration: "none" }}
                href="https://wa.me/918943999997?text=Hello"
              >
                Whatsapp
              </a>
            </Button>

     
          </div>
        </div>
      </div>
    </div>
  );
};

export default SubscriptionPlan;
