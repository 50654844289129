import DataService from "../Dataservice";

const getMyBussiness = async () => {
  let url = "/business/my_business/";
  const data = await DataService.get(url);
  return data?.data;
};

const addBusiness = async (values) => {
  let url = "/business/create/";
  const data = await DataService.post(url, values);
  return data?.data;
};

const getBusinessDetails = async (id) => {
  let url = `/business/my_business/${id}/`;
  const data = await DataService.get(url);
  return data?.data;
};

const deleteBusiness = async (id) => {
  let url = `/business/my_business/${id}/`;
  const data = await DataService.del(url);
  return data?.data;
};

const editBussiness = async (values) => {
  const { id, ...editData } = values;
  let url = `/business/my_business/${id}/`;
  const data = await DataService.patch(url, values);
  return data?.data;
};

const getSubcriptionPlan = async (id) => {
  let url = `/business/my_business/${id}/subscription_plans/`;
  const data = await DataService.get(url);
  return data?.data;
};

const getCurrentPlan = async (id) => {
  let url = `/business/my_business/${id}/current_plan/`;
  const data = await DataService.get(url);
  return data?.data;
};

const getBusinessList = async ({
  latitude,
  longitude,
  radius,
  category,
  subCategory,
  name,
  limit,
  offset,
}) => {
  let url = "";
  if (
    latitude &&
    longitude &&
    category &&
    subCategory &&
    radius &&
    name &&
    limit
  ) {
    url = `/business/list/?lat=${latitude}&lon=${longitude}&category=${category}&sub_category=${subCategory}&radius=${radius}&name=${name}&limit=${limit}`;
  } else if (
    latitude &&
    longitude &&
    category &&
    subCategory &&
    radius &&
    limit
  ) {
    url = `/business/list/?lat=${latitude}&lon=${longitude}&category=${category}&sub_category=${subCategory}&radius=${radius}&limit=${limit}`;
  } else if (latitude && longitude && radius && subCategory && name && limit) {
    url = `/business/list/?lat=${latitude}&lon=${longitude}&radius=${radius}&name=${name}&sub_category=${subCategory}&limit=${limit}`;
  } else if (latitude && longitude && radius && subCategory && limit) {
    url = `/business/list/?lat=${latitude}&lon=${longitude}&radius=${radius}&sub_category=${subCategory}&limit=${limit}`;
  } else if (latitude && longitude && radius && name && limit) {
    url = `/business/list/?lat=${latitude}&lon=${longitude}&radius=${radius}&name=${name}&limit=${limit}`;
  } else if (latitude && longitude && radius && limit) {
    url = `/business/list/?lat=${latitude}&lon=${longitude}&radius=${radius}&limit=${limit}`;
  }

  const data = await DataService.getWithoutToken(url);

  return data?.data;
};

const getBookmark = async () => {
  let url = "/business/bookmarks/list/";
  const data = await DataService.get(url);
  return data?.data;
};

const addBookmark = async (id) => {
  let url = `/business/business/${id}/bookmark/`;
  const data = await DataService.post(url, {});
  return data?.data;
};

const getRating = async (id) => {
  let url = `/business/${id}/rating/`;
  const data = await DataService.get(url);
  return data?.data;
};

const updateRating = async (values) => {
  const { id } = values;
  let url = `/business/${id}/rating/`;
  const data = await DataService.patch(url, values);
  return data?.data;
};

const getDetails = async (id) => {
  let url = `/business/${id}/detail/`;
  const data = await DataService.getWithoutToken(url);
  return data?.data;
};

const getTrialplanDetails = async () => {
  let url = "/business/subscription/trial_plan/";
  const data = await DataService.get(url);
  return data?.data;
};

const addViews = async (values) => {
  let url = "/business/add_viewers/";
  const data = await DataService.post(url, values);
  return data?.data;
};

const addCallers = async (values) => {
  let url = "/business/add_callers/";
  const data = await DataService.post(url, values);
  return data?.data;
};

export {
  addBusiness,
  getMyBussiness,
  deleteBusiness,
  getSubcriptionPlan,
  getBusinessDetails,
  editBussiness,
  getBusinessList,
  getBookmark,
  addBookmark,
  getCurrentPlan,
  getRating,
  updateRating,
  getDetails,
  getTrialplanDetails,
  addViews,
  addCallers,
};
