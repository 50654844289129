import React, { Fragment, useEffect, useState } from "react";
import { BsArrowRight } from "react-icons/bs";
import { useHistory, useParams } from "react-router-dom";
import { Box, Button, Grid, Typography } from "@mui/material";
import theme from "../../../../../utils/theme";
import { FaSave } from "react-icons/fa";
import CloseIcon from "@mui/icons-material/Close";
import useBusinessQuery from "../../../../../store/Bussiness/useBusinessQuery";
import useImageQuery from "../../../../../store/Images/useImageQuerys";
import ImageRemovalModal from "../../Add business/ImageRemovalModal";
import { RiDeleteBin5Line } from "react-icons/ri";
import "../EditBusiness.css";
import S3 from "react-aws-s3";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ErrorModal from "../../Add business/ErrorModal";
import SuccessModal from "../../Add business/SuccessModal";
import axios from "axios";
import UploadModal from "../../../../../components/UploadModal";
import { Buffer } from "buffer";
import { BsFillPenFill } from "react-icons/bs";

window.Buffer = Buffer;

const EditCatalogueGold = () => {
  const history = useHistory();
  const { id } = useParams();

  const [catelogue, setCatelogue] = useState([]);
  const [imageId, setImageId] = useState("");
  const [plan, setPlan] = useState({});
  const [openModal, setOpenModal] = useState(false);
  const [cateloguePhoto, setCateloguePhoto] = useState(false);
  const [poster, setPoster] = useState("");
  const [show, setShow] = useState("catelogue");
  const [openSucces, setOpensuccess] = useState(false);
  const [openError, setOpenError] = useState(false);
  const [uploadModal, setUploadModal] = useState(false);
  const [message, setMessage] = useState("");

  const envConfig = process.env;

  const config = {
    bucketName: envConfig.REACT_APP_S3_BUCKET,
    region: envConfig.REACT_APP_S3_REGION,
    dirName: "master-click",
    accessKeyId: envConfig.REACT_APP_S3_ACCESS_KEY_ID,
    secretAccessKey: envConfig.REACT_APP_S3_SECRET_ACCESS_KEY,
  };
  const ReactS3Client = new S3(config);

  const { data: subscriptionDetail, isSuccess: subscriptionSuccess } =
    useBusinessQuery.Get_my_currentplan(id);

  const { data: catelogueList, isSuccess: catelogueSuccess } =
    useImageQuery.Get_Catelogue(id);

  const {
    mutateAsync: addCatelogue,
    isError: addCatelogueIsError,
    error: addCatelogueError,
  } = useImageQuery.Add_catelogue();

  const { mutateAsync: deleteCatelogue } = useImageQuery.Del_catelogue();

  const { mutateAsync: editCatelogue } = useImageQuery.Edit_catelogue();

  const {
    mutateAsync: EditBusiness,
    isSuccess: editSuccess,
    isError: editError,
  } = useBusinessQuery.Edit_business();

  const { data: businessDetails, isSuccess: businessSuccess } =
    useBusinessQuery.Get_my_bussiness_details(id);

  const handleCloseUpload = () => {
    setUploadModal(false);
  };

  const handleCatelogueImage = async (e) => {
    if (e.target.files.length == 0) {
      return;
    } else {
      let url = null;
      setUploadModal(true);
      url = e.target.files[0];
      const data = await ReactS3Client.uploadFile(url);
      url = data.location;
      if (url) {
        try {
          await addCatelogue({
            catalogue_image: url,
            id,
            is_active: true,
          });
        } catch (error) {
          handleCloseUpload();
        }
      }
    }
    setCateloguePhoto(false);
  };

  const handlePremium = async (e) => {
    if (e.target.files.length == 0) {
      return;
    } else {
      setUploadModal(true);
      try {
        let url = null;

        url = e.target.files[0];
        const data = await ReactS3Client.uploadFile(url);
        url = data.location;
        if (url) {
          setPoster(url);
          handleCloseUpload();
        }
      } catch (error) {
        handleCloseUpload();
      }
    }
  };

  useEffect(() => {
    if (catelogueSuccess || catelogueList) {
      handleCloseUpload();
      setCatelogue(() =>
        catelogueList?.data?.results?.map((item) => {
          return {
            ...item,
            titleError: "",
          };
        })
      );
    }
  }, [catelogueSuccess, catelogueList]);

  useEffect(() => {
    if (subscriptionSuccess || subscriptionDetail) {
      setPlan(subscriptionDetail?.data?.selected_plan?.subscription_features);
    }
  }, [subscriptionSuccess, subscriptionDetail]);

  const handleEdit = async (e, item, i) => {
    if (item?.name == "") {
      catelogue[i].titleError = "Required";
      setCatelogue([...catelogue]);
      return;
    }
    await editCatelogue({
      businessId: id,
      id: item.id,
      name: item.name,
      catalogue_image: item.catalogue_image,
      is_active: item.is_active,
    });
  };

  const handleClose = async (value) => {
    if (show == "catelogue") {
      if (value) {
        try {
          await deleteCatelogue({
            businessId: id,
            id: imageId,
          });
        } catch (error) {}
      }
    } else {
      if (value) {
        setPoster("");
      }
    }
    setOpenModal(false);
  };

  const handleAdd = async () => {
    try {
      let payload = { id, banner_image: poster };
      if (businessDetails.data.name) {
        payload.name = businessDetails.data.name;
      }
      if (businessDetails.data.mobile_number) {
        payload.mobile_number = businessDetails.data.mobile_number;
      }
      if (businessDetails.data.type) {
        payload.type = businessDetails.data.type;
      }
      if (businessDetails.data.category) {
        payload.category = businessDetails.data.category;
      }
      if (businessDetails.data.country) {
        payload.country = businessDetails.data.country;
      }
      if (businessDetails.data.state) {
        payload.state = businessDetails.data.state;
      }
      if (businessDetails.data.district) {
        payload.district = businessDetails.data.district;
      }

      if (businessDetails.data.about_us) {
        payload.about_us = businessDetails.data.about_us;
      }
      if (businessDetails.data.post_office) {
        payload.post_office = businessDetails.data.post_office;
      }
      if (businessDetails.data.pin_code) {
        payload.pin_code = businessDetails.data.pin_code;
      }
      if (businessDetails.data.longitude) {
        payload.longitude = businessDetails.data.longitude;
      }
      if (businessDetails.data.latitude) {
        payload.latitude = businessDetails.data.latitude;
      }
      if (businessDetails.data.sub_category_id) {
        payload.sub_category = businessDetails.data.sub_category_id;
      }
      if (businessDetails.data.google_location_link) {
        payload.google_location_link =
          businessDetails.data.google_location_link;
      }
      if (businessDetails.data.whatsapp_number) {
        payload.whatsapp_number = businessDetails.data.whatsapp_number;
      }
      if (businessDetails.data.facebook) {
        payload.facebook = businessDetails.data.facebook;
      }
      if (businessDetails.data.instagram) {
        payload.instagram = businessDetails.data.instagram;
      }
      if (businessDetails.data.youtube) {
        payload.youtube = businessDetails.data.youtube;
      }

      if (businessDetails.data.website) {
        payload.website = businessDetails.data.website;
      }
      if (businessDetails.data.image) {
        payload.image = businessDetails.data.image;
      }

      await EditBusiness(payload);
    } catch (error) {}
  };

  const handleNext = () => {
    if (plan.banner_image) {
      setShow("premium");
    } else {
      setOpensuccess(true);
    }
  };

  const handleErrorModal = () => {
    setOpenError(false);
    setMessage("");
  };

  const handleSuccessModal = () => {
    history.push("/my-list");
    setOpensuccess(false);
  };

  useEffect(() => {
    if (businessSuccess) {
      setPoster(businessDetails.data.banner_image);
    }
  }, [businessSuccess]);

  useEffect(() => {
    if (editSuccess) {
      setOpensuccess(true);
    }
    if (editError) {
      setOpenError(true);
    }
  }, [editSuccess, editError]);

  useEffect(() => {
    if (addCatelogueIsError && addCatelogueError) {
      setMessage(addCatelogueError?.data?.message);
      setOpenError(true);
    }
  }, [addCatelogueIsError, addCatelogueError]);
  return (
    <Fragment>
      {show == "catelogue" ? (
        <>
          {" "}
          <div
            style={{
              backgroundColor: "#fff",
              boxShadow: "-2px 0px 5px rgba(22, 107, 142, 0.25)",
              color: "black",
              display: "flex",
              width: "100%",
              alignItems: "center",
              gap: "1rem",
              flexDirection: "row",
              padding: "1rem",
              position: "fixed",
              top: 0,
              zIndex: "100",
            }}
          >
            <CloseIcon
              onClick={() => history.push("/my-list")}
              style={{
                color: "black",
              }}
            />
            <p style={{ color: "black" }}>Edit Catalogue Photos</p>
          </div>
          <div
            style={{
              marginTop: "50px",
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            {catelogue &&
              catelogue.map((item, i) => (
                <>
                  <div
                    style={{
                      position: "relative",
                      display: "flex",
                      justifyContent: "center",
                      top: "20px",
                    }}
                    key={i}
                  >
                    {" "}
                    <img
                      src={item.catalogue_image}
                      width={"92%"}
                      height={"224px"}
                      style={{
                        borderRadius: "20px",
                        border: "4px solid rgba(22, 107, 142, 1)",
                        marginBottom: "10px",
                      }}
                    />
                    <Button
                      className="delete-btn"
                      onClick={() => {
                        setImageId(item.id);
                        setOpenModal(true);
                      }}
                    >
                      <RiDeleteBin5Line />
                    </Button>
                  </div>
                  <div
                    style={{
                      width: "100%",
                      marginTop: "15px",
                      padding: "10px 20px",
                    }}
                  >
                    <p style={{ fontWeight: "bold", marginBottom: "6px" }}>
                      Title<span style={{ color: "red" }}>*</span>
                    </p>
                    <Grid container spacing={2}>
                      <Grid item xs={10}>
                        <input
                          placeholder="Business Name"
                          className="reg-inp"
                          value={item.name}
                          maxLength={14}
                          onChange={(e) => {
                            let data = [...catelogue];
                            data[i].name = e.target.value;
                            data[i].titleError = "";
                            setCatelogue([...data]);
                          }}
                          style={{ marginBottom: "0px" }}
                        />
                        {item.titleError && item.titleError.length > 0 && (
                          <div className="error-message">{item.titleError}</div>
                        )}
                      </Grid>
                      <Grid item xs={2}>
                        {" "}
                        <Button
                          className="edit-service-btn"
                          onClick={(e) => handleEdit(e, item, i)}
                          style={{marginTop:"15px"}}
                        >
                          <BsFillPenFill />
                        </Button>
                      </Grid>
                    </Grid>
                  </div>
                </>
              ))}
          </div>
          {catelogue.length == 0 || cateloguePhoto ? (
            <div
              style={{
                marginTop: "2rem",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <input
                style={{
                  display: "none",
                }}
                type="file"
                id="file"
                accept=".jpg,.jpeg,.png"
                onChange={(e) => handleCatelogueImage(e)}
              />
              <label
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  cursor: "pointer",
                  color: "#677eff",
                  width: "92%",
                  height: "3rem",
                  border: "none",
                  color: "info",
                  backgroundColor: "#fff",
                  border: "solid 3px #166B8E",
                  borderRadius: "35px",
                  padding: "8rem",
                }}
                htmlFor="file"
              >
                <i className=" fs-3 fa-solid fa-image"></i>
                <span
                  style={{
                    width: "100%",
                    borderRadius: "30px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                  }}
                >
                  <img src="/images/img-icon.png" />
                  <p style={{ color: "#2182B1" }}>
                    Add Catalogue Image for your business
                  </p>
                </span>
              </label>
            </div>
          ) : null}
          {catelogue.length != plan.catalogue_images ? (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                margin: "2rem 0px",
              }}
            >
              <Button
                variant="contained"
                style={{
                  backgroundColor: theme.palette.secondary.main,
                  borderRadius: "12px",
                  height: "3rem",
                }}
                onClick={() => setCateloguePhoto(true)}
              >
                Add {plan?.catalogue_images - catelogue.length} more images
              </Button>
            </div>
          ) : null}
          <Button
            startIcon={<BsArrowRight />}
            onClick={handleNext}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              backgroundColor: theme.palette.secondary.main,
              borderRadius: "12px",
              height: "3rem",
              position: "fixed",
              bottom: "25px",
              right: "15px",
              minWidth: "0px",
              width: "50px",
              height: "50px",
              color: "white",
            }}
          ></Button>
        </>
      ) : null}

      {show == "premium" ? (
        <Fragment>
          <div
            style={{
              backgroundColor: "#fff",
              boxShadow: "-2px 0px 5px rgba(22, 107, 142, 0.25)",
              color: "black",
              display: "flex",
              width: "100%",
              alignItems: "center",
              gap: "1rem",
              flexDirection: "row",
              padding: "1rem",
              position: "fixed",
              top: 0,
              zIndex: "100",
            }}
          >
            <ArrowBackIcon
              style={{
                color: "black",
              }}
              onClick={() => history.push("/my-list")}
            />
            <p style={{ color: "black" }}>Edit Poster</p>
          </div>

          <div
            style={{
              marginTop: "50px",
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            {poster ? (
              <div
                style={{
                  position: "relative",
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "10px",
                }}
              >
                <img
                  src={poster}
                  width={"92%"}
                  height={"224px"}
                  style={{ borderRadius: "20px" }}
                />
                <Button
                  className="delete-btn"
                  onClick={() => {
                    setOpenModal(true);
                  }}
                >
                  <RiDeleteBin5Line />
                </Button>
              </div>
            ) : (
              ""
            )}
          </div>
          {!poster ? (
            <div
              style={{
                marginTop: "5rem",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <input
                style={{
                  display: "none",
                }}
                type="file"
                id="file"
                accept=".jpg,.jpeg,.png"
                onChange={(e) => handlePremium(e)}
              />
              <label
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  cursor: "pointer",
                  color: "#677eff",
                  width: "92%",
                  height: "3rem",
                  border: "none",
                  color: "info",
                  backgroundColor: "#fff",
                  border: "solid 3px #166B8E",
                  borderRadius: "35px",
                  padding: "8rem",
                }}
                htmlFor="file"
              >
                <i className=" fs-3 fa-solid fa-image"></i>
                <span
                  style={{
                    width: "100%",
                    borderRadius: "30px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                  }}
                >
                  <img src="/images/img-icon.png" />
                  <p style={{ color: "#2182B1" }}>Add Poster</p>
                </span>
              </label>
            </div>
          ) : (
            ""
          )}

          <Box
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginTop: "5px",
            }}
          >
            <Typography
              sx={{
                color: "#FF0000",
                fontSize: "12px !important",
                fontWeight: "600",
              }}
            >
              Note
            </Typography>
            <Typography
              sx={{
                color: "#000000",
                fontSize: "12px !important",
                fontWeight: "600",
              }}
            >
              : You can add poster later
            </Typography>
          </Box>

          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              margin: "2rem",
              position: "fixed",
              bottom: 0,
              width: "82%",
            }}
          >
            <div className="next-btn">
              <FaSave style={{ fontSize: "1.5rem" }} onClick={handleAdd} />
            </div>
          </div>
        </Fragment>
      ) : null}
      {openModal ? (
        <ImageRemovalModal open={openModal} handleClose={handleClose} />
      ) : null}
      {openError ? (
        <ErrorModal
          open={openError}
          handleClose={handleErrorModal}
          message={message}
        />
      ) : null}
      {openSucces ? (
        <SuccessModal
          open={openSucces}
          handleClose={handleSuccessModal}
          title="Ok"
          message={
            show == "catelogue"
              ? "Catalogue photo edited successfully"
              : "Poster edited successfully"
          }
        />
      ) : null}
      {uploadModal ? (
        <UploadModal open={uploadModal} handleClose={handleCloseUpload} />
      ) : null}
    </Fragment>
  );
};

export default EditCatalogueGold;
