import React, { useEffect, useRef, useState } from "react";
import Header from "../../components/header/Header";
import BottomNav from "../../components/Bottom nav/BottomNav";
import { MdDashboard } from "react-icons/md";
import { GiHamburgerMenu } from "react-icons/gi";
import { useParams, useHistory } from "react-router-dom";
import useCommonQuery from "../../store/common/useCommonQuery";
import CategoreyCard from "../../components/CategoreyCard";
import BookmarkNotFound from "../myListing/bookmark/bookmarkNotFound/BookmarkNotFound";
import { Grid, Typography } from "@mui/material";
import "./ListView.css";

function SubCategory() {
  const history = useHistory();
  const { id } = useParams();
  const inputRef = useRef();

  const [search, setSearch] = useState("");
  const [toggle, setToggle] = useState(false);
  const [items, setItems] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [height, setHeight] = useState("");

  const { data: subcategoryList, isSuccess } = useCommonQuery.Get_subcategory({
    id,
    search,
    limit: 2000,
    offset: 0,
  });

  const handleGridView = () => {
    setToggle(false);
  };
  const handleListView = () => {
    setToggle(true);
  };

  const handleClick = (name) => {
    history.push(`/category/${id}/subCategory/${name}`);
  };

  useEffect(() => {}, [search]);

  return (
    <div
      id="parent-div"
      ref={inputRef}
      style={{ height: "100vh", overflowY: "scroll" }}
    >
      <Header />
      <div
        style={{
          display: `${toggle ? "none" : "block"}`,
          marginBottom: "5rem",
        }}
      >
        <div style={{ marginTop: "9rem" }}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              paddingLeft: "1rem",
              paddingRight: "1rem",
            }}
          >
            <h4>All Subcategories</h4>
            <MdDashboard
              onClick={handleListView}
              style={{ fontSize: "33px" }}
            />
          </div>
        </div>
        <Grid container spacing={2} style={{ padding: "1rem" }}>
          {subcategoryList &&
            subcategoryList?.data?.results?.map((items, idx) => {
              return (
                <CategoreyCard
                  id={items.id}
                  title={items.name}
                  handleClick={handleClick}
                  image={items.image}
                  key={idx}
                />
              );
            })}
        </Grid>
      </div>

      <div
        style={{
          display: `${toggle ? "block" : "none"}`,
          marginBottom: "2rem",
        }}
      >
        <div style={{ marginTop: "9rem" }}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              paddingLeft: "1rem",
              paddingRight: "1rem",
            }}
          >
            <h4 style={{ margin: 0 }}>All Subcategories</h4>
            <GiHamburgerMenu
              onClick={handleGridView}
              style={{ fontSize: "33px" }}
            />
          </div>
        </div>
        <div style={{ position: "relative" }}>
          <input
            placeholder="Search in Category"
            className="cat-inp"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
          <img
            style={{ position: "absolute", right: "2rem", top: "1.5rem" }}
            src="/images/insideSearch.png"
          />
        </div>
        <div className="list-Card-body">
          {subcategoryList &&
            subcategoryList?.data?.results?.map((item, i) => {
              return (
                <div
                  className="list-Card"
                  onClick={() =>
                    history.push(`/category/${id}/subCategory/${item.id}`)
                  }
                  key={i}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-start",
                    }}
                  >
                    <img
                      style={{ width: "25px" }}
                      src={item.image}
                      alt="not found"
                    />
                  </div>
                  <Typography
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      flex: 1,

                      fontSize: "12px",
                      fontWeight: "500",
                    }}
                  >
                    {item.name}
                  </Typography>
                </div>
              );
            })}
        </div>
      </div>
      <BottomNav />
      {subcategoryList?.data?.results?.length == 0 ? (
        <BookmarkNotFound title="No SubCategories" />
      ) : null}
    </div>
  );
}

export default React.memo(SubCategory);
