import { Box, Button, Modal, Typography } from "@mui/material";
import React from "react";
import deleteicon from "../../../../assets/deleteicon.png";
import "./AddBusiness.css";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%",
  background: "white",
  padding: "24px 30px",
  borderRadius: "24px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  height: "252px",
};

function ImageRemovalModal({ open, handleClose }) {
  return (
    <Modal open={open} >
      <Box style={style}>
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <img src={deleteicon} width="55px" height="55px" />
        </Box>
        <Typography
          sx={{
            fontSize: "16px",
            fontWeight: "600",
            color: "rgba(14, 58, 83, 1)",
            textAlign: "center",
          }}
        >
          Are you sure you want to remove this image ?
        </Typography>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Button className="cancel-button" onClick={()=>handleClose(false)}>Cancel</Button>
          <Button className="sure-button" onClick={()=>handleClose(true)}>Sure</Button>
        </Box>
      </Box>
    </Modal>
  );
}

export default ImageRemovalModal;
