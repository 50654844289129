import DataService from "../Dataservice";

const getCatelogue = async (id) => {
  let url = `/business/my_business/${id}/catalogue_images/`;
  const data = await DataService.get(url);
  return data?.data;
};

const deleteCatelogue = async (values) => {
  let url = `/business/my_business/${values.businessId}/catalogue_images/${values.id}/`;
  const data = await DataService.del(url);
  return data;
};

const editCatelogue = async (values) => {
  let url = `/business/my_business/${values.businessId}/catalogue_images/${values.id}/`;
  const data = await DataService.patch(url, values);
  return data;
};

const addCatelogue = async (values) => {
  const { id, ...edit } = values;
  let url = `/business/my_business/${id}/catalogue_images/`;
  const data = await DataService.post(url, values);
  return data?.data;
};

const getCover = async (id) => {
  let url = `/business/my_business/${id}/cover_images/`;
  const data = await DataService.get(url);
  return data?.data;
};

const addCover = async (values) => {
  const { id, ...editData } = values;
  let url = `/business/my_business/${id}/cover_images/`;
  const data = await DataService.post(url, values);
  return data?.data;
};

const deleteCover = async (values) => {
  let url = `/business/my_business/${values.businessId}/cover_images/${values.id}/`;
  const data = await DataService.del(url);
  return data;
};

export {
  getCatelogue,
  deleteCatelogue,
  addCatelogue,
  getCover,
  addCover,
  deleteCover,
  editCatelogue
};
