import { useMutation, useQuery, useQueryClient } from "react-query";
import { addCatelogue, addCover, deleteCatelogue, deleteCover, editCatelogue, getCatelogue, getCover, imageConverter } from "./imagesUrls";
import axios from "axios";

const Get_Catelogue = (id) => {
  return useQuery(["catelogue"], () => getCatelogue(id));
};

const Get_cover =(id)=>{
  return useQuery(["cover"],()=>getCover(id))
}


const Add_catelogue = ()=>{
  const queryClient = useQueryClient()
  return useMutation((data) => addCatelogue(data), {
    onMutate: async (newData) => {
      queryClient.cancelQueries("catelogue");
      const prev = await queryClient.getQueryData("catelogue");
      return {
        prev,
        newData,
      };
    },
    onSettled:()=>{
      queryClient.invalidateQueries("catelogue")
    },
    onError:(err,_,context)=>{
      queryClient.setQueryData("catelogue",context.prev)
    }
  });
}

const Edit_catelogue = () => {
  const queryClient = useQueryClient();

  return useMutation((data) => editCatelogue(data), {
    onMutate: async (newData) => {
      queryClient.cancelQueries("catelogue");
      const prev = await queryClient.getQueryData("catelogue");
      return {
        prev,
        newData,
      };
    },
    onSettled:()=>{
      queryClient.invalidateQueries("catelogue")
    },
    onError:(err,_,context)=>{
      queryClient.setQueryData("catelogue",context.prev)
    }
  });
};
const Del_catelogue = () => {
  const queryClient = useQueryClient();

  return useMutation((data) => deleteCatelogue(data), {
    onMutate: async (newData) => {
      queryClient.cancelQueries("catelogue");
      const prev = await queryClient.getQueryData("catelogue");
      return {
        prev,
        newData,
      };
    },
    onSettled:()=>{
      queryClient.invalidateQueries("catelogue")
    },
    onError:(err,_,context)=>{
      queryClient.setQueryData("catelogue",context.prev)
    }
  });
};

const Add_cover = ()=>{
  const queryClient = useQueryClient()
  return useMutation((data) => addCover(data), {
    onMutate: async (newData) => {
      queryClient.cancelQueries("cover");
      const prev = await queryClient.getQueryData("cover");
      return {
        prev,
        newData,
      };
    },
    onSettled:()=>{
      queryClient.invalidateQueries("cover")
    },
    onError:(err,_,context)=>{
      queryClient.setQueryData("cover",context.prev)
    }
  });
}

const Del_cover = () => {
  const queryClient = useQueryClient();

  return useMutation((data) => deleteCover(data), {
    onMutate: async (newData) => {
      queryClient.cancelQueries("cover");
      const prev = await queryClient.getQueryData("cover");
      return {
        prev,
        newData,
      };
    },
    onSettled:()=>{
      queryClient.invalidateQueries("cover")
    },
    onError:(err,_,context)=>{
      queryClient.setQueryData("cover",context.prev)
    }
  });
};




const useImageQuery = {
  Get_Catelogue,
  Del_catelogue,
  Add_catelogue,
  Get_cover,
  Add_cover,
  Del_cover,
  Edit_catelogue
};

export default useImageQuery;
