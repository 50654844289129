import React, { useContext, useEffect, useState } from "react";
import "./SignUp.css";
import {
  Box,
  Button,
  FormControl,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import theme from "../../../utils/theme";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useHistory } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import useAuthQuery from "../../../store/auth/useAuthQuery";
import { variableContext } from "../../../context/VariableContex";
import masterClick from "../../../assets/masterClick.svg";
import Flag from "../../../assets/Flag.svg";
import white from "../../../assets/white.png";

const modelSchema = Yup.object().shape({
  phoneNumber: Yup.number().required("Phone Number is required"),
});

const SignUp = () => {
  const history = useHistory();

  const { setMobile } = useContext(variableContext);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [value, setValue] = useState("");

  const initialValues = {
    phoneNumber: "",
  };

  const {
    mutateAsync: sendOtp,
    isSuccess,
    error,
    isError,
  } = useAuthQuery.Registration();

  console.log({ error });

  const formik = useFormik({
    initialValues,
    validationSchema: modelSchema,
    onSubmit: async (values, { setStatus, setSubmitting, setFieldError }) => {
      if (values.phoneNumber.toString().length < 10) {
        setFieldError("phoneNumber", "Invalid Phonenumber");
        return;
      }
      if (values.phoneNumber.toString().length > 10) {
        setFieldError("phoneNumber", "Invalid Phonenumber");
        return;
      }
      try {
        setMobile(values.phoneNumber);
        await sendOtp({
          name: "name",
          mobile: values.phoneNumber,
        });
      } catch (error) {
        setStatus("Something went wrong");
        setSubmitting(false);
      }
    },
  });

  useEffect(() => {
    if (isSuccess) {
      history.push("/sign-in-otp");
    }
  }, [isSuccess]);

  useEffect(() => {
    if (isError && error) {
      formik.setFieldError("phoneNumber", error?.response?.data?.message);
    }
  }, [isError, error]);

  return (
    <div
      className="signUp-bg-screen"
      style={{ backgroundColor: theme.palette.primary.main }}
    >
      <div style={{ position: "relative", width: "100%", height: "100%" }}>
        <div className="">
          <ArrowBackIcon
            onClick={() => history.push("/")}
            style={{
              color: "#fff",
              position: "absolute",
              top: "2rem",
              left: "1rem",
            }}
          />
        </div>
        <div className="heading-body">
          <div className="heading">
            <img src={white} width="100%" height="100%" alt="No Image" />
          </div>
        </div>

        <div className="signUp-bottomBox">
          <div className="signUp-title">
            <h1 style={{ margin: 0 }}>Sign Up</h1>
            <p style={{ margin: 0 }}>
              Join our community and discover the best local businesses by
              signing up today.
            </p>
          </div>
          <form onSubmit={formik.handleSubmit} noValidate>
            <div className="signUp-form-body">
              <p style={{ fontWeight: "bold", marginTop: "8px" }}>
                Phone Number
              </p>
              <div className="dropdown-mob-num">
                <FormControl sx={{ width: "64px" }}>
                  <Select
                    value={value}
                    displayEmpty
                    sx={{
                      height: "48px",
                      borderTopLeftRadius: "70px",
                      borderBottomLeftRadius: "70px",
                      "& .MuiSelect-select": {
                        marginTop: "4px",
                      },
                      "& .MuiSelect-icon.Mui-disabled": {
                        color: "black !important",
                      },
                    }}
                    disabled
                  >
                    <MenuItem value="" sx={{ marginTop: "4px" }}>
                      <img src={Flag} />
                    </MenuItem>
                  </Select>
                </FormControl>
                <input
                  className="inp-number"
                  type="number"
                  {...formik.getFieldProps("phoneNumber")}
                />
              </div>
              {formik.touched.phoneNumber && formik.errors.phoneNumber ? (
                <div className="error-message">{formik.errors.phoneNumber}</div>
              ) : (
                ""
              )}
            </div>

            <div className="signUp-btn-body2">
              <Button
                type="submit"
                className="signUp-btn2"
                style={{
                  backgroundColor: theme.palette.secondary.main,
                  paddingBottom: "6px",
                }}
                variant="contained"
              >
                Get OTP
              </Button>
            </div>
          </form>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginBottom: "10px",
            }}
          >
            <Typography style={{ fontWeight: "bold" }}>
              Already have an account ?
            </Typography>
            <Button
              sx={{ textTransform: "none", textDecoration: "underline" }}
              onClick={() => history.push("/sign-in")}
            >
              {" "}
              Sign In
            </Button>
          </Box>
        </div>
      </div>
    </div>
  );
};

export default SignUp;
