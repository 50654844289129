import { Box, Button, Grid, Modal, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import ReactCrop, {
  centerCrop,
  makeAspectCrop,
  Crop,
  PixelCrop,
  convertToPixelCrop,
} from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import S3 from "react-aws-s3";
import { Buffer } from "buffer";
import useStyles from "../../../../SharedClass/sharedClass";
window.Buffer = Buffer;

const TO_RADIANS = Math.PI / 180;

function centerAspectCrop(mediaWidth, mediaHeight, aspect) {
  return centerCrop(
    makeAspectCrop(
      {
        unit: "%",
        width: 90,
      },
      aspect,
      mediaWidth,
      mediaHeight
    ),
    mediaWidth,
    mediaHeight
  );
}

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%",
  background: "white",
  padding: "24px 30px",
  borderRadius: "24px",
};

function CropModal({ open, handleClose, setCroppingImage, croppingImage }) {
  const [result, setResult] = useState("");
  const [show, setShow] = useState(true);
  const [showError, setShowError] = useState("");
  const [imgSrc, setImgSrc] = useState("");
  const previewCanvasRef = useRef(null);
  const imgRef = useRef(null);
  const hiddenAnchorRef = useRef(null);
  const blobUrlRef = useRef("");
  const [crop, setCrop] = useState();
  const [completedCrop, setCompletedCrop] = useState();
  const [scale, setScale] = useState(1);
  const [rotate, setRotate] = useState(0);
  const [aspect, setAspect] = useState(undefined);
  const [errorMessage, setErrorMessage] = useState("");

  const styles = useStyles();
  const inputRef = useRef();

  const config = {
    bucketName: "erp-bucket-dev",
    region: "ap-south-1",
    dirName: "erp-listingApp/property-documents",
    accessKeyId: "AKIAYI7JX4JXTXOVAQOA",
    secretAccessKey: "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",
  };

  const ReactS3Client = new S3(config);

  const canvasPreview = async (image, canvas, crop, scale = 1, rotate = 0) => {
    const ctx = canvas.getContext("2d");

    if (!ctx) {
      throw new Error("No 2d context");
    }

    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;

    const pixelRatio = window.devicePixelRatio;

    canvas.width = Math.floor(crop.width * scaleX * pixelRatio);
    canvas.height = Math.floor(crop.height * scaleY * pixelRatio);

    ctx.scale(pixelRatio, pixelRatio);
    ctx.imageSmoothingQuality = "high";

    const cropX = crop.x * scaleX;
    const cropY = crop.y * scaleY;

    const rotateRads = rotate * TO_RADIANS;
    const centerX = image.naturalWidth / 2;
    const centerY = image.naturalHeight / 2;

    ctx.save();

    ctx.translate(-cropX, -cropY);

    ctx.translate(centerX, centerY);

    ctx.rotate(rotateRads);
    // 2) Scale the image
    ctx.scale(scale, scale);
    // 1) Move the center of the image to the origin (0,0)
    ctx.translate(-centerX, -centerY);
    ctx.drawImage(
      image,
      0,
      0,
      image.naturalWidth,
      image.naturalHeight,
      0,
      0,
      image.naturalWidth,
      image.naturalHeight
    );

    const base64 = canvas.toDataURL("image/jpeg");
    if (base64) {
      const byteCharacters = Buffer.from(base64, "base64").toString("ascii");
      const arrayBuffer = new ArrayBuffer(byteCharacters.length);
      const uint8Array = new Uint8Array(arrayBuffer);

      for (let i = 0; i < byteCharacters.length; i++) {
        uint8Array[i] = byteCharacters.charCodeAt(i);
      }
      const blob = new Blob([uint8Array], { type: "image/png" });

      const file = new File([blob], "image.png", { type: "image/png" });

      const data = await ReactS3Client.uploadFile(file);
      setCroppingImage(data.location);
    }

    ctx.restore();
  };

  useEffect(() => {
    if (croppingImage) {
      handleClose();
    }
  }, [croppingImage]);

  function onSelectFile(e) {
    setErrorMessage("");

    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      const fileSizeInBytes = file.size;
      const fileSizeInKB = fileSizeInBytes / 1024;
      if (fileSizeInKB > 300) {
        setErrorMessage("File size is large");
        return;
      }
      setCrop(undefined);
      const reader = new FileReader();
      reader.addEventListener("load", () =>
        setImgSrc(reader.result?.toString() || "")
      );
      reader.readAsDataURL(e.target.files[0]);
      setShow(false);
    }
  }

  function onImageLoad(e) {
    if (aspect) {
      const { width, height } = e.currentTarget;
      setCrop(centerAspectCrop(width, height, aspect));
    }
  }

  const handleClick = async () => {
    if (
      completedCrop?.width &&
      completedCrop?.height &&
      imgRef.current &&
      previewCanvasRef.current
    ) {
      canvasPreview(
        imgRef.current,
        previewCanvasRef.current,
        completedCrop,
        scale,
        rotate
      );
    }
  };

  return (
    <Modal open={open}>
      <Box sx={style}>
        <div className="App">
          <div
            style={{
              display: show ? " flex" : "none",
              justifyContent: "center",
              alignItems: "center",
              margin: "13px 0px",
            }}
            onClick={() => {
              inputRef.current.click();
            }}
          >
            <input
              type="file"
              accept="image/*"
              onChange={onSelectFile}
              ref={inputRef}
              style={{ display: "none" }}
            />
            <img src="/images/img-icon.png" />{" "}
          </div>
          {!!imgSrc && (
            <ReactCrop
              crop={crop}
              onChange={(_, percentCrop) => setCrop(percentCrop)}
              onComplete={(c) => setCompletedCrop(c)}
              aspect={aspect}
            >
              <img
                ref={imgRef}
                alt="Crop me"
                src={imgSrc}
                style={{ transform: `scale(${scale}) rotate(${rotate}deg)` }}
                onLoad={onImageLoad}
              />
            </ReactCrop>
          )}
          {!!completedCrop && (
            <>
              <div>
                <canvas
                  ref={previewCanvasRef}
                  style={{
                    border: "1px solid black",
                    objectFit: "contain",
                    width: completedCrop.width,
                    height: completedCrop.height,
                    display: "none",
                  }}
                />
              </div>
            </>
          )}
        </div>
        {errorMessage.length > 0 ? (
          <Typography sx={{ color: "red", textAlign: "center" }}>
            {errorMessage}
          </Typography>
        ) : null}
        <Box sx={{ margin: "10px 0px", display: "flex" }}>
          <Button
            onClick={handleClose}
            sx={{
              width: "90% !important",
              color: "white !important",
              borderRadius: "12px !important",
              height: "48px !important",
              textTransform: "none !important",
              marginRight: "5px",
            }}
            style={{ background: "rgba(240, 150, 48, 1)" }}
          >
            Cancel
          </Button>
          <Button
            onClick={handleClick}
            sx={{
              background: "rgba(14, 58, 83, 1)",
              width: "90% !important",
              color: "white !important",
              borderRadius: "12px !important",
              height: "48px !important",
              textTransform: "none !important",
            }}
            style={{ background: "rgba(14, 58, 83, 1)" }}
          >
            Ok
          </Button>{" "}
        </Box>
      </Box>
    </Modal>
  );
}

export default CropModal;
