import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Box, Button, Modal } from "@mui/material";
import SuccessModal from "../Add business/SuccessModal";
import "../../../../components/myListingCard/MyListingCard";
import useBusinessQuery from "../../../../store/Bussiness/useBusinessQuery";

const style = {
  position: "absolute",
  top: "33%",
  right: "10%",
  width: "118px",
  background: "white",
  display: "flex",
  flexDirection: "column",
  padding: "7px",
  borderRadius: "10px",
};

function EditModal({ open, handleClose, id, basicPlan, planName }) {
  const history = useHistory();
  const [planDetails, setPlanDetails] = useState({});
  const {
    data,
    isSuccess: getSubscriptionSucess,
    refetch,
  } = useBusinessQuery.Get_subcription(id);

  useEffect(() => {
    if (getSubscriptionSucess && data) {
      setPlanDetails(
        data?.data?.results?.filter((item) => item.name == planName)[0]
          ?.subscription_features
      );
    }
  }, [getSubscriptionSucess, data]);
  useEffect(() => {
    if (id) {
      refetch();
    }
  }, [id]);

  const {
    mutateAsync: deleteBusiness,
    isSuccess,
    isError,
  } = useBusinessQuery.Del_business();

  const handleDelete = async () => {
    try {
      await deleteBusiness(id);
    } catch (error) {}
  };

  const handleImage = () => {
    history.push(`/edit-shopimage/${id}`);
    handleClose("false");
  };

 
  useEffect(() => {
    if (isSuccess) {

      handleClose("true");
    }
  }, [isSuccess]);


  return (
    <>
      <Modal open={open} onClose={()=>handleClose("false")}>
        <Box style={style}>
          <Button
            className="editButton"
            onClick={() => {
              history.push(`/edit-details/${id}`);
              handleClose("false");
            }}
            sx={{ marginBottom: "10px" }}
          >
            Edit Business
          </Button>

          <Button
            className="editButton"
            onClick={handleImage}
            sx={{ marginBottom: "10px" }}
          >
            Edit images
          </Button>

          {planDetails?.service ? (
          <Button
            className="editButton"
            onClick={() => {
              history.push(`/edit-service/${id}`);
              handleClose("false");
            }}
            sx={{ marginBottom: "10px" }}
          >
            Edit Services
          </Button>
        ) : null}

          <Button className="editButton" onClick={handleDelete}>
            {" "}
            Delete
          </Button>
        </Box>
      </Modal>
      
    </>
  );
}

export default EditModal;
