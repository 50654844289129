import React, {
  useState,
  Fragment,
  useRef,
  useContext,
  useEffect,
} from "react";
import CloseIcon from "@mui/icons-material/Close";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ReactGoogleAutocomplete from "react-google-autocomplete";
import useStyles from "../../../../SharedClass/sharedClass";
import { BsArrowRight } from "react-icons/bs";
import { useHistory } from "react-router-dom";
import "./AddBusiness.css";
import {
  Button,
  Box,
  Typography,
  FormControl,
  FormControlLabel,
  Radio,
} from "@mui/material";
import theme from "../../../../utils/theme";
import { FaSave } from "react-icons/fa";
import * as Yup from "yup";
import { useFormik } from "formik";
import useCommonQuery from "../../../../store/common/useCommonQuery";
import S3 from "react-aws-s3";
import { Buffer } from "buffer";
import useBusinessQuery from "../../../../store/Bussiness/useBusinessQuery";
import { variableContext } from "../../../../context/VariableContex";
import deleteicon from "../../../../assets/deleteicon.png";
import ImageRemovalModal from "./ImageRemovalModal";
import ErrorModal from "./ErrorModal";
import SuccessModal from "./SuccessModal";
import CropModal from "./CropModal";
import axios from "axios";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import GpsFixedIcon from "@mui/icons-material/GpsFixed";
import UploadModal from "../../../../components/UploadModal";
import CategoryModal from "../../../../components/CategoryModal/Index";
import SubCategoryModal from "../../../../components/SubCategoryModal/Index";
import useAuthQuery from "../../../../store/auth/useAuthQuery";
import VerifyErrorModal from "./VerifyErrorModal";
window.Buffer = Buffer;

const AddBusiness = () => {
  const {
    latitude: lat,
    longitude: long,
    setLatitude: setLat,
    setLongitude: setLong,
    mobile,
  } = useContext(variableContext);

  const history = useHistory();
  const classes = useStyles();
  const ref = useRef();
  const photoref = useRef();
  const catelogueRef = useRef();
  const premiumRef = useRef();

  const Api_Key = "AIzaSyDMC5uUfcSScgVY9AGqsGQwYm8P6_LmyYg";

  const [show, setShow] = useState("business");
  const [add, setAdd] = useState([
    { name: "" },
    { name: "" },
    { name: "" },
    { name: "" },
    { name: "" },
  ]);
  const [state, setState] = useState(1);
  const [district, setDistrict] = useState("");
  const [postOffice, setPostOffice] = useState("");
  const [pincode, setPincode] = useState("");
  const [about, setAbout] = useState("");
  const [name, setName] = useState("");
  const [type, setType] = useState("");
  const [category, setCategory] = useState("");
  const [googleLocation, setGoogleLocation] = useState("");
  const [Whatsapp, setWhatsApp] = useState("");
  const [facebook, setFacebook] = useState("");
  const [instagram, setInstagram] = useState("");
  const [youtube, setYoutube] = useState("");
  const [website, setWebsite] = useState("");
  const [cover, setCover] = useState([]);
  const [photos, setPhotos] = useState("");
  const [catelogue, setCatelogue] = useState([]);
  const [premium, setPremium] = useState([]);
  const [poster, setPoster] = useState("");
  const [subCategory, setSubCategory] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [id, setId] = useState("");
  const [selectedImage, setSelectedImage] = useState("");
  const [openError, setOpenError] = useState(false);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [phonenumber, setPhonenumber] = useState("");
  const [latitude, setLatitude] = useState("");
  const [longitude, setLongitude] = useState("");
  const [croppingImage, setCroppingImage] = useState();
  const [openCropModal, setOpenCropModal] = useState(false);
  const [placeName, setPlaceName] = useState("");
  const [locationError, setLocationError] = useState("");
  const [uploadModal, setUploadModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [categoryModal, setCategoryModal] = useState(false);
  const [categoryId, setCategoryId] = useState("");
  const [subCategoryModal, setSubCategoryModal] = useState(false);
  const [subCategoryId, setSubCategoryId] = useState("");
  const [categoryError, setCategoryError] = useState("");
  const [phoneNumberError, setPhoneNumberError] = useState("");
  const [otp, setOtp] = useState("");
  const [otpSuccessModal, setOtpSuccessModal] = useState(false);
  const [nameError, setNameError] = useState("");
  const [typeError, setTypeError] = useState("");
  const [location_info, setLocationInfo] = useState("");
  const [newCatelogue, setNewCatelogue] = useState();

  const envConfig = process.env;

  const config = {
    bucketName: envConfig.REACT_APP_S3_BUCKET,
    region: envConfig.REACT_APP_S3_REGION,
    dirName: "master-click",
    accessKeyId: envConfig.REACT_APP_S3_ACCESS_KEY_ID,
    secretAccessKey: envConfig.REACT_APP_S3_SECRET_ACCESS_KEY,
  };
  const ReactS3Client = new S3(config);

  const { data: districtList } = useCommonQuery.Get_district(state);

  const { data: trailPlan } = useBusinessQuery.Get_trial_plan();

  const {
    mutateAsync: AddBusiness,
    isSuccess,
    isError,
    error: businessError,
  } = useBusinessQuery.Add_business();

  function getLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(showPosition, onError);
    }
  }

  function onError(error) {
    if (error) {
      // setOpenLocation(true);
    }
  }

  function showPosition(position) {
    setLat(position.coords.latitude);
    setLong(position.coords.longitude);
  }

  const fetchCityName = async () => {
    try {
      const response = await axios.get(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${long}&key=${Api_Key}`
      );

      const results = response.data.results;
      if (results && results.length > 0) {
        const addressComponents = results[0].address_components;
        for (const component of addressComponents) {
          if (component.types.includes("locality")) {
            setPlaceName(component.long_name);
            setLocationInfo(component.long_name);
            break;
          }
        }
      }
    } catch (error) {}
  };

  useEffect(() => {
    if (mobile) {
      setPhonenumber(mobile);
      setWhatsApp(mobile);
    }
  }, [mobile]);

  useEffect(() => {
    if (lat && long) {
      fetchCityName();
      setLatitude(lat);
      setLongitude(long);
    }
  }, [lat, long]);

  const handleLocation = (place) => {
    let lat = place.geometry.location.lat();
    let long = place.geometry.location.lng();
    setLatitude(lat);
    setLongitude(long);
    setLocationInfo(place.name);
    setLocationError("");
  };

  const handleSave = async () => {
    let error = false;
    if (name.trim() == "") {
      setNameError("Business name is required");
      error = true;
    }
    if (type == "") {
      setTypeError("Type is required");
    }
    if (latitude == "" && longitude == "") {
      setLocationError("Location is required");
      error = true;
    }
    if (categoryId == "") {
      error = true;
      setCategoryError("Category is required");
    }

    if (error) {
      return;
    } else {
      try {
        await AddBusiness({
          name,
          type,
          mobile_number: phonenumber,
          category: categoryId,
          sub_category: subCategoryId,

          latitude: latitude,
          longitude: longitude,

          image: photos,
          banner_image: poster,
        });
      } catch (error) {}
    }
  };

  const handleImage = () => {
    setOpenCropModal(true);
    setCroppingImage("");
  };

  const handleCloseCrop = () => {
    if (show == "photos") {
      setPhotos(croppingImage);
    } else if (show == "coverPhoto") {
      setCover([...cover, croppingImage]);
    } else if (show == "catelogue") {
      setCatelogue([...catelogue, croppingImage]);
    } else if (show == "poster") {
      setPoster(croppingImage);
    }

    setOpenCropModal(false);
  };

  const handleClose = (value) => {
    if (value) {
      if (selectedImage == "cover") {
        const data = cover.filter((item, i) => i != id);
        setCover([...data]);
      } else if (selectedImage == "premium") {
        const data = premium.filter((item, i) => i != id);
        setPremium([...data]);
      } else if (selectedImage == "photos") {
        setPhotos("");
      } else if (selectedImage == "catelogue") {
        const data = catelogue.filter((item, i) => i != id);
        setCatelogue([...data]);
      } else {
        setPoster("");
      }
    }
    setOpenModal(false);
  };

  const handleError = () => {
    setAbout("");
    setAdd([
      { name: "" },
      { name: "" },
      { name: "" },
      { name: "" },
      { name: "" },
    ]);
    setCategory("");
    setCatelogue([]);
    setCover([]);
    setDistrict("");
    setFacebook("");
    setGoogleLocation("");
    setPhonenumber("");
    setInstagram("");
    setName("");
    setPincode("");
    setPhotos("");
    setPostOffice("");
    setType("");
    setShow("business");
    setOpenError(false);

    setPoster("");
    setPremium([]);
  };

  const handleSuccess = () => {
    setOpenSuccess(false);
    history.push("/my-list");
  };

  const handleCloseUpload = () => {
    setUploadModal(false);
  };

  const handleCategoryModal = (data) => {
    if (data.name) {
      setCategoryError("");
    }
    setCategoryModal(false);
    setCategoryId(data.id);
    setCategory(data.name);
  };

  const handleSubCategoryModal = (data) => {
    setSubCategoryModal(false);
    setSubCategoryId(data.id);
    setSubCategory(data.name);
  };

  const validateForm = () => {
    let error = null;
    const data = catelogue.map((item) => {
      if (item.title == "") {
        item.titleError = "Required";
        error = true;
      }
      return item;
    });
    setCatelogue([...data]);
    return error;
  };

  const handleBusiness = async () => {
    if (!validateForm()) {
      let payload = {
        name,
        type,
        mobile_number: phonenumber,
        category: categoryId,
        sub_category: subCategoryId,
        district,
        post_office: postOffice,
        pin_code: pincode,
        latitude: latitude,
        longitude: longitude,
        about_us: about,
        image: photos,
        banner_image: poster,
        location_info,

        catalogue_image: catelogue?.map((item) => {
          return {
            catalogue_image: item?.url,
            is_active: true,
            name: item?.title,
          };
        }),
        cover_image: cover.map((item) => {
          return {
            cover_image: item ? item : "",
            is_active: true,
          };
        }),
        social_medias: {
          google_location_link: googleLocation,
          whatsapp_number: Whatsapp,
          facebook,
          instagram,
          youtube,
          website,
        },
        service: add
          .filter((item) => Boolean(item.name))
          ?.map((data) => ({ ...data, is_active: true })),
      };
      if (!payload.pin_code) {
        delete payload.pin_code;
      }
      try {
        await AddBusiness(payload);
      } catch (error) {}
    }
  };

  const handleAdd = (e, i) => {
    let data = add[i];
    data.name = e.target.value;
    add[i] = data;
    setAdd([...add]);
  };

  useEffect(() => {
    if (isSuccess) {
      setOpenSuccess(true);
    }
    if (isError && businessError) {
      setErrorMessage(businessError?.data?.message);
      setOpenError(true);
    }
  }, [isSuccess, isError, businessError]);

  const handleCoverImage = async (e) => {
    if (e.target.files.length == 0) {
      return;
    } else {
      let url = null;

      setUploadModal(true);
      url = e.target.files[0];
      if (url !== null) {
        const data = await ReactS3Client.uploadFile(url);
        const newData = data.location;
        setCover([...cover, newData]);
        handleCloseUpload();
      }
    }
  };

  const handlePhoto = async (e) => {
    if (e.target.files.length == 0) {
      return;
    } else {
      let url = null;
      setUploadModal(true);
      url = e.target.files[0];
      if (url !== null) {
        const data = await ReactS3Client.uploadFile(url);
        url = data.location;
        setPhotos(url);
        handleCloseUpload();
      }
    }
  };
  const handleCatelogueImage = async (e) => {
    if (e.target.files.length == 0) {
      return;
    } else {
      let url = null;
      setUploadModal(true);
      url = e.target.files[0];
      if (url !== null) {
        const data = await ReactS3Client.uploadFile(url);
        url = data.location;
        setCatelogue([...catelogue, { url, title: "", titleError: "" }]);
        handleCloseUpload();
      }
    }
  };

  const handlePosterImage = async (e) => {
    if (e.target.files.length == 0) {
      return;
    } else {
      let url = null;
      setUploadModal(true);
      url = e.target.files[0];
      if (url !== null) {
        const data = await ReactS3Client.uploadFile(url);
        url = data.location;
        setPoster(url);
        handleCloseUpload();
      }
    }
  };

  return (
    <div>
      {show == "business" ? (
        <>
          <div
            style={{
              backgroundColor: "#fff",
              boxShadow: "-2px 0px 5px rgba(22, 107, 142, 0.25)",
              color: "black",
              display: "flex",
              width: "100%",
              alignItems: "center",
              gap: "1rem",
              flexDirection: "row",
              padding: "1rem",
              position: "fixed",
              top: 0,
            }}
          >
            <CloseIcon
              onClick={() => history.push("/")}
              style={{
                color: "black",
              }}
            />
            <p style={{ color: "black" }}>Add Business</p>
          </div>
          <form>
            <div
              className="completeRegistration-form-body"
              style={{ marginTop: "4rem" }}
            >
              <p style={{ fontWeight: "bold" }}>
                Business Name<span style={{ color: "red" }}>*</span>
              </p>
              <input
                placeholder="Business Name"
                className="reg-inp"
                value={name}
                onChange={(e) => {
                  setName(e.target.value);
                  setNameError("");
                }}
              />
              {nameError && nameError.length > 0 && (
                <div className="error-message">{nameError}</div>
              )}
              <p style={{ fontWeight: "bold" }}>
                Phone number<span style={{ color: "red" }}>*</span>{" "}
              </p>

              <input
                placeholder="Phone number"
                className="reg-inp"
                value={phonenumber}
                onChange={(e) => {
                  setPhonenumber(e.target.value);
                  setPhoneNumberError("");
                }}
              />
              {phoneNumberError && phoneNumberError.length > 0 && (
                <div className="error-message">{phoneNumberError}</div>
              )}
              <p style={{ fontWeight: "bold" }}>
                Type <span style={{ color: "red" }}>*</span>
              </p>

              <Box
                className="reg-inp"
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  paddingLeft: "0px !important",
                }}
              >
                <FormControlLabel
                  sx={{ width: "0px", marginLeft: "0px" }}
                  onChange={() => setType("shop")}
                  control={
                    <Radio
                      value={type}
                      onChange={() => setType("shop")}
                      checked={type == "shop" ? true : false}
                    />
                  }
                  label="Shop"
                  labelPlacement="start"
                />
                <FormControlLabel
                  onChange={() => setType("service")}
                  sx={{ width: "130px" }}
                  control={
                    <Radio
                      value={type}
                      onChange={() => setType("service")}
                      checked={type == "service" ? true : false}
                    />
                  }
                  label="Service"
                  labelPlacement="start"
                />
              </Box>
              {typeError && typeError.length > 0 && (
                <div className="error-message">{typeError}</div>
              )}
              <p style={{ fontWeight: "bold" }}>
                Category<span style={{ color: "red" }}>*</span>{" "}
              </p>

              <input
                placeholder="Select Category"
                className="reg-inp"
                defaultValue={category}
                onClick={() => setCategoryModal(true)}
              />

              {categoryError && categoryError.length > 0 && (
                <div className="error-message">{categoryError}</div>
              )}
              <p style={{ fontWeight: "bold" }}>Subcategory </p>

              <input
                placeholder="Select sub category"
                className="reg-inp"
                defaultValue={subCategory}
                onClick={() => setSubCategoryModal(true)}
              />

              <p style={{ fontWeight: "bold" }}>
                Location<span style={{ color: "red" }}>*</span>{" "}
              </p>
              <div
                className="reg-inp"
                style={{ display: "flex", alignItems: "center" }}
              >
                <Button
                  onClick={() => getLocation()}
                  style={{ minWidth: "0px", padding: "0px" }}
                >
                  <GpsFixedIcon />
                </Button>

                <ReactGoogleAutocomplete
                  apiKey={Api_Key}
                  options={{
                    types: [
                      "neighborhood",
                      "locality",
                      "sublocality",
                      "postal_code",
                      "point_of_interest",
                      // "ALL",
                    ],
                    componentRestrictions: { country: "in" },
                    fields: [
                      "address_components",
                      "geometry",
                      "icon",
                      "name",
                      "place_id",
                      "formatted_address",
                      // "ALL",
                    ],
                  }}
                  name="location"
                  defaultValue={placeName ? placeName : ""}
                  onPlaceSelected={(place) => handleLocation(place)}
                  style={{
                    outline: "unset",
                    border: "unset",
                    width: "100%",
                    padding: "1rem",
                  }}
                />
              </div>

              {locationError && locationError.length > 0 && (
                <div className="error-message">{locationError}</div>
              )}
              <p style={{ fontWeight: "bold" }}>Banner Image</p>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                }}
              >
                {poster ? (
                  <div
                    style={{
                      position: "relative",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <img
                      src={poster}
                      width={"92%"}
                      height={"224px"}
                      style={{
                        borderRadius: "20px",
                        border: "4px solid rgba(22, 107, 142, 1)",
                        marginBottom: "10px",
                      }}
                    />
                    <img
                      src={deleteicon}
                      style={{
                        position: "absolute",
                        width: "24px",
                        height: "24px",
                        cursor: "pointer",
                        right: "30px",
                        top: "19px",
                      }}
                      onClick={() => {
                        setOpenModal(true);

                        setSelectedImage("poster");
                      }}
                    />
                  </div>
                ) : (
                  ""
                )}
              </div>
              {!poster ? (
                <>
                  <Box sx={{ display: "flex", marginBottom: "10px" }}>
                    <Button
                      style={{
                        color: "black",
                        textTransform: "none",
                        width: "120px",
                        border: "1px solid black",
                      }}
                      onClick={() => premiumRef.current.click()}
                    >
                      Choose File
                    </Button>
                    <input
                      style={{ border: "1px solid black" }}
                      ref={premiumRef}
                      type="file"
                      accept=".jpg,.jpeg,.png"
                      onChange={(e) => handlePosterImage(e)}
                    />
                  </Box>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      flexDirection: "column",
                    }}
                  >
                    <input
                      style={{
                        display: "none",
                      }}
                      type="file"
                      id="file"
                      accept=".jpg,.jpeg,.png"
                      onChange={(e) => handlePosterImage(e)}
                    />
                  </div>
                </>
              ) : (
                ""
              )}
              <p style={{ fontWeight: "bold" }}>Shop Image</p>
              {!photos ? (
                <Box sx={{ display: "flex" }}>
                  <Button
                    style={{
                      color: "black",
                      textTransform: "none",
                      width: "120px",
                      border: "1px solid black",
                    }}
                    onClick={() => photoref.current.click()}
                  >
                    Choose File
                  </Button>
                  <input
                    type="file"
                    style={{ border: "1px solid black" }}
                    id="file"
                    accept=".jpg,.jpeg,.png"
                    ref={photoref}
                    onChange={(e) => handlePhoto(e)}
                  />
                </Box>
              ) : null}

              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                  position: "relative",
                }}
              >
                {photos ? (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    {" "}
                    <img
                      src={photos}
                      width={"92%"}
                      height={"224px"}
                      style={{
                        borderRadius: "20px",
                        border: "4px solid rgba(22, 107, 142, 1)",
                        marginBottom: "10px",
                      }}
                    />
                    <img
                      src={deleteicon}
                      style={{
                        position: "absolute",
                        width: "24px",
                        height: "24px",
                        cursor: "pointer",
                        right: "30px",
                        top: "19px",
                      }}
                      onClick={() => {
                        setOpenModal(true);

                        setSelectedImage("photos");
                      }}
                    />
                  </div>
                ) : null}
              </div>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  margin: "30px 0px",
                }}
              >
                <Button className="save-button" onClick={handleSave}>
                  Save
                </Button>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Typography
                    sx={{
                      fontSize: "15px",
                      fontWeight: "600",
                      border: "1px solid black",
                      height: "37px",
                      display: "flex",
                      alignItems: "center",
                      padding: "0px 2px",
                    }}
                  >
                    Add more information
                  </Typography>
                  <Button
                    className="save-button"
                    onClick={() => setShow("more details")}
                  >
                    Continue
                  </Button>
                </Box>
              </Box>
            </div>

            {/* <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
                margin: "2rem",
              }}
            >
              <button className="next-btn" type="submit">
                <BsArrowRight />
              </button>
            </div> */}
          </form>

          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
              height: "8rem",
              backgroundColor: "#2182B1",
            }}
          >
            <div
              style={{
                width: "100%",
                height: "8rem",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                color: "#fff",
                flexDirection: "column",
              }}
            >
              <p style={{ margin: 0 }}>If you need help for registration</p>
              <hr style={{ width: "90%" }} />
              <div style={{ display: "flex", gap: "1rem", marginTop: "10px" }}>
                <Button
                  variant="contained"
                  style={{ backgroundColor: theme.palette.secondary.main }}
                >
                  <a
                    style={{ color: "white", textDecoration: "none" }}
                    href="https://wa.me/918943999997?text=Hello"
                  >
                    Whatsapp
                  </a>
                </Button>
              </div>
            </div>
          </div>
        </>
      ) : null}
      {show == "more details" ? (
        <Fragment>
          <div
            style={{
              backgroundColor: "#fff",
              boxShadow: "-2px 0px 5px rgba(22, 107, 142, 0.25)",
              color: "black",
              display: "flex",
              width: "100%",
              alignItems: "center",
              gap: "1rem",
              flexDirection: "row",
              padding: "1rem",
              position: "fixed",
              top: 0,
            }}
          >
            <ArrowBackIcon
              onClick={() => setShow("business")}
              style={{
                color: "black",
              }}
            />

            <p style={{ color: "black" }}>Add More Details </p>
          </div>

          <div
            className="completeRegistration-form-body"
            style={{ marginTop: "4rem" }}
          >
            <p style={{ fontWeight: "bold" }}>
              District<span style={{ color: "red" }}>*</span>
            </p>
            <select
              className="inp-dropdown-business"
              value={district}
              onChange={(e) => setDistrict(e.target.value)}
            >
              <option value="">Select District</option>
              {districtList &&
                districtList.data.results.map((item) => (
                  <option value={item.id} key={item.id}>
                    {item.name}
                  </option>
                ))}
            </select>

            <p style={{ fontWeight: "bold" }}>Post Office </p>
            <input
              placeholder="Enter Post Office"
              className="reg-inp"
              value={postOffice}
              onChange={(e) => setPostOffice(e.target.value)}
            />

            <p style={{ fontWeight: "bold" }}>Pin Code</p>
            <input
              placeholder="Enter Pincode"
              className="reg-inp"
              value={pincode}
              onChange={(e) => setPincode(e.target.value)}
            />

            <p style={{ fontWeight: "bold" }}>About your Business</p>
            <textarea
              placeholder="About"
              className="reg-textarea"
              value={about}
              onChange={(e) => setAbout(e.target.value)}
              rows="3"
            />
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              margin: "2rem",
            }}
          >
            <button
              className="next-btn"
              onClick={() => {
                if (
                  trailPlan &&
                  trailPlan?.data?.results?.[0]?.subscription_features?.service
                ) {
                  setShow("service");
                } else {
                  setShow("socialMedia");
                }
              }}
            >
              <BsArrowRight />
            </button>
          </div>
        </Fragment>
      ) : null}
      {show == "service" ? (
        <Fragment>
          <div
            style={{
              backgroundColor: "#fff",
              boxShadow: "-2px 0px 5px rgba(22, 107, 142, 0.25)",
              color: "black",
              display: "flex",
              width: "100%",
              alignItems: "center",
              gap: "1rem",
              flexDirection: "row",
              padding: "1rem",
              position: "fixed",
              top: 0,
            }}
          >
            <ArrowBackIcon
              onClick={() => setShow("more details")}
              style={{
                color: "black",
              }}
            />
            <p style={{ color: "black" }}>Add Service </p>
          </div>
          <div
            className="completeRegistration-form-body"
            style={{ marginTop: "4rem" }}
          >
            <p style={{ fontWeight: "bold" }}>Services </p>
            {add.map((item, i) => {
              if (i < 10) {
                return (
                  <input
                    placeholder="Enter "
                    className="reg-inp"
                    value={item.name}
                    onChange={(e) => handleAdd(e, i)}
                    maxLength={20}
                  />
                );
              }
            })}
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginTop: "1rem",
            }}
          >
            <Button
              variant="contained"
              style={{
                backgroundColor: theme.palette.secondary.main,
                borderRadius: "12px",
                height: "3rem",
                width: "169px",
              }}
              onClick={() => setAdd([...add, { name: "" }])}
            >
              Add more
            </Button>
          </div>
          <div
            onClick={() => setShow("socialMedia")}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              margin: "2rem",
            }}
          >
            <div className="next-btn">
              <BsArrowRight />
            </div>
          </div>
        </Fragment>
      ) : null}
      {show == "socialMedia" ? (
        <Fragment>
          <div
            style={{
              backgroundColor: "#fff",
              boxShadow: "-2px 0px 5px rgba(22, 107, 142, 0.25)",
              color: "black",
              display: "flex",
              width: "100%",
              alignItems: "center",
              gap: "1rem",
              flexDirection: "row",
              padding: "1rem",
              position: "fixed",
              top: 0,
            }}
          >
            <ArrowBackIcon
              onClick={() => {
                if (
                  trailPlan &&
                  trailPlan?.data?.results?.[0]?.subscription_features?.service
                ) {
                  setShow("service");
                } else {
                  setShow("more details");
                }
              }}
              style={{
                color: "black",
              }}
            />
            <p style={{ color: "black" }}>Add Social Media </p>
          </div>

          <div
            className="completeRegistration-form-body"
            style={{ marginTop: "4rem" }}
          >
            <p style={{ fontWeight: "bold" }}>Google Location Link</p>
            <input
              placeholder="Enter"
              className="reg-inp"
              value={googleLocation}
              onChange={(e) => setGoogleLocation(e.target.value)}
            />

            <p style={{ fontWeight: "bold" }}>Whatsapp Phone number </p>
            <input
              placeholder="Enter"
              className="reg-inp"
              value={Whatsapp}
              onChange={(e) => setWhatsApp(e.target.value)}
            />

            <p style={{ fontWeight: "bold" }}>Facebook </p>
            <input
              placeholder="Enter the profile link "
              className="reg-inp"
              value={facebook}
              onChange={(e) => setFacebook(e.target.value)}
            />

            <p style={{ fontWeight: "bold" }}>Instagram </p>
            <input
              placeholder="Enter the profile link "
              className="reg-inp"
              value={instagram}
              onChange={(e) => setInstagram(e.target.value)}
            />

            <p style={{ fontWeight: "bold" }}>Youtube </p>
            <input
              placeholder="Enter the profile link"
              className="reg-inp"
              value={youtube}
              onChange={(e) => setYoutube(e.target.value)}
            />

            <p style={{ fontWeight: "bold" }}>Website </p>
            <input
              placeholder="Enter the Website link"
              className="reg-inp"
              value={website}
              onChange={(e) => setWebsite(e.target.value)}
            />
          </div>

          <div
            onClick={() => setShow("coverPhoto")}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              margin: "2rem",
            }}
          >
            <div className="next-btn">
              <BsArrowRight />
            </div>
          </div>
        </Fragment>
      ) : null}

      {show == "coverPhoto" ? (
        <Fragment>
          <div
            style={{
              backgroundColor: "#fff",
              boxShadow: "-2px 0px 5px rgba(22, 107, 142, 0.25)",
              color: "black",
              display: "flex",
              width: "100%",
              alignItems: "center",
              gap: "1rem",
              flexDirection: "row",
              padding: "1rem",
              position: "fixed",
              top: 0,
              zIndex: "100",
            }}
          >
            <ArrowBackIcon
              onClick={() => setShow("socialMedia")}
              style={{
                color: "black",
              }}
            />
            <p style={{ color: "black" }}>Add Cover Photo</p>
          </div>

          <div
            style={{
              marginTop: "50px",
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            {cover.map((item, i) => (
              <div
                style={{
                  position: "relative",
                  display: "flex",
                  justifyContent: "center",
                  top: "20px",
                }}
              >
                {" "}
                <img
                  src={item}
                  width={"92%"}
                  height={"224px"}
                  style={{
                    borderRadius: "20px",
                    border: "4px solid rgba(22, 107, 142, 1)",
                    marginBottom: "10px",
                  }}
                />
                <img
                  src={deleteicon}
                  style={{
                    position: "absolute",
                    width: "24px",
                    height: "24px",
                    cursor: "pointer",
                    right: "30px",
                    top: "19px",
                  }}
                  onClick={() => {
                    setOpenModal(true);
                    setId(i);
                    setSelectedImage("cover");
                  }}
                />
              </div>
            ))}
          </div>
          {trailPlan?.data?.results[0]?.subscription_features?.cover_images ==
          cover.length ? (
            ""
          ) : (
            <div
              style={{
                marginTop: cover.length > 0 ? "2rem" : "5rem",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <input
                style={{
                  display: "none",
                }}
                type="file"
                id="file"
                accept=".jpg,.jpeg,.png"
                ref={ref}
                onChange={(e) => handleCoverImage(e)}
              />
              <label
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  cursor: "pointer",
                  color: "#677eff",
                  width: "92%",
                  height: "3rem",
                  border: "none",
                  color: "info",
                  backgroundColor: "#fff",
                  border: "solid 3px #166B8E",
                  borderRadius: "35px",
                  padding: "8rem",
                }}
                htmlFor="file"
              >
                <i className=" fs-3 fa-solid fa-image"></i>
                <span
                  style={{
                    width: "100%",
                    borderRadius: "30px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                  }}
                >
                  <img src="/images/img-icon.png" />
                  <p style={{ color: "#2182B1" }}>
                    Add Cover Image for your business
                  </p>
                </span>
              </label>
            </div>
          )}

          {trailPlan?.data?.results[0]?.subscription_features?.cover_images ==
          cover.length ? (
            ""
          ) : (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                margin: "2rem 0",
              }}
            >
              <Button
                variant="contained"
                style={{
                  backgroundColor: theme.palette.secondary.main,
                  borderRadius: "12px",
                  height: "3rem",
                }}
                onClick={() => ref.current.click()}
              >
                Add more images
              </Button>
            </div>
          )}

          <div
            onClick={() => setShow("catelogue")}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              margin: "2rem",
              position: "fixed",
              bottom: 0,
              width: "82%",
            }}
          >
            <div className="next-btn">
              <BsArrowRight />
            </div>
          </div>
        </Fragment>
      ) : null}

      {show === "catelogue" ? (
        <Fragment>
          <div
            style={{
              backgroundColor: "#fff",
              boxShadow: "-2px 0px 5px rgba(22, 107, 142, 0.25)",
              color: "black",
              display: "flex",
              width: "100%",
              alignItems: "center",
              gap: "1rem",
              flexDirection: "row",
              padding: "1rem",
              position: "fixed",
              top: 0,
              zIndex: "100",
            }}
          >
            <ArrowBackIcon
              onClick={() => setShow("coverPhoto")}
              style={{
                color: "black",
              }}
            />
            <p style={{ color: "black" }}>Add Catalogue Photos</p>
          </div>

          <div
            style={{
              marginTop: "50px",
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            {catelogue.map((item, i) => (
              <>
                <div
                  style={{
                    position: "relative",
                    display: "flex",
                    justifyContent: "center",
                    top: "20px",
                  }}
                >
                  {" "}
                  <img
                    src={item?.url}
                    width={"92%"}
                    height={"224px"}
                    style={{
                      borderRadius: "20px",
                      border: "4px solid rgba(22, 107, 142, 1)",
                      marginBottom: "10px",
                    }}
                  />
                  <img
                    src={deleteicon}
                    style={{
                      position: "absolute",
                      width: "24px",
                      height: "24px",
                      cursor: "pointer",
                      right: "30px",
                      top: "19px",
                    }}
                    onClick={() => {
                      setOpenModal(true);
                      setId(i);
                      setSelectedImage("catelogue");
                    }}
                  />
                </div>
                <div
                  style={{ width: "100%", marginTop: "6px", padding: "10px 20px" }}
                >
                  <p style={{ fontWeight: "bold", marginBottom: "6px" }}>
                    Title<span style={{ color: "red" }}>*</span>
                  </p>
                  <input
                    placeholder="Business Name"
                    className="reg-inp"
                    value={item.title}
                    maxLength={14}
                    onChange={(e) => {
                      let data = [...catelogue];
                      data[i].title = e.target.value;
                      data[i].titleError = "";
                      setCatelogue([...data]);
                    
                    }}
                  />
                  {item.titleError && item.titleError.length > 0 && (
                    <div className="error-message">{item.titleError}</div>
                  )}
                </div>
              </>
            ))}
          </div>
          {catelogue.length ==
          trailPlan?.data?.results[0]?.subscription_features
            ?.catalogue_images ? null : (
            <div
              style={{
                marginTop: "2rem",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <input
                style={{
                  display: "none",
                }}
                type="file"
                id="file"
                accept=".jpg,.jpeg,.png"
                ref={catelogueRef}
                onChange={(e) => handleCatelogueImage(e)}
              />
              <label
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  cursor: "pointer",
                  color: "#677eff",
                  width: "92%",
                  height: "3rem",
                  border: "none",
                  color: "info",
                  backgroundColor: "#fff",
                  border: "solid 3px #166B8E",
                  borderRadius: "35px",
                  padding: "8rem",
                }}
                htmlFor="file"
              >
                <i className=" fs-3 fa-solid fa-image"></i>
                <span
                  style={{
                    width: "100%",
                    borderRadius: "30px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                  }}
                >
                  <img src="/images/img-icon.png" />
                  <p style={{ color: "#2182B1" }}>
                    Add Catalogue Image for your business
                  </p>
                </span>
              </label>
            </div>
          )}

          {catelogue.length ==
          trailPlan?.data?.results[0]?.subscription_features
            ?.catalogue_images ? (
            ""
          ) : (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                margin: "2rem 0",
              }}
            >
              <Button
                variant="contained"
                style={{
                  backgroundColor: theme.palette.secondary.main,
                  borderRadius: "12px",
                  height: "3rem",
                }}
              >
                Add{" "}
                {trailPlan?.data?.results[0]?.subscription_features
                  ?.catalogue_images - catelogue.length}{" "}
                more images
              </Button>
            </div>
          )}

          <div
            onClick={handleBusiness}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              margin: "2rem",
              position: "fixed",
              bottom: 0,
              width: "82%",
            }}
          >
            <div className="next-btn">
              <FaSave style={{ fontSize: "1.5rem" }} />
            </div>
          </div>
        </Fragment>
      ) : null}

      {openModal ? (
        <ImageRemovalModal open={openModal} handleClose={handleClose} />
      ) : null}
      {openError ? (
        <ErrorModal
          open={openError}
          handleClose={handleError}
          message={errorMessage}
        />
      ) : null}

      {openSuccess ? (
        <SuccessModal
          open={openSuccess}
          handleClose={handleSuccess}
          title="Go to home"
          message="Your business will be listed after admin's approval"
        />
      ) : null}
      {openCropModal ? (
        <CropModal
          open={openCropModal}
          handleClose={handleCloseCrop}
          setCroppingImage={setCroppingImage}
          croppingImage={croppingImage}
        />
      ) : null}
      {uploadModal ? (
        <UploadModal open={uploadModal} handleClose={handleCloseUpload} />
      ) : null}
      {categoryModal ? (
        <CategoryModal
          open={categoryModal}
          setOpen={setCategoryModal}
          handleClose={handleCategoryModal}
          categoryId={categoryId}
        />
      ) : null}
      {subCategoryModal ? (
        <SubCategoryModal
          open={subCategoryModal}
          setOpen={setSubCategoryModal}
          handleClose={handleSubCategoryModal}
          categoryId={categoryId}
          subCategoryId={subCategoryId}
        />
      ) : null}
    </div>
  );
};

export default AddBusiness;
