import {
  Button,
  Card,
  CardContent,
  Grid,
  Typography,
  Box,
  Rating,
} from "@mui/material";
import React, { useEffect } from "react";
import Checkbox from "@mui/material/Checkbox";
import FavoriteBorder from "@mui/icons-material/FavoriteBorder";
import Favorite from "@mui/icons-material/Favorite";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import CallIcon from "@mui/icons-material/Call";
import { useHistory, Link } from "react-router-dom";
import useBusinessQuery from "../store/Bussiness/useBusinessQuery";
import thumbnail from "../assets/thumbnail.png";
import locations from "../assets/locations.svg";
const label = { inputProps: { "aria-label": "Checkbox demo" } };

const ProductCard = ({ item }) => {
  const history = useHistory();

  const local = JSON.parse(localStorage.getItem("user"));

  const { mutateAsync: addBookmark, isSuccess } =
    useBusinessQuery.Add_bookmark();

  const { data: bookmarkList } = useBusinessQuery.Get_bookmark_list();

  const { mutateAsync: addCalls } = useBusinessQuery.Add_calls();

  const handleAddFavourite = async (e, id) => {
    if (local?.id) {
      try {
        await addBookmark(id);
      } catch (error) { }
    } else {
      return;
    }
  };

  const handleCalls = async () => {
    try {
      await addCalls({ business_id: parseInt(item.id) });
    } catch (error) { }
  };

  return (

    <Card
      sx={{ minHeight: "115px", mx: .2, mt: 2 }}
    >
      <CardContent
        sx={{
          padding: "4px 12px !important",
          display: "flex",
          alignItems: "center",
          gap: "10px",
          justifyContent: "space-evenly",
              
        }}
      >
        <Grid container spacing={1}>
          <Grid item xs={5}>
            <div
              style={{ cursor: "pointer", width: "100%", height: "100%" }}
              onClick={() => history.push(`/product-details/${item.id}`)}
                  
            >
              <img
                src={item.image ? item.image : thumbnail}
                alt="not found"
                width="100%"
                height="105px"
                    
                style={{ borderRadius: "12px", objectFit: "cover" }}
              />
            </div>
          </Grid>
          <Grid item xs={7}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography
                style={{ fontWeight: "600", fontSize: "12px", lineHeight: "14px" }}
                onClick={() => history.push(`/product-details/${item.id}`)}
              >
                {item.name}
              </Typography>
              <Box
                sx={{
                  width: "24px",
                  height: "24px",
                  border: "1px solid rgba(238, 238, 238, 1)",
                  borderRadius: "20px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Checkbox
                  {...label}
                  checked={
                    bookmarkList?.data?.results?.some(
                      (value) => value.id == item.id
                    )
                      ? true
                      : false
                  }
                  checkedIcon={
                    <Favorite
                      style={{
                        color: "#ff0000",
                        width: "15px",
                        height: "15.65px",
                      }}
                    />
                  }
                  icon={
                    <FavoriteBorder
                      style={{
                        color: "#2b2b2b",
                        width: "15px",
                        height: "15.65px",
                      }}
                    />
                  }
                  onChange={(e) => handleAddFavourite(e, item.id)}
                  sx={{
                    width: "24px",
                    height: "24px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                />
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: item.is_premium ? "space-between" : "start",
                alignItems: "center",
                marginTop: "4px",
              }}
              onClick={() => history.push(`/product-details/${item.id}`)}
            >
              <Box
                sx={{ display: "flex", alignItems: "center", gap: "3px" }}
              >
                <Rating
                  name="rating"
                  value={item?.average_rating ? item?.average_rating : 0}
                  precision={0.1}
                  sx={{ fontSize: "12px" }}
                  readOnly
                />{" "}
                <Typography sx={{ fontSize: "7.5px", fontWeight: "600" }}>
                  {item.no_of_ratings} reviews
                </Typography>
              </Box>
              {item.is_premium ? (
                <Typography
                  style={{
                    backgroundColor: "rgba(240, 150, 48, 1)",
                    width: "50px",
                    height: "16px",
                    borderRadius: "4px",
                    color: "#fff",
                    fontSize: "8px",
                    fontWeight: "600",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  Premium
                </Typography>
              ) : null}
            </Box>

            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "start",
                marginTop: "5px",
                gap: "3px",
              }}
            >
              <img
                src={locations}
                alt="not found"
                width="7.98px"
                height="12px"
              />
              <Typography style={{ fontSize: "12px", fontWeight: "500" }}>
                {item.district_name && item.district_name}
              </Typography>
            </div>
            <Box sx={{ marginTop: "7px" }}>
              <Grid container spacing={1} sx={{ paddingBottom: "4px" }}>
                <Grid item xs={6}>
                  <Button
                    variant="contained"
                    style={{
                      fontSize: "10px",
                      borderRadius: "6px",
                      width: "100%",
                      textTransform: "none",
                      padding: "8px 6px",
                      height: "29px",
                      background: "rgba(33, 130, 177, 1)",
                      width: "100%",
                      gap: "10px",
                    }}
                    onClick={handleCalls}
                    sx={{
                      "& .MuiButton-startIcon": {
                        margin: "0px",
                      },
                    }}
                    startIcon={<CallIcon style={{ fontSize: "10px" }} />}
                  >
                    <a
                      href={`tel:${item.mobile_number}`}
                      style={{
                        color: "white",
                        textDecoration: "none",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      Call Now{" "}
                    </a>
                  </Button>
                </Grid>
                <Grid item xs={6}>
                  <Button
                    variant="contained"
                    style={{
                      fontSize: "10px",
                      borderRadius: "6px",
                      width: "100%",
                      textTransform: "none",
                      padding: "8px 6px",
                      background: "rgba(37, 207, 67, 1)",
                      height: "29px",
                      width: "100%",
                      gap: "10px",
                    }}
                    sx={{
                      "& .MuiButton-startIcon": {
                        margin: "0px",
                      },
                    }}
                    startIcon={
                      <WhatsAppIcon style={{ fontSize: "10px" }} />
                    }
                  >
                    <a
                      style={{
                        color: "white",
                        textDecoration: "none",
                        display: "flex",
                        alignItems: "center",
                      }}
                      href={`https://wa.me/91${item.whatsapp_number}?text=Hello`}
                    >
                      Whatsapp
                    </a>
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
     
  );
};

export default ProductCard;
