import DataService from "./Dataservice"


const changeNumber = async (values)=>{
    let url ="/auth/change-mobile-number/"
    const data = await DataService.post(url,values)
    return data?.data

}


const verifyNumber = async (values)=>{
    let url = "/auth/verify-mobile-number/"
    const data = await DataService.post(url,values)
    return data?.data
}

export {
    changeNumber,
    verifyNumber
}