import React from "react";
import BottomNav from "../../components/Bottom nav/BottomNav";
import "./Dashboard.css";
import theme from "../../utils/theme";

import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Button } from "@mui/material";
import Listing from "./Listing/Listing";
import Bookmark from "./bookmark/Bookmark";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ padding: "10px" }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const MyListing = () => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <div>
      <div
        style={{ backgroundColor: theme.palette.primary.main }}
        className="listing-header"
      >
        <p style={{ margin: 0 }}>My Listings</p>
      </div>
      <div className="list-content">
        <Box sx={{ width: "100%", position: "fixed" }}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              className="list-tabs"
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              <Tab className="mylist-btn" label="Listings" {...a11yProps(0)} />
              <Tab className="mylist-btn" label="Bookmarks" {...a11yProps(1)} />
            </Tabs>
          </Box>
          <TabPanel value={value} index={0}>
            <Listing />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <Box
              sx={{
                height: "80vh",
                overflow: "scroll",
                paddingBottom: "100px",
              }}
            >
              <Bookmark />
            </Box>
          </TabPanel>
        </Box>
      </div>
      <BottomNav />
    </div>
  );
};

export default MyListing;
