import DataService from "../Dataservice";

const getService = async (id) => {
  let url = `/business/my_business/${id}/services/`;
  const data = await DataService.get(url);
  return data?.data;
};

const AddService = async (values) => {
  const { id, ...editData } = values;
  let url = `/business/my_business/${id}/services/`;
  const data = await DataService.post(url, values);
  return data?.data;
};

const editservice = async (values) => {
  const { id, business_id, ...editData } = values;
  let url = `/business/my_business/${business_id}/service/${id}/`;
  const data = await DataService.patch(url, values);
  return data?.data
};

const deleteService = async (values) => {
  let url = `/business/my_business/${values.business_id}/service/${values.id}/`;
  const data = await DataService.del(url);
  return data?.data;
};

export { getService, AddService, deleteService,editservice };
