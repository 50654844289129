import { Box, Button, Modal, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import theme from "../../utils/theme";
import useMobileNumberQuery from "../../store/useMobileNumberQuery";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%",
  background: "white",
  padding: "24px 30px",
  borderRadius: "24px",
};

function OtpModal({ open, handleClose, mobile }) {
  const [value1, setValue1] = useState("");
  const [value2, setValue2] = useState("");
  const [value3, setValue3] = useState("");
  const [value4, setValue4] = useState("");

  const handleChange = (e, value) => {
    if (value == "inpuField2") {
      if (e.target.value == "") {
        setValue1(e.target.value);
      } else {
        setValue1(e.target.value);
        document.getElementById("inpuField2").focus();
      }
    } else if (value == "inpuField3") {
      if (e.target.value == "") {
        setValue2(e.target.value);
      } else {
        setValue2(e.target.value);
        document.getElementById("inpuField3").focus();
      }
    } else if (value == "inpuField4") {
      if (e.target.value == "") {
        setValue3(e.target.value);
      } else {
        setValue3(e.target.value);
        document.getElementById("inpuField4").focus();
      }
    } else if (value == "inpuField1") {
      setValue4(e.target.value);
    }
  };

  const {
    mutateAsync: verify,
    isSuccess,
    isError,
    error,
  } = useMobileNumberQuery.Verify_number();

  const handleSubmit = async () => {
    if (!value1 || !value2 || !value3 || !value4) return;
    else {
      try {
        verify({
          mobile,
          otp: value1 + value2 + value3 + value4,
        });
      } catch (error) {}
    }
  };

  useEffect(() => {
    if (isSuccess) {
      handleClose();
    }
  }, [isSuccess, isError]);

  return (
    <Modal open={open} onClose={handleClose}>
      <Box sx={style}>
        <div className="signIn-title">
          <h1 style={{ margin: 0 }}>OTP</h1>
          <p style={{ margin: 0 }}>
            Enter the one-time password (OTP) we've sent to 9888XXXX88 to
            securely verify your account
          </p>
        </div>
        <div className="signIn-otp-body">
          <div className="round-inp-body">
            <input
              className="round-inp"
              value={value1}
              onChange={(e) => {
                handleChange(e, "inpuField2");
              }}
              maxLength={1}
            />
            <input
              className="round-inp"
              value={value2}
              onChange={(e) => {
                handleChange(e, "inpuField3");
              }}
              maxLength={1}
              id="inpuField2"
            />
            <input
              className="round-inp"
              value={value3}
              onChange={(e) => {
                handleChange(e, "inpuField4");
              }}
              maxLength={1}
              id="inpuField3"
            />
            <input
              className="round-inp"
              value={value4}
              onChange={(e) => {
                handleChange(e, "inpuField1");
              }}
              maxLength={1}
              id="inpuField4"
            />
          </div>
        </div>
        <div className="signIn-btn-body2">
          <Button
            style={{ backgroundColor: theme.palette.secondary.main }}
            className="signIn-btn2"
            variant="contained"
            onClick={handleSubmit}
          >
            Submit
          </Button>
        </div>
      </Box>
    </Modal>
  );
}

export default OtpModal;
