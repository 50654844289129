import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import React from "react";
import { Button } from "@mui/material";
import ".././registrationModal/RegistrationSuccessModal.css";
import theme from "../../utils/theme";
import { useHistory } from "react-router-dom";

const LoginReward = (props) => {
  const history = useHistory();

  const handleRedirect = () => {
    history.push("/sign-in");
    props.setToggle(false);
  };

  const style = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  };

  return (
    <div>
      <Modal
        style={style}
        open={props.toggle}
        onClose={props.handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="modal-body">
          <img
            style={{ marginBottom: "1rem", marginTop: ".8rem" }}
            src="/images/RewardBadge.png"
          />
          <Typography
            id="modal-modal-description"
            style={{
              textAlign: "center",
              marginTop: "1rem",
              marginBottom: "1rem",
            }}
          >
            Login Now to Get 2 month free premium subscription
          </Typography>
          <Button
            className="btn-reg-success"
            variant="contained"
            style={{ backgroundColor: theme.palette.secondary.main }}
            onClick={() => handleRedirect()}
          >
            Login Now
          </Button>
        </Box>
      </Modal>
    </div>
  );
};

export default LoginReward;
