import React, { Fragment, useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { Button, Grid, Box } from "@mui/material";
import theme from "../../../../utils/theme";
import { useParams, useHistory ,useLocation} from "react-router-dom";
import { FaSave } from "react-icons/fa";
import useServiceQuery from "../../../../store/Service/useServiceQuery";
import { BsFillPenFill } from "react-icons/bs";
import { AiOutlineClose } from "react-icons/ai";
import "./EditBusiness.css";
import useBusinessQuery from "../../../../store/Bussiness/useBusinessQuery";
import SuccessModal from "../Add business/SuccessModal";

function EditService() {
  const { id } = useParams();
  const history = useHistory();
 

  const [add, setAdd] = useState([]);
  const [plan, setPlan] = useState({});
  const [openSuccess, setOpenSuccess] = useState(false);

  const { data: subscriptionDetail, isSuccess: businessSuccess } =
    useBusinessQuery.Get_my_currentplan(id);

  const { data: getService, isSuccess: getSuccess } =
    useServiceQuery.Get_service(id);

  const { mutateAsync: addService, isSuccess } = useServiceQuery.Add_service();

  const { mutateAsync: deleteService, isSuccess: deleteSuccess } =
    useServiceQuery.Del_service();

  const { mutateAsync: editService } = useServiceQuery.Edit_service();

  const handleService = async () => {
    const data = add.filter((item) => !item.id);
    try {
      await addService({
        id,
        name: data[0].name,
        is_active: true,
      });
    } catch (error) {}
  };

  const handleAdd = (e, i) => {
    let data = add[i];
    data.name = e.target.value;
    data.is_active = true;
    add[i] = data;
    setAdd([...add]);
  };

  const handleEdit = async (value) => {
    const data = add.find((item) => item.id == value);
    try {
      await editService({
        name: data.name,
        id: value,
        business_id: id,
        is_active: data.is_active,
      });
    } catch (error) {}
  };

  const handleDelete = async (value) => {
    if (!value && add.length != 1) {
      return;
    } else if (add.length == 1) {
      return;
    } else {
      const data = add.filter((item) => item.id != value);
      setAdd([...data]);
      try {
        await deleteService({
          business_id: id,
          id: value,
        });
      } catch (error) {}
    }
  };

  const handleSuccess = () => {
    setOpenSuccess(false);
    history.push("/my-list");
  };

  useEffect(() => {
    if (businessSuccess && subscriptionDetail) {
      setPlan(subscriptionDetail?.data?.selected_plan?.subscription_features);
    }
  }, [businessSuccess]);

  useEffect(() => {
    if (getSuccess || deleteSuccess || getService || isSuccess) {
      if (getSuccess && getService && plan?.service) {

        if (getService?.data?.results?.length == 0) {
          setAdd([{ name: "", is_active: true }]);
        } else {
          if (plan?.service == getService?.data?.results?.length) {
            setAdd([...getService?.data?.results]);
          } else {
            setAdd([
              ...getService?.data?.results,
              { name: "", is_active: true },
            ]);
          }
        }
      }
    }
  }, [getSuccess, deleteSuccess, getService, isSuccess]);

  return (
    <div>
      <Fragment>
        <div
          style={{
            backgroundColor: "#fff",
            boxShadow: "-2px 0px 5px rgba(22, 107, 142, 0.25)",
            color: "black",
            display: "flex",
            width: "100%",
            alignItems: "center",
            gap: "1rem",
            flexDirection: "row",
            padding: "1rem",
            position: "fixed",
            top: 0,
          }}
        >
          <CloseIcon
            style={{
              color: "black",
            }}
            onClick={() => history.push("/my-list")}
          />
          <p style={{ color: "black" }}>Edit Service </p>
        </div>
        <div
          className="completeRegistration-form-body"
          style={{ marginTop: "4rem" }}
        >
          <p style={{ fontWeight: "bold" }}>Services </p>
          {add.map((item, i) => {
            return (
              <Grid
                container
                alignItems={"center"}
                spacing={2}
                sx={{ marginBottom: "10px" }}
              >
                <Grid item xs={9}>
                  <input
                    placeholder="Enter "
                    className="reg-inp1"
                    value={item?.name}
                    maxLength={20}
                    onChange={(e) => handleAdd(e, i)}
                    key={i}
                  />
                </Grid>
                <Grid item xs={3}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "100%",
                    }}
                  >
                    <Button
                      className="edit-service-btn"
                      onClick={() => handleEdit(item.id)}
                    >
                      <BsFillPenFill />
                    </Button>
                    <Button
                      className="edit-service-btn"
                      sx={{
                        backgroundColor: "rgba(240, 150, 48, 1) !important",
                      }}
                      onClick={() => handleDelete(item.id)}
                    >
                      <AiOutlineClose />
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            );
          })}
        </div>
        {getService?.data?.results?.length != plan?.service ? (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginTop: "1rem",
            }}
          >
            <Button
              variant="contained"
              style={{
                backgroundColor: theme.palette.secondary.main,
                borderRadius: "12px",
                height: "3rem",
                width: "169px",
              }}
              onClick={handleService}
            >
              Add {plan?.service - getService?.data?.results?.length} more
            </Button>
          </div>
        ) : null}

        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            margin: "2rem",
          }}
        >
          <div className="next-btn" onClick={() => setOpenSuccess(true)}>
            <FaSave />
          </div>
        </div>
      </Fragment>
      {openSuccess ? (
        <SuccessModal
          open={openSuccess}
          handleClose={handleSuccess}
          title="Ok"
          message="Services edited successfully"
        />
      ) : null}
    </div>
  );
}

export default EditService;
