import { useQuery } from "react-query";
import {
  getCategory,
  getCities,
  getDistrcit,
  getNewsChannel,
  getNewsPaper,
  getPublicServiceCategory,
  getServiceLink,
  getState,
  getSubCategory,
} from "./commonQueryUrl";

const Get_district = (id) => {
  return useQuery(["district", id], () => getDistrcit(id), {
    enabled: !!id,
  });
};
const Get_cities = (id) => {
  return useQuery(["city", id], () => getCities(id), {
    enabled: !!id,
  });
};

const Get_state = () => {
  return useQuery(["state"], () => getState());
};
const Get_public_serivce = (query) => {
  return useQuery(["publicService", query], () =>
    getPublicServiceCategory(query)
  );
};

const Get_category = (query) => {
  return useQuery(["category", query], () => getCategory(query));
};

const Get_channel = (query) => {
  return useQuery(["newsChannel", query], () => getNewsChannel(query));
};
const Get_newsPaper = (query) => {
  return useQuery(["newsPaper", query], () => getNewsPaper(query));
};

const Get_subcategory = (query) => {
  return useQuery(["subcategory", query], () => getSubCategory(query), {});
};

const Get_service_link = (query) => {
  return useQuery(["serviceLink", query], () => getServiceLink(query), {});
};

const useCommonQuery = {
  Get_district,
  Get_cities,
  Get_state,
  Get_category,
  Get_channel,
  Get_newsPaper,
  Get_subcategory,
  Get_public_serivce,
  Get_service_link,
};

export default useCommonQuery;
