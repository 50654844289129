import React, { useEffect, useState } from "react";
import "./MyProfile.css";
import BottomNav from "../../components/Bottom nav/BottomNav";
import theme from "../../utils/theme";
import { useHistory } from "react-router-dom";
import useProfileQuery from "../../store/Profile/useProfileQuery";
import { Button, Typography } from "@mui/material";
import LogoutIcon from "@mui/icons-material/Logout";
import useBusinessQuery from "../../store/Bussiness/useBusinessQuery";

const Profile = () => {
  const history = useHistory();
  const [name, setName] = useState("");
  const [image, setImage] = useState("");
  const local = JSON.parse(localStorage.getItem("user"));

  const { data: bussinessList, isSuccess } =
    useBusinessQuery.Get_my_bussiness();
  const handleLogout = () => {
    if (local?.id) {
      localStorage.clear("accessToken");
      localStorage.clear("user");
      localStorage.clear("refreshToken");
    }

    history.push("/sign-in");
    setName("");
  };

  useEffect(() => {
    if (isSuccess && bussinessList && local?.id) {
      setName(bussinessList?.data?.results[0]?.name);
      setImage(bussinessList?.data?.results[0]?.image);
    }
  }, [isSuccess, bussinessList]);

  return (
    <div>
      <div
        style={{ backgroundColor: theme.palette.primary.main }}
        className="pro-head"
      >
        <p>My Profile</p>
      </div>

      <div className="pro-img">
        <div
          style={{
            borderRadius: "50%",
            width: "96px",
            height: "96px",
            overflow: "hidden",
          }}
        >
          <img
            src={image ? image : "/images/user-img.png"}
            alt="not Found"
            width="100%"
            height="100%"
          />
        </div>

        <Typography
          sx={{ fontSize: "20px", fontWeight: "600", textAlign: "center" }}
        >
          {name}
        </Typography>
      </div>

      <div className="options-body">
        <div
          className="profile-inp"
          onClick={() => history.push("/help-support")}
        >
          <p>Customer Support</p>
          <img src="/images/right-arrow.png" alt="not found" />
        </div>
      </div>
      <div className="options-body">
        {local?.id ? (
          <Button
            className="profile-inp"
            onClick={() => handleLogout()}
            style={{
              background: "#0E3A53",
              color: "white",
              width: "120px",
            }}
            endIcon={<LogoutIcon />}
          >
            Logout
          </Button>
        ) : (
          <Button
            className="profile-inp"
            onClick={() => handleLogout()}
            style={{
              background: "#0E3A53",
              color: "white",
              width: "120px",
            }}
            endIcon={<LogoutIcon />}
          >
            Sign In
          </Button>
        )}
      </div>

      <BottomNav />
    </div>
  );
};

export default Profile;
