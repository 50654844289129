import Axios from "axios";

export const http = Axios.create({
  baseURL: "https://backend.masterclick.in/api/v1",
  headers: { Accept: "application/json" },
});

http.interceptors.request.use(async (config) => {
  let accessToken = localStorage.getItem("accessToken");
  config.headers["authorization"] = `Bearer ${accessToken}`;
  return config;
});

http.interceptors.response.use(undefined, function (err) {
  var statusCode = err.status;
  if (statusCode === undefined) {
    var lineSplit = err.toString().split("\n")[0].split(" ");
    statusCode = lineSplit[lineSplit.length - 1];
  }
  if (statusCode == 401) {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("user");
    // window.location.href = "/auth-home";
  }
  if (statusCode == 400) {
    throw err?.response;
  }
  if (statusCode == 500) {
    throw err?.response;
  }
});

export const formData_instance = Axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    "Content-Type": "multipart/form-data",
  },
});

formData_instance.interceptors.request.use(async (config) => {
  let accessToken = localStorage.getItem("accessToken");
  config.headers["authorization"] = `Bearer ${accessToken}`;
  return config;
});

http.interceptors.response.use(
  async (response) => {
    return response;
  },
  async (error) => {
    return Promise.reject(error);
  }
);

formData_instance.interceptors.response.use(
  async (response) => {
    return response;
  },
  async (error) => {
    return Promise.reject(error);
  }
);

export const httpWithoutToken = Axios.create({
  baseURL: "https://backend.masterclick.in/api/v1",
  headers: { Accept: "application/json" },
});

httpWithoutToken.interceptors.request.use(async (config) => {
  return config;
});

httpWithoutToken.interceptors.response.use(undefined, function (err) {
  var statusCode = err.status;
  if (statusCode === undefined) {
    var lineSplit = err.toString().split("\n")[0].split(" ");
    statusCode = lineSplit[lineSplit.length - 1];
  }
  if (statusCode == 401) {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("user");
    // window.location.href = "/auth-home";
  }
  if (statusCode == 400) {
    throw err?.response;
  }
});
