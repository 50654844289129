import { Card, CardContent, Grid, Typography } from "@mui/material";
import React from "react";
import noPic from "../assets/no-pic.png";

const CategoreyCard = (props) => {

  return (
    <Grid item xs={4} >
      <Card
        onClick={() => props.handleClick(props.id)}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          borderRadius: "8px",
          height: "93px",
          boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.15)",
        }}
      >
        <CardContent
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            padding: "16px 0px 24px 0px",
          }}
        >
          <img
            width="40px"
            height="40px"
            src={props.image ? props.image : noPic}
          />
          <Typography
            color="textSecondary"
            style={{
              fontSize: "12px",
              fontWeight: "500",
              textAlign: "center",
              marginTop: "5px",
            }}
          >
            {props.title}
          </Typography>
        </CardContent>
      </Card>
    </Grid>
  );
};

export default CategoreyCard;
