import { useMutation, useQuery, useQueryClient } from "react-query";
import { getProfile, updateProfile } from "./profileUrls";

const Get_profile = () => {
  return useQuery(["profile"], () => getProfile());
};

const Update_profile = () => {
  const queryClient = useQueryClient();

  return useMutation((data) => updateProfile(data), {
    onMutate: async (newData) => {
      queryClient.cancelQueries("profile");
      const prev = await queryClient.getQueryData("profile");
      return {
        prev,
        newData,
      };
    },
    onSettled: () => {
      queryClient.invalidateQueries("profile");
    },
    onError: (err, _, context) => {
      queryClient.setQueryData("profile", context.prev);
    },
  });
};

const useProfileQuery = {
  Get_profile,
  Update_profile,
};

export default useProfileQuery;
