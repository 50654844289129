import React, { useEffect, useRef, useState } from "react";
import Header from "../../components/header/Header";
import BottomNav from "../../components/Bottom nav/BottomNav";

import { MdDashboard } from "react-icons/md";
import { GiHamburgerMenu } from "react-icons/gi";

import { Grid, Typography } from "@mui/material";
import CategoreyCard from "../../components/CategoreyCard";

import "./ListView.css";
import useCommonQuery from "../../store/common/useCommonQuery";
import noPic from "../../assets/no-pic.png";
import { useHistory } from "react-router-dom";

const CategoryListView = () => {
  const history = useHistory();
  const inputRef = useRef();

  const [toggle, setToggle] = useState(false);
  const [search, setSearch] = useState("");
  const [items, setItems] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [height, setHeight] = useState("");

  const query = {
    search,
    limit: 1000,
    offset: 0,
  };
  const {
    data: categoryList,
    isSuccess,
  } = useCommonQuery.Get_category(query);

  const handleGridView = () => {
    setToggle(false);
  };
  const handleListView = () => {
    setToggle(true);
  };

  const handleClick = (id) => {
    history.push(`/subcategory/${id}`);
  };

  useEffect(() => {}, [search]);

  return (
    <div
      id="parent-div"
      ref={inputRef}
      style={{ height: "100vh", overflowY: "scroll" }}
    >
      <Header />
      <div
        style={{
          display: `${toggle ? "none" : "block"}`,
          marginBottom: "5rem",
        }}
      >
        <div style={{ marginTop: "9rem" }}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              paddingLeft: "1rem",
              paddingRight: "1rem",
            }}
          >
            <h4>All Categories</h4>
            <MdDashboard
              onClick={handleListView}
              style={{ fontSize: "33px" }}
            />
          </div>
        </div>
        <Grid container spacing={2} style={{ padding: "1rem" }}>
          {categoryList &&
            categoryList?.data?.results?.map((items, idx) => {
              return (
                <CategoreyCard
                  id={items.id}
                  title={items.name}
                  image={items.image}
                  handleClick={handleClick}
                  key={items.id}
                />
              );
            })}
        </Grid>
      </div>
      <div
        style={{
          display: `${toggle ? "block" : "none"}`,
          marginBottom: "2rem",
        }}
      >
        <div style={{ marginTop: "9rem" }}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              paddingLeft: "1rem",
              paddingRight: "1rem",
            }}
          >
            <h4 style={{ margin: 0 }}>All Categories</h4>
            <GiHamburgerMenu
              onClick={handleGridView}
              style={{ fontSize: "33px" }}
            />
          </div>
        </div>
        <div style={{ position: "relative" }}>
          <input
            placeholder="Search in Category"
            className="cat-inp"
            type="text"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
          <img
            style={{ position: "absolute", right: "2.4rem", top: "1.5rem" }}
            src="/images/insideSearch.png"
          />
        </div>
        <div className="list-Card-body">
          {categoryList &&
            categoryList?.data?.results?.map((item) => {
              return (
                <div
                  className="list-Card"
                  onClick={() => history.push(`/subcategory/${item.id}`)}
                  key={item.id}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-start",
                    }}
                  >
                    <img
                      style={{ width: "25px" }}
                      src={item?.image ? item?.image : noPic}
                      alt="not found"
                    />
                  </div>
                  <Typography
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      flex: 1,

                      fontSize: "12px",
                      fontWeight: "500",
                    }}
                  >
                    {item.name}
                  </Typography>
                </div>
              );
            })}
        </div>
      </div>
      <BottomNav />
    </div>
  );
};

export default React.memo(CategoryListView);
