import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import React from "react";
import { Button } from "@mui/material";
import "./DeleteAlert.css";
import theme from "../../utils/theme";

const DeleteAlert = (props) => {
  const style = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  };
  return (
    <div>
      <Modal
        style={style}
        open={props.Toggle}
        onClose={props.Toggle}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="DeleteModal-body">
          <img src="/images/Delete.png" />
          <Typography
            id="modal-modal-description"
            style={{
              textAlign: "center",
              marginTop: "1rem",
              marginBottom: "1rem",
            }}
          >
            Are you sure you want to delete Business ?
          </Typography>
          <div style={{ display: "flex", gap: "1rem" }}>
            <Button
              sx={{ marginBottom: "1rem" }}
              className="btn-reg-success"
              variant="outlined"
              //   style={{ backgroundColor: theme.palette.secondary.main }}
              onClick={() => props.setToggle()}
            >
              Cancel
            </Button>
            <Button
              sx={{ marginBottom: "1rem" }}
              className="btn-reg-success"
              variant="contained"
              style={{ backgroundColor: theme.palette.secondary.main }}
              onClick={() => props.setToggle()}
            >
              Sure
            </Button>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default DeleteAlert;
