import React, { useContext, useEffect, useState } from "react";
import "./SignInOtp.css";
import { Button } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useHistory } from "react-router-dom";
import theme from "../../../../utils/theme";
import { variableContext } from "../../../../context/VariableContex";
import useAuthQuery from "../../../../store/auth/useAuthQuery";
import RegistrationErrorModal from "../../../../components/registrationModal/RegistrationErrorModal";
import masterClick from "../../../../assets/masterClick.svg";
import white from "../../../../assets/white.png";

const SignInOtp = () => {
  const history = useHistory();

  const { mobile } = useContext(variableContext);

  const [openError, setOpenError] = useState(false);
  const [value1, setValue1] = useState("");
  const [value2, setValue2] = useState("");
  const [value3, setValue3] = useState("");
  const [value4, setValue4] = useState("");
  const [message, setMessage] = useState("");

  const {
    mutateAsync: verify,
    isSuccess,
    isError,
    error,
  } = useAuthQuery.VerifyOtp();
  const {
    mutateAsync: sendOtp,
    isSuccess: isSendOtpSuccess,
    error: sendOtpError,
    isError: isSendOtpError,
  } = useAuthQuery.SendOtp();

  const handleChange = (e, value) => {
    if (value == "inpuField2") {
      if (e.target.value == "") {
        setValue1(e.target.value);
      } else {
        setValue1(e.target.value);
        document.getElementById("inpuField2").focus();
      }
    } else if (value == "inpuField3") {
      if (e.target.value == "") {
        setValue2(e.target.value);
      } else {
        setValue2(e.target.value);
        document.getElementById("inpuField3").focus();
      }
    } else if (value == "inpuField4") {
      if (e.target.value == "") {
        setValue3(e.target.value);
      } else {
        setValue3(e.target.value);
        document.getElementById("inpuField4").focus();
      }
    } else if (value == "inpuField1") {
      setValue4(e.target.value);
    }
  };

  const handleSubmit = async () => {
    if (value1 && value2 && value3 && value4) {
      try {
        await verify({
          mobile,
          otp: value1 + value2 + value3 + value4,
        });
      } catch (error) {}
    }
  };

  const handleResend = async () => {
    setValue1("");
    setValue2("");
    setValue3("");
    setValue4("");
    try {
      await sendOtp({
        phoneNumber: mobile,
      });
    } catch (error) {}
  };
  const handleClose = () => {
    setValue1("");
    setValue2("");
    setValue3("");
    setValue4("");
    setOpenError(false);
  };

  useEffect(() => {
    if (isSuccess) {
      history.push("/");
    }
  }, [isSuccess]);

  useEffect(() => {
    if (isError && error) {
      setMessage(error?.response?.data?.message?.non_field_errors?.[0]);
      setOpenError(true);
    }
  }, [isError, error]);

  return (
    <div
      className="signIn-bg-screen"
      style={{ backgroundColor: theme.palette.primary.main }}
    >
      <div className="">
        <ArrowBackIcon
          onClick={() => history.push("/sign-in")}
          style={{
            color: "#fff",
            position: "relative",
            top: "2rem",
            left: "1rem",
          }}
        />
      </div>
      <div className="heading-body">
        <div className="heading">
          <img src={white} width="100%" height="100%" alt="No Image" />
        </div>
      </div>

      <div className="signIn-bottomBox">
        <div className="signIn-title">
          <h1 style={{ margin: 0 }}>OTP</h1>
          <p style={{ margin: 0 }}>
            Enter the one-time password (OTP) we've sent to {mobile} to securely
            verify your account and start discovering local businesses.
          </p>
        </div>

        <div className="signIn-otp-body">
          <div className="round-inp-body">
            <input
              className="round-inp"
              value={value1}
              onChange={(e) => {
                handleChange(e, "inpuField2");
              }}
              maxLength={1}
            />
            <input
              className="round-inp"
              value={value2}
              onChange={(e) => {
                handleChange(e, "inpuField3");
              }}
              maxLength={1}
              id="inpuField2"
            />
            <input
              className="round-inp"
              value={value3}
              onChange={(e) => {
                handleChange(e, "inpuField4");
              }}
              maxLength={1}
              id="inpuField3"
            />
            <input
              className="round-inp"
              value={value4}
              onChange={(e) => {
                handleChange(e, "inpuField1");
              }}
              maxLength={1}
              id="inpuField4"
            />
          </div>
          <p
            style={{
              color: theme.palette.primary.main,
              margin: 0,
              marginTop: "1rem",
            }}
          >
            No OTP received?{" "}
            <Button
              style={{
                color: theme.palette.primary.main,
                fontWeight: "bold",
                border: "none",
              }}
              onClick={handleResend}
            >
              Resend now
            </Button>
          </p>
        </div>
        <div className="signIn-btn-body2">
          <Button
            style={{ backgroundColor: theme.palette.secondary.main }}
            className="signIn-btn2"
            variant="contained"
            onClick={handleSubmit}
          >
            Submit
          </Button>
        </div>
      </div>
      {openError ? (
        <RegistrationErrorModal
          Toggle={openError}
          handleClose={handleClose}
          message={message}
          title="Error"
        />
      ) : null}
    </div>
  );
};

export default SignInOtp;
