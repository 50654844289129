import React from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useHistory } from "react-router-dom";
import { Box, Typography } from "@mui/material";
import useCommonQuery from "../../store/common/useCommonQuery";
import "./ServiceLink.css";
import service from "../../assets/service.svg";

function ServiceLink() {
  const history = useHistory();
  const { data: serviceLink } = useCommonQuery.Get_service_link({ limit: 2000,offset:0 });

  return (
    <div>
      <div
        style={{
          backgroundColor: "#fff",
          boxShadow: "-2px 0px 5px rgba(22, 107, 142, 0.25)",
          color: "black",
          display: "flex",
          width: "100%",
          alignItems: "center",
          gap: "1rem",
          flexDirection: "row",
          padding: "1rem",
        }}
      >
        <ArrowBackIcon
          onClick={() => history.push("/")}
          style={{
            color: "black",
          }}
        />
        <p style={{ color: "black" }}>Service Link</p>
      </div>

      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          gap: "20px",
          padding: "15px 22px",
          marginBottom: "20px",
        }}
      >
        <Typography className="typo">Valuable web links</Typography>
        {serviceLink?.data?.results?.map((item) => (
          <a
            href={item?.url ? item?.url : ""}
            style={{ textDecoration: "none", width: "100%" }}
          >
            <Box className="service-main" key={item.id}>
              <Typography
                sx={{
                  textAlign: "center",
                  fontWeight: "600",
                  fontSize: "12px",
                  cursor: "pointer",
                  color: "black",
                  paddingLeft: "10px",
                }}
              >
                {item.name}
              </Typography>
            </Box>
          </a>
        ))}
      </Box>
    </div>
  );
}

export default ServiceLink;
