import React, { useEffect, useState } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import { Box } from "@mui/material";
import master from "../assets/master.png";
import { useLocation } from "react-router-dom";

const HeroCarousel = ({ data = [] }) => {
  const location = useLocation();
  const [images, setImages] = useState([]);
  useEffect(() => {
    if (data.length>0) {
      if (location.pathname.includes("product-details")) {
        const newData = data
          ?.map((item) => ({ image: item?.cover_image }))
          ?.filter((currentData) => Boolean(currentData?.image));
        setImages(newData);
      } else {
        const newData = data
          ?.map((item) => ({ image: item?.banner_image }))
          ?.filter((currentData) => Boolean(currentData?.image));
        setImages(newData);
      }
    }
  }, [data]);

  return (
    <Box>
      <Carousel
        style={{ cursor: "pointer" }}
        showIndicators={true}
        showThumbs={false}
        showStatus={false}
        autoPlay={images.length > 1 ? true : false}
        infiniteLoop={true}
        animationHandler="fade"
      >
        {images.length > 0 ? (
          images.map((item, i) => (
            <Box
              key={i}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "230px",
              }}
            >
              <img
                src={item.image}
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                }}
                loading="lazy"
                alt="hero"
              />
            </Box>
          ))
        ) : (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "230px",
            }}
          >
            <img
              src={master}
              style={{
                width: "100%",
                height: "100%",
                objectFit: "contain",
              }}
              loading="lazy"
              alt="hero"
            />
          </Box>
        )}
      </Carousel>
    </Box>
  );
};

export default React.memo(HeroCarousel);
