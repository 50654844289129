import React from "react";
import BookmarkNotFound from "./bookmarkNotFound/BookmarkNotFound";
import useBusinessQuery from "../../../store/Bussiness/useBusinessQuery";
import ProductCard from "../../../components/ProductCard";

const Bookmark = () => {
  const { data: bookmarkList } = useBusinessQuery.Get_bookmark_list();


  return (
    <div>
      {bookmarkList &&
        bookmarkList.data.results.map((item, i) => (
          <ProductCard item={item} key={i} />
        ))}
      {bookmarkList && bookmarkList.data.results.length == 0  || !bookmarkList ? (
        <div style={{ height: "55vh", display: "flex" }}>
          <BookmarkNotFound title="No Bookmarks Found" />
        </div>
      ) : null}
    </div>
  );
};

export default Bookmark;
