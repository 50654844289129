import React, { Fragment, useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import useBusinessQuery from "../../../../store/Bussiness/useBusinessQuery";
import CloseIcon from "@mui/icons-material/Close";
import { BsArrowRight } from "react-icons/bs";
import { useFormik } from "formik";
import useCommonQuery from "../../../../store/common/useCommonQuery";
import * as Yup from "yup";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ReactGoogleAutocomplete from "react-google-autocomplete";
import useStyles from "../../../../SharedClass/sharedClass";
import ErrorModal from "../Add business/ErrorModal";
import axios from "axios";
import SuccessModal from "../Add business/SuccessModal";
import CategoryModal from "../../../../components/CategoryModal/Index";
import SubCategoryModal from "../../../../components/SubCategoryModal/Index";

const modelSchema1 = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  type: Yup.string().required("Type is required"),
  phone: Yup.string().required("Number is is required"),
});

function EditDetails() {
  const { id } = useParams();
  const history = useHistory();
  const classes = useStyles();
  const Api_Key = "AIzaSyDMC5uUfcSScgVY9AGqsGQwYm8P6_LmyYg";

  const [show, setShow] = useState("business");
  const [plan, setPlan] = useState({});
  const [name, setName] = useState("");
  const [type, setType] = useState("");
  const [category, setCategory] = useState("");
  const [state, setState] = useState(1);
  const [district, setDistrict] = useState("");
  const [postOffice, setPostOffice] = useState("");
  const [pincode, setPincode] = useState("");
  const [subCategory, setSubCategory] = useState("");
  const [phonenumber, setPhonenumber] = useState("");
  const [about, setAbout] = useState("");
  const [googleLocation, setGoogleLocation] = useState("");
  const [Whatsapp, setWhatsApp] = useState("");
  const [facebook, setFacebook] = useState("");
  const [instagram, setInstagram] = useState("");
  const [youtube, setYoutube] = useState("");
  const [website, setWebsite] = useState("");
  const [latitude, setLatitude] = useState("");
  const [longitude, setLongitude] = useState("");
  const [errorModal, setErrorModal] = useState(false);
  const [placeName, setPlaceName] = useState("");
  const [locationError, setLocationError] = useState("");
  const [openSucces, setOpenSucces] = useState(false);
  const [categoryModal, setCategoryModal] = useState(false);
  const [categoryId, setCategoryId] = useState("");
  const [subCategoryModal, setSubCategoryModal] = useState(false);
  const [subCategoryId, setSubCategoryId] = useState("");
  const [categoryError, setCategoryError] = useState("");
  const [location_info, setLocationInfo] = useState("");


  const { data: businessDetails, isSuccess: businessSuccess } =
    useBusinessQuery.Get_my_bussiness_details(id);

  const { data: currentPlan, isSuccess: planSuccess } =
    useBusinessQuery.Get_my_currentplan(id);

  const {
    mutateAsync: editBussiness,
    isSuccess,
    isError,
  } = useBusinessQuery.Edit_business();

  const { data: districtList } = useCommonQuery.Get_district(state);

  useEffect(() => {
    if (businessSuccess && businessDetails) {
      setName(businessDetails.data.name);
      setType(businessDetails.data.type);
      setCategoryId(businessDetails.data.category);
      setCategory(businessDetails.data.category_name);
      setAbout(businessDetails.data.about_us);
      setPincode(businessDetails.data.pin_code);
      setPostOffice(businessDetails.data.post_office);
      setState(businessDetails.data.state);
      setSubCategoryId(businessDetails.data.sub_category);
      setSubCategory(businessDetails.data.sub_category_name);
      setDistrict(businessDetails.data.district);
      setPhonenumber(businessDetails.data.mobile_number);
      setGoogleLocation(businessDetails.data.google_location_link);
      setWhatsApp(businessDetails.data.whatsapp_number);
      setFacebook(businessDetails.data.facebook);
      setInstagram(businessDetails.data.instagram);
      setYoutube(businessDetails.data.youtube);
      setWebsite(businessDetails.data.website);

      if (businessDetails?.data?.latitude && businessDetails?.data?.longitude) {
        fetchCityName();
      }
    }
  }, [businessSuccess,businessDetails]);

  useEffect(() => {
    if (planSuccess && currentPlan) {
      const data = currentPlan?.data?.selected_plan;
      setPlan(data);
    }
  }, [planSuccess,currentPlan]);

  const initialValues = {
    name,
    type,

    phone: phonenumber,
  };

  const handleCategoryModal = (data) => {
    if (data.name) {
      setCategoryError("");
    }
    setCategoryModal(false);
    setCategoryId(data.id);
    setCategory(data.name);
  };

  const handleSubCategoryModal = (data) => {
    setSubCategoryModal(false);
    setSubCategoryId(data.id);
    setSubCategory(data.name);
  };

  const formik1 = useFormik({
    initialValues: initialValues,
    validationSchema: modelSchema1,
    enableReinitialize: true,
    onSubmit: async (values) => {
      if (plan?.name == "Basic plan" && plan?.is_basic) {
        try {
          await editBussiness({
            id,
            name: values.name,
            type: values.type,
            category: categoryId,
            district: district ? district : businessDetails.data.district,
            Post_office: postOffice,
            pin_code: pincode,
            location_info
          });
        } catch (error) {}
      } else {
        setName(values.name)
        setType(values.type);
        setPhonenumber(values.phone);
        setShow("socialMedia");
      }
    },
  });

  const handleLocation = (place) => {
    let lat = place.geometry.location.lat();
    let long = place.geometry.location.lng();
    setLatitude(lat);
    setLongitude(long);
    setLocationInfo(place.name)
  
  };

  const fetchCityName = async () => {
    try {
      const response = await axios.get(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${businessDetails?.data?.latitude},${businessDetails?.data?.longitude}&key=${Api_Key}`
      );

      const results = response.data.results;
      if (results && results.length > 0) {
        const addressComponents = results[0].address_components;
        for (const component of addressComponents) {
          if (component.types.includes("locality")) {
            setPlaceName(component.long_name);
            setLocationInfo(component.long_name)
            break;
          }
        }
      }
    } catch (error) {}
  };
  
  const handleClick = async () => {
    try {
      let payload = {
        id,
        name: name,
        type: type,
        category: categoryId,
        district: district ? district : businessDetails.data.district,
        Post_office: postOffice,
        pin_code: pincode,
        sub_category: subCategoryId,
        location_info
      };
      if (businessDetails.data.about_us) {
        payload.about_us = businessDetails.data.about_us;
      }
      if (businessDetails.data.longitude) {
        payload.longitude = longitude
          ? longitude
          : businessDetails.data.longitude;
      }
      if (businessDetails.data.latitude) {
        payload.latitude = latitude ? latitude : businessDetails.data.latitude;
      }
      if (googleLocation) {
        payload.google_location_link = googleLocation;
      }
      if (Whatsapp) {
        payload.whatsapp_number = Whatsapp;
      }
      if (facebook) {
        payload.facebook = facebook;
      }
      if (instagram) {
        payload.instagram = instagram;
      }
      if (youtube) {
        payload.youtube = youtube;
      }

      if (website) {
        payload.website = website;
      }
      if (businessDetails.data.banner_image) {
        payload.banner_image = businessDetails.data.banner_image;
      }
      if (businessDetails.data.image) {
        payload.image = businessDetails.data.image;
      }

      await editBussiness(payload);
    } catch (error) {}
  };

  useEffect(() => {
    if (isSuccess) {
      setOpenSucces(true);
    }
  }, [isSuccess]);
  useEffect(() => {
    if (isError) {
      setErrorModal(true);
    }
  }, [isError]);

  const handleCloseErrorModal = () => {
    setErrorModal(false);
    setShow("business");
  };

  const handleSuccessModal = () => {
    history.push("/my-list");
    setOpenSucces(false);
  };

  return (
    <div>
      {show == "business" ? (
        <>
          <div
            style={{
              backgroundColor: "#fff",
              boxShadow: "-2px 0px 5px rgba(22, 107, 142, 0.25)",
              color: "black",
              display: "flex",
              width: "100%",
              alignItems: "center",
              gap: "1rem",
              flexDirection: "row",
              padding: "1rem",
              position: "fixed",
              top: 0,
            }}
          >
            <CloseIcon
              onClick={() => history.push("/my-list")}
              style={{
                color: "black",
              }}
            />
            <p style={{ color: "black" }}>Edit Business</p>
          </div>
          <form onSubmit={formik1.handleSubmit}>
            <div
              className="completeRegistration-form-body"
              style={{ marginTop: "4rem" }}
            >
              <p style={{ fontWeight: "bold" }}>
                Business Name<span style={{ color: "red" }}>*</span>
              </p>
              <input
                placeholder="Business Name"
                className="reg-inp"
                {...formik1.getFieldProps("name")}
              />

              {formik1.touched.name && formik1.errors.name && (
                <div className="error-message">{formik1.errors.name}</div>
              )}

              <p style={{ fontWeight: "bold" }}>
                Type <span style={{ color: "red" }}>*</span>
              </p>
              <select
                className="inp-dropdown-business"
                {...formik1.getFieldProps("type")}
              >
                <option value="">Select Type</option>
                <option value={"shop"}>Shop</option>
                <option value={"service"}>Service</option>
              </select>
              {formik1.errors.type && formik1.touched.type && (
                <div className="error-message">{formik1.errors.type}</div>
              )}

              <p style={{ fontWeight: "bold" }}>
                Category<span style={{ color: "red" }}>*</span>{" "}
              </p>
              <input
                placeholder="Select Category"
                className="reg-inp"
                defaultValue={category}
                onClick={() => setCategoryModal(true)}
              />

              {categoryError && categoryError.length > 0 && (
                <div className="error-message">{categoryError}</div>
              )}

              {plan?.name !== "Basic plan" ? (
                <>
                  <p style={{ fontWeight: "bold" }}>Subcategory </p>
                  <input
                    placeholder="Select sub category"
                    className="reg-inp"
                    defaultValue={subCategory}
                    onClick={() => setSubCategoryModal(true)}
                  />
                </>
              ) : null}

              {plan?.name !== "Basic plan" ? (
                <>
                  <p style={{ fontWeight: "bold" }}>
                    Phonenumber<span style={{ color: "red" }}>*</span>{" "}
                  </p>
                  <input
                    placeholder="Enter Post Office"
                    className="reg-inp"
                    {...formik1.getFieldProps("phone")}
                  />
                  {formik1.errors.phone && formik1.touched.phone && (
                    <div className="error-message">{formik1.errors.phone}</div>
                  )}
                </>
              ) : null}
              {plan?.name !== "Basic plan" ? (
                <>
                  <p style={{ fontWeight: "bold" }}>
                    Location<span style={{ color: "red" }}>*</span>{" "}
                  </p>
                  <ReactGoogleAutocomplete
                    apiKey={Api_Key}
                    options={{
                      types: [
                        "neighborhood",
                        "locality",
                        "sublocality",
                        "postal_code",
                        "point_of_interest",
                        // "ALL",
                      ],
                      componentRestrictions: { country: "in" },
                      fields: [
                        "address_components",
                        "geometry",
                        "icon",
                        "name",
                        "place_id",
                        "formatted_address",
                        // "ALL",
                      ],
                    }}
                    name="location"
                    defaultValue={placeName}
                    onPlaceSelected={(place) => handleLocation(place)}
                    style={{
                      outline: "unset",
                      border: "unset",
                      width: "100%",
                      height: "3rem",
                      borderRadius: "50px",
                      padding: "1rem",
                      boxShadow: "-2px 0px 5px rgba(22, 107, 142, 0.25)",
                      marginBottom: "10px",
                    }}
                  />
                </>
              ) : null}

              <p style={{ fontWeight: "bold" }}>District </p>
              <select
                className="inp-dropdown-business"
                value={district}
                onChange={(e) => setDistrict(e.target.value)}
              >
                <option value="">Select District</option>
                {districtList &&
                  districtList.data.results.map((item) => (
                    <option value={item.id} key={item.id}>
                      {item.name}
                    </option>
                  ))}
              </select>

              <p style={{ fontWeight: "bold" }}>Post Office </p>
              <input
                placeholder="Enter Post Office"
                className="reg-inp"
                value={postOffice}
                onChange={(e) => setPostOffice(e.target.value)}
              />

              <p style={{ fontWeight: "bold" }}>Pin Code </p>
              <input
                placeholder="Enter Pincode"
                className="reg-inp"
                value={pincode}
                onChange={(e) => setPincode(e.target.value)}
              />
              {plan?.name !== "Basic plan" ? (
                <>
                  <p style={{ fontWeight: "bold" }}>About your Business</p>
                  <textarea
                    placeholder="About"
                    className="reg-textarea"
                    value={about}
                    onChange={(e) => setAbout(e.target.value)}
                    rows="3"
                  />
                </>
              ) : null}
            </div>

            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
                margin: "2rem",
              }}
            >
              <button className="next-btn" type="submit">
                <BsArrowRight />
              </button>
            </div>
          </form>
        </>
      ) : null}

      {show == "socialMedia" && plan?.name !== "Basic plan" && !plan.is_basic ? (
        <Fragment>
          <div
            style={{
              backgroundColor: "#fff",
              boxShadow: "-2px 0px 5px rgba(22, 107, 142, 0.25)",
              color: "black",
              display: "flex",
              width: "100%",
              alignItems: "center",
              gap: "1rem",
              flexDirection: "row",
              padding: "1rem",
              position: "fixed",
              top: 0,
            }}
          >
            <ArrowBackIcon
              onClick={() => setShow("business")}
              style={{
                color: "black",
              }}
            />
            <p style={{ color: "black" }}>Edit Social Media </p>
          </div>

          <div
            className="completeRegistration-form-body"
            style={{ marginTop: "4rem" }}
          >
            <p style={{ fontWeight: "bold" }}>Google Location Link</p>
            <input
              placeholder="Enter"
              className="reg-inp"
              value={googleLocation}
              onChange={(e) => setGoogleLocation(e.target.value)}
            />

            <p style={{ fontWeight: "bold" }}>Whatsapp Phone number </p>
            <input
              placeholder="Enter"
              className="reg-inp"
              value={Whatsapp}
              onChange={(e) => setWhatsApp(e.target.value)}
            />

            <p style={{ fontWeight: "bold" }}>Facebook </p>
            <input
              placeholder="Enter the profile link "
              className="reg-inp"
              value={facebook}
              onChange={(e) => setFacebook(e.target.value)}
            />

            <p style={{ fontWeight: "bold" }}>Instagram </p>
            <input
              placeholder="Enter the profile link "
              className="reg-inp"
              value={instagram}
              onChange={(e) => setInstagram(e.target.value)}
            />

            <p style={{ fontWeight: "bold" }}>Youtube </p>
            <input
              placeholder="Enter the profile link"
              className="reg-inp"
              value={youtube}
              onChange={(e) => setYoutube(e.target.value)}
            />

            <p style={{ fontWeight: "bold" }}>Website </p>
            <input
              placeholder="Enter the Website link"
              className="reg-inp"
              value={website}
              onChange={(e) => setWebsite(e.target.value)}
            />
          </div>

          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              margin: "2rem",
            }}
          >
            <div className="next-btn" onClick={handleClick}>
              <BsArrowRight />
            </div>
          </div>
        </Fragment>
      ) : null}
      {openSucces ? (
        <SuccessModal
          open={openSucces}
          handleClose={handleSuccessModal}
          title="Ok"
          message="Business edited successfully"
        />
      ) : null}
      {errorModal ? (
        <ErrorModal open={errorModal} handleClose={handleCloseErrorModal} />
      ) : null}
      {categoryModal ? (
        <CategoryModal
          open={categoryModal}
          setOpen={setCategoryModal}
          handleClose={handleCategoryModal}
          categoryId={categoryId}
        />
      ) : null}
      {subCategoryModal ? (
        <SubCategoryModal
          open={subCategoryModal}
          setOpen={setSubCategoryModal}
          handleClose={handleSubCategoryModal}
          categoryId={categoryId}
          subCategoryId={subCategoryId}
        />
      ) : null}
    </div>
  );
}

export default EditDetails;
