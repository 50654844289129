import React, { useState, useEffect, useContext } from "react";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useHistory } from "react-router-dom";
import {
  Button,
  FormControl,
  Grid,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import theme from "../../utils/theme";
import useStyles from "../../SharedClass/sharedClass";
import useProfileQuery from "../../store/Profile/useProfileQuery";
import useCommonQuery from "../../store/common/useCommonQuery";
import axios from "axios";
import Flag from "../../assets/Flag.svg";
import { BsFillPenFill } from "react-icons/bs";
import useMobileNumberQuery from "../../store/useMobileNumberQuery";
import OtpModal from "./OtpModal";
import SuccessModal from "../myListing/Listing/Add business/SuccessModal";
import ReactGoogleAutocomplete from "react-google-autocomplete";
import { variableContext } from "../../context/VariableContex";
import UploadModal from "../../components/UploadModal";
import S3 from "react-aws-s3";
import { Buffer } from "buffer";
window.Buffer = Buffer;


const Profile = () => {
  const { setLatitude, setLongitude, setLocationName, locationName } =
    useContext(variableContext);
  const history = useHistory();
  const classes = useStyles();

  const Api_Key = "AIzaSyDMC5uUfcSScgVY9AGqsGQwYm8P6_LmyYg";

  const [state, setState] = useState("");
  const [disabled, setDisabled] = useState(true);
  const [name, setName] = useState("");
  const [mobile, setMobile] = useState("");
  const [district, setDistrict] = useState("");
  const [nameError, setNameError] = useState("");
  const [file, setFile] = useState("");
  const [value, setValue] = useState("");
  const [numberError, setNumberError] = useState("");
  const [open, setOpen] = useState(false);
  const [openSucces, setOpenSucces] = useState(false);
  const [currentLocation, setCurrentLocation] = useState("");
  const [uploadModal, setUploadModal] = useState(false);


  const envConfig = process.env;

  const config = {
    bucketName: envConfig.REACT_APP_S3_BUCKET,
    region: envConfig.REACT_APP_S3_REGION,
    dirName: "master-click",
    accessKeyId: envConfig.REACT_APP_S3_ACCESS_KEY_ID,
    secretAccessKey: envConfig.REACT_APP_S3_SECRET_ACCESS_KEY,
  };
  const ReactS3Client = new S3(config);


  const { data: getData, isSuccess: getSuccess } =
    useProfileQuery.Get_profile();

  const { mutateAsync: updateProfile, isSuccess } =
    useProfileQuery.Update_profile();

  const { mutateAsync: updateNumber, isSuccess: numberSuccess } =
    useMobileNumberQuery.Update_number();

  useEffect(() => {
    if (getSuccess && getData) {
      setName(getData.data.name);
      setMobile(getData.data.mobile);
      setFile(getData.data.image);
      setCurrentLocation(getData.data.location);
    }
  }, [getSuccess, getData]);
  const handleClose = () => {
    setOpen(false);
  };

  const handleLocation = (place) => {
    let lat = place.geometry.location.lat();
    let long = place.geometry.location.lng();
    setLatitude(lat);
    setLongitude(long);
    setLocationName(place.name);
  };

  const handleImage = async (e) => {
    if (e.target.files.length == 0) {
      return;
    } else {
      try {
        let url = null;

        setUploadModal(true);
        url = e.target.files[0];
        if (url !== null) {
          const data = await ReactS3Client.uploadFile(url);
          url = data.location;
          setFile(url);
          handleCloseUpload();
        }
      } catch (error) {
        handleCloseUpload();
      }
    }
  };
  const handleCloseUpload = () => {
    setUploadModal(false);
  };
  const handleNumber = async () => {
    if (mobile.length < 10 || mobile.length > 10) {
      setNumberError("Enter valid phone number");
    } else {
      try {
        await updateNumber({
          temp_mobile: mobile,
        });
      } catch (error) {}
    }
  };

  const handleSuccessModal = () => {
    setOpenSucces(false);
  };

  const handleProfile = async () => {
    if (!name) {
      setNameError("Name is required");
    } else {
      try {
        await updateProfile({
          name,

          image: file ? file : getData.data.image,
          location: locationName,
        });
      } catch (error) {}
    }
  };

  useEffect(() => {
    if (numberSuccess) {
      setOpen(true);
    }
  }, [numberSuccess]);

  useEffect(() => {
    if (isSuccess) {
      setDisabled(true);
    }
  }, [isSuccess]);

  useEffect(() => {
    if (isSuccess) {
      setOpenSucces(true);
    }
  }, [isSuccess]);

  return (
    <div>
      <div
        style={{
          backgroundColor: "#fff",
          boxShadow: "-2px 0px 5px rgba(22, 107, 142, 0.25)",
          color: "black",
          display: "flex",
          width: "100%",
          alignItems: "center",
          gap: "1rem",
          flexDirection: "row",
          padding: "1rem",
        }}
      >
        <ArrowBackIcon
          onClick={() => history.push("/my-profile")}
          style={{
            color: "black",
          }}
        />
        <p style={{ color: "black" }}>My Profile</p>
      </div>

      <div className="pro-img">
        <div
          style={{
            borderRadius: "50%",
            width: "96px",
            height: "96px",
            overflow: "hidden",
          }}
        >
          <img
            src={file ? file : "/images/user-img.png"}
            alt="not Found"
            width="100%"
            height="100%"
          />
        </div>
        <p style={{ fontWeight: "bold" }}>{state}</p>
      </div>

      <div className="completeRegistration-form-body">
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <p style={{ fontWeight: "bold", margin: 0 }}>Name</p>
          </Grid>
          <Grid item xs={12}>
            <input
              placeholder="Name"
              className="reg-inp"
              value={name}
              onChange={(e) =>{ setName(e.target.value)
              setNameError("")
              }}
              disabled={disabled}
            />
            {nameError && nameError.length > 0 && (
              <div>
                <p style={{ color: "red", paddingLeft: "10px" }}>{nameError}</p>
              </div>
            )}
          </Grid>
          <Grid item xs={12}>
            <p style={{ fontWeight: "bold", margin: 0 }}>Phone Number</p>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={1} alignItems={"center"}>
              <Grid xs={10}>
                <div className="dropdown-mob-num">
                  <FormControl sx={{ width: "80px" }}>
                    <Select
                      value={value}
                      displayEmpty
                      sx={{
                        height: "50px",
                        borderTopLeftRadius: "70px",
                        borderBottomLeftRadius: "70px",
                        "& .MuiSelect-select": {
                          marginTop: "4px",
                        },
                        "& .MuiSelect-icon.Mui-disabled": {
                          color: "black !important",
                        },
                      }}
                      onChange={(e) => setValue(e.target.value)}
                      disabled={true}
                    >
                      <MenuItem value="" sx={{ marginTop: "4px" }}>
                        <img src={Flag} />
                      </MenuItem>
                    </Select>
                  </FormControl>
                  <input
                    className="inp-number"
                    type="text"
                    value={mobile}
                    onChange={(e) => {
                      setNumberError("");
                      setMobile(e.target.value);
                    }}
                  />{" "}
                </div>
                {numberError && numberError.length > 0 && (
                  <div>
                    <p style={{ color: "red", paddingLeft: "15px" }}>
                      {numberError}
                    </p>
                  </div>
                )}
              </Grid>
              <Grid
                item
                xs={2}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Button
                  className="edit-service-btn"
                  sx={{
                    width: "30px !important",
                    height: "30px !important",
                    marginBottom: "10px",
                  }}
                  onClick={handleNumber}
                >
                  <BsFillPenFill />
                </Button>
              </Grid>
            </Grid>
          </Grid>

          <Grid container columnSpacing={2}>
            <Grid item xs={12}>
              <Typography className="title"> Location</Typography>
            </Grid>

            <Grid item xs={12}>
              {disabled ? (
                <input
                  className="reg-inp"
                  value={currentLocation ? currentLocation : locationName}
                  onChange={(e) => setName(e.target.value)}
                  disabled={disabled}
                />
              ) : (
                <ReactGoogleAutocomplete
                  apiKey={Api_Key}
                  options={{
                    types: [
                      "neighborhood",
                      "locality",
                      "sublocality",
                      "postal_code",
                      "point_of_interest",
                    ],
                    componentRestrictions: { country: "in" },
                    fields: [
                      "address_components",
                      "geometry",
                      "icon",
                      "name",
                      "place_id",
                      "formatted_address",
                    ],
                  }}
                  defaultValue={locationName ? locationName : currentLocation}
                  name="location"
                  onPlaceSelected={(place) => handleLocation(place)}
                  style={{
                    width: "100%",
                    height: "48px",
                    border: "1px solid white",
                    borderRadius: "50px",
                    paddingLeft: "15px",
                    boxShadow: "-2px 0px 5px rgba(22, 107, 142, 0.25)",
                  }}
                />
              )}
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <p style={{ fontWeight: "bold", margin: 0 }}>Profile Image</p>
          </Grid>
          <Grid item xs={12}>
            <input
              type="file"
              className="reg-inp"
              onChange={(e) => handleImage(e)}
              disabled={disabled}
            />
          </Grid>
        </Grid>
      </div>
      <div
        style={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          margin: "20px 0px",
        }}
      >
        {disabled ? (
          <Button
            style={{
              width: "224px",
              height: "48px",
              background: "#0E3A53",
              borderRadius: "12px",
              color: "white",
            }}
            onClick={() => setDisabled(false)}
          >
            Edit
          </Button>
        ) : (
          <>
            <Button
              style={{
                backgroundColor: "#fff",
                color: "black",
                margin: "1rem",
              }}
              className="completeRegistration-btn2"
              variant="contained"
              onClick={() => setDisabled(true)}
            >
              Cancel
            </Button>
            <Button
              style={{
                backgroundColor: theme.palette.secondary.main,
                margin: "1rem",
              }}
              className="completeRegistration-btn2"
              variant="contained"
              onClick={handleProfile}
            >
              Update
            </Button>
          </>
        )}
      </div>
      {open ? (
        <OtpModal open={open} handleClose={handleClose} mobile={mobile} />
      ) : null}
      {openSucces ? (
        <SuccessModal
          open={openSucces}
          handleClose={handleSuccessModal}
          title="Ok"
          message="Profile edited successfully"
        />
      ) : null}
      {uploadModal ? (
        <UploadModal open={uploadModal} handleClose={handleCloseUpload} />
      ) : null}
    </div>
  );
};

export default Profile;
