import { useMutation, useQuery, useQueryClient } from "react-query";
import { changeNumber, verifyNumber } from "./mobileNumberUrls";

const Update_number = () => {
  const queryClient = useQueryClient();

  return useMutation((data) => changeNumber(data), {
    onMutate: async (newData) => {
      queryClient.cancelQueries("phoneNumber");
      const prev = await queryClient.getQueryData("phoneNumber");
      return {
        prev,
        newData,
      };
    },
    onSettled: () => {
      queryClient.invalidateQueries("phoneNumber");
    },
    onError: (err, _, context) => {
      queryClient.setQueryData("phoneNumber", context.prev);
    },
  });
};

const Verify_number = () => {
  const queryClient = useQueryClient();

  return useMutation((data) => verifyNumber(data), {
    onMutate: async (newData) => {
      queryClient.cancelQueries("phoneNumber");
      const prev = await queryClient.getQueryData("phoneNumber");
      return {
        prev,
        newData,
      };
    },
    onSettled: () => {
      queryClient.invalidateQueries("phoneNumber");
    },
    onError: (err, _, context) => {
      queryClient.setQueryData("phoneNumber", context.prev);
    },
  });
};

const useMobileNumberQuery = {
  Update_number,
  Verify_number,
};

export default useMobileNumberQuery;
