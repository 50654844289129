import React, { useState } from "react";
import "./BottomNav.css";
import { AiFillHome } from "react-icons/ai";
import { ImPriceTags } from "react-icons/im";
import { HiBriefcase } from "react-icons/hi";
import { FaUserTie } from "react-icons/fa";
import { useHistory, useLocation } from "react-router-dom";
import LoginReward from "../reward/LoginReward";
import AddIcon from "@mui/icons-material/Add";
import { Box, Typography } from "@mui/material";

const BottomNav = () => {
  const history = useHistory();
  const liveLoc = useLocation();

  const local = JSON.parse(localStorage.getItem("user"));
  const [openTerms, setOpenTerms] = useState(false);

  const data = [
    {
      icon: <AiFillHome size={30} />,
      path: "/",
    },
    {
      icon: <ImPriceTags size={30} />,
      path: "/category-list-view",
    },
    {
      icon: (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <img
            style={{ width: "2.8rem" }}
            src="/navIcon/plus.svg"
            alt="Add Business"
          />
          <p style={{ margin: 0, padding: 0 }}>Add Business</p>
        </div>
      ),
      path: local?.id ? "/add-business" : "/sign-up",
    },
    {
      icon: <HiBriefcase size={30} />,
      path: "/my-list",
    },
    {
      icon: <FaUserTie size={30} />,
      path: "/my-profile",
    },
  ];

  const [toggleAdd, setToggleAdd] = useState(false);

  const handleClose = () => {
    setToggleAdd(false);
  };

  const handleClick = (value) => {
    history.push(value);
  };

  return (
    <div className="bottom-nav">
      <Box
        onClick={() => handleClick(data[0].path)}
        style={{
          color: `${data[0].path === liveLoc.pathname ? "#2182B1" : "initial"}`,
        }}
      >
        {data[0].icon}
      </Box>
      <Box
        onClick={() => handleClick(data[1].path)}
        style={{
          color: `${data[1].path === liveLoc.pathname ? "#2182B1" : "initial"}`,
        }}
      >
        {data[1].icon}
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          position: "relative",
          width: "80px",
        }}
        onClick={() => handleClick(data[2].path)}
      >
        <Box className="bottom_main_box"></Box>
        <Box className="bottom_semi"></Box>
        <Box className="bottom_icon_box">
          <AddIcon sx={{ fontSize: "42px", color: "white" }} />
        </Box>
      </Box>
      <Box
        onClick={() => handleClick(data[3].path)}
        style={{
          color: `${data[3].path === liveLoc.pathname ? "#2182B1" : "initial"}`,
        }}
      >
        {data[3].icon}
      </Box>
      <Box
        onClick={() => handleClick(data[4].path)}
        style={{
          color: `${data[4].path === liveLoc.pathname ? "#2182B1" : "initial"}`,
        }}
      >
        {data[4].icon}
      </Box>

      {toggleAdd ? (
        <LoginReward
          toggle={toggleAdd}
          setToggle={setToggleAdd}
          handleClose={handleClose}
        />
      ) : null}
    </div>
  );
};

export default BottomNav;
