import React, { memo } from "react";
import "./NewsChannel.css";
import { useHistory, Link } from "react-router-dom";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Card, CardContent, Grid } from "@mui/material";
import useCommonQuery from "../../../store/common/useCommonQuery";

const NewsChannel = () => {
  const history = useHistory();

  const { data: newsChannelList } = useCommonQuery.Get_channel({
    limit: 500,
    offset: 0,
  });
  return (
    <div>
      <div
        style={{
          backgroundColor: "#fff",
          boxShadow: "-2px 0px 5px rgba(22, 107, 142, 0.25)",
          color: "black",
          display: "flex",
          width: "100%",
          alignItems: "center",
          gap: "1rem",
          flexDirection: "row",
          padding: "1rem",
        }}
      >
        <ArrowBackIcon
          onClick={() => history.push("/")}
          style={{
            color: "black",
          }}
        />
        <p style={{ color: "black" }}>NewsChannel</p>
      </div>

      <div className="news-head">മലയാളം വാർത്താ ചാനലുകൾ</div>
      <Grid container spacing={2} sx={{ padding: "0px 12px" }}>
        {newsChannelList &&
          newsChannelList.data.results.map((item, idx) => {
            return (
              <Grid item xs={4} key={idx}>
                <a href={item?.url ? item?.url : ""}>
                  <Card
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      borderRadius: "10px",
                      height: "100px",
                      width: "100px",
                    }}
                  >
                    <CardContent
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <img
                        style={{ width: "5rem", height: "5rem" }}
                        src={item.image}
                        alt={item.name}
                      />
                    </CardContent>
                  </Card>
                </a>
              </Grid>
            );
          })}
      </Grid>
    </div>
  );
};

export default memo(NewsChannel);
