import React, { useContext, useEffect, useRef, useState } from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useHistory, useParams } from "react-router-dom";
import {
  Box,
  Button,
  FormControl,
  Grid,
  InputAdornment,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import useBusinessQuery from "../../store/Bussiness/useBusinessQuery";
import useStyles from "../../SharedClass/sharedClass";
import { variableContext } from "../../context/VariableContex";
import ProductCard from "../../components/ProductCard";
import BookmarkNotFound from "../myListing/bookmark/bookmarkNotFound/BookmarkNotFound";
import { MdDashboard } from "react-icons/md";
import { GiHamburgerMenu } from "react-icons/gi";
import CategoreyCard from "../../components/CategoreyCard";
import useCommonQuery from "../../store/common/useCommonQuery";
import noPic from "../../assets/no-pic.png";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import category2 from "../../assets/category2.svg";

function PublicServiceCenter() {
  const { latitude, longitude, radius, locationName } =
    useContext(variableContext);

  const history = useHistory();
  const { id } = useParams();
  const inputRef = useRef();

  const classes = useStyles();

  const [value, setValue] = useState("");
  const [search, setSearch] = useState("");
  const [toggle, setToggle] = useState(false);
  const [items, setItems] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [height, setHeight] = useState("");

  const { data: categoryList, isSuccess } = useCommonQuery.Get_subcategory({
    id,
    search,
    limit: 2000,
    offset: 0,
  });

  const handleChange = (e) => {
    setValue(e.target.value);
    if (e.target.value == "location") {
      history.push("/location-search");
    }
  };

  useEffect(() => {}, [search]);

  const handleGridView = () => {
    setToggle(false);
  };
  const handleListView = () => {
    setToggle(true);
  };

  const handleClick = (name) => {
    history.push(`/category/${id}/subCategory/${name}`);
  };

  return (
    <div
      id="parent-div"
      ref={inputRef}
      style={{ height: "100vh", overflowY: "scroll" }}
    >
      <div
        style={{
          backgroundColor: "#fff",
          boxShadow: "-2px 0px 5px rgba(22, 107, 142, 0.25)",
          color: "black",
          padding: "10px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            width: "100%",
            alignItems: "center",
            gap: "1rem",
            marginBottom: "15px",
          }}
        >
          <ArrowBackIcon
            onClick={() => history.push("/")}
            style={{
              color: "black",
            }}
          />
          <Typography style={{ fontSize: "16px", fontWeight: "600" }}>
            Public Service Center
          </Typography>
        </Box>

        <div className="dropdown-search-body">
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Box className={classes.categoryLocSearch}>
                <img src="/images/loc.png" />
                <FormControl sx={{ flex: 1 }}>
                  <Select
                    value={value}
                    onChange={handleChange}
                    displayEmpty
                    inputProps={{ "aria-label": "Without label" }}
                    sx={{
                      "& .MuiOutlinedInput-notchedOutline": {
                        border: "none !important",
                      },
                      "& .MuiSelect-select": {
                        color: "rgba(33, 130, 177, 1) !important",
                        fontSize: "16px !important",
                        fontWeight: "600 !important",
                      },
                    }}
                  >
                    <MenuItem value="">{locationName}</MenuItem>
                    <MenuItem value="location">select location</MenuItem>
                  </Select>
                </FormControl>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box className={classes.categoryLocSearch}>
                <img src={category2} />

                <Button
                  sx={{
                    flex: 1,
                    textTransform: "none",
                    paddingLeft: "8px",
                    display: "flex",
                    justifyContent: "space-between",
                    fontSize: "16px",
                    fontWeight: "600",
                  }}
                  endIcon={<ArrowDropDownIcon />}
                  onClick={() => history.push("/subcategory-search")}
                >
                  Category
                </Button>
              </Box>
            </Grid>
          </Grid>
        </div>
      </div>

      <div>
        <div style={{ display: `${toggle ? "none" : "block"}` }}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              paddingLeft: "1rem",
              paddingRight: "1rem",
              marginTop: "10px",
            }}
          >
            <h4>All Categories</h4>
            <MdDashboard
              onClick={handleListView}
              style={{ fontSize: "33px" }}
            />
          </div>
          <Grid container spacing={2} style={{ padding: "1rem" }}>
            {categoryList &&
              categoryList?.data?.results?.map((items, idx) => {
                return (
                  <CategoreyCard
                    id={items.id}
                    title={items.name}
                    handleClick={handleClick}
                    image={items.image}
                    key={idx}
                  />
                );
              })}
          </Grid>
        </div>

        <div
          style={{ display: `${toggle ? "block" : "none"}`, marginTop: "10px" }}
        >
          <div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                paddingLeft: "1rem",
                paddingRight: "1rem",
              }}
            >
              <h4 style={{ margin: 0 }}>All Categories</h4>
              <GiHamburgerMenu
                onClick={handleGridView}
                style={{ fontSize: "33px" }}
              />
            </div>
          </div>
          <div style={{ position: "relative" }}>
            <input
              placeholder="Search in Category"
              className="cat-inp"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
            <img
              style={{ position: "absolute", right: "2.4rem", top: "1.5rem" }}
              src="/images/insideSearch.png"
            />
          </div>
          <div className="list-Card-body">
            {categoryList &&
              categoryList?.data?.results?.map((item, i) => {
                return (
                  <div
                    className="list-Card"
                    onClick={() =>
                      history.push(`/category/${id}/subCategory/${item.id}`)
                    }
                    key={i}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-start",
                      }}
                    >
                      <img
                        style={{ width: "25px" }}
                        src={item.image ? item.image : noPic}
                        alt="not found"
                      />
                    </div>
                    <Typography
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        flex: 1,
                        fontSize: "12px",
                        fontWeight: "500",
                      }}
                    >
                      {item.name}
                    </Typography>
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    </div>
  );
}

export default React.memo(PublicServiceCenter);
