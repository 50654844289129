import React from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useHistory } from "react-router-dom";
import { Button } from "@mui/material";
import theme from "../../../utils/theme";

const PaymentConfirmation = () => {
    const history = useHistory();
  return (
    <div>
      <div
        style={{
          backgroundColor: "#fff",
          boxShadow: "-2px 0px 5px rgba(22, 107, 142, 0.25)",
          color: "black",
          display: "flex",
          width: "100%",
          alignItems: "center",
          gap: "1rem",
          flexDirection: "row",
          paddingLeft: "1rem",
          position: "fixed",
          top: 0,
        }}
        // className="pro-head"
      >
        <ArrowBackIcon
          onClick={() => history.push("/my-list")}
          style={{
            color: "black",
          }}
        />
        <p style={{ color: "black" }}>Payment Confirmation</p>
      </div>

      <div style={{marginTop:"12rem",display:"flex",alignItems:"center",justifyContent:"center",flexDirection:"column"}} >
        <img src="/images/success.png" alt="not found" />

        <div style={{display:"flex",alignContent:"center",justifyContent:"center",gap:"1rem",marginTop:"2rem",width:"100%"}} >

            <p style={{margin:0,fontWeight:"bold"}} >Payment Confirmed</p>



        </div>
          <p style={{fontSize:".9rem"}} >You have purchased premium subscription for One Year</p>
          <Button onClick={()=>history.push("/home")} variant="contained" style={{width:"11rem",height:"2.8rem",borderRadius:"12px",marginTop:"1rem"}} >Go to Home</Button>
      </div>

      <div style={{position:"fixed",bottom:0,display:"flex",alignItems:"center",justifyContent:"center",width:"100%",height:"8rem",backgroundColor:"#2182B1"}} >
        <div style={{width:"100%",height:"8rem",display:"flex",justifyContent:"center",alignItems:"center",color:"#fff",flexDirection:"column"}} >
          <p style={{margin:0}} >If  you need help for registration, <br/> Please Contact: 8943 999 997 </p>
          <hr style={{width:"90%"}} />
          <div style={{display:"flex",gap:"1rem"}} >
            <Button variant="contained" style={{backgroundColor:theme.palette.secondary.main }} >Whatsapp</Button>
            <Button variant="contained" style={{backgroundColor:theme.palette.secondary.main }} >Call Us</Button>
          </div>
        </div>
      </div>

    </div>
  );
};

export default PaymentConfirmation;
