import axios from "axios";
import { useMutation } from "react-query";

const API_URL = "https://backend.masterclick.in/api/v1";

const SendOtp = () => {
  return useMutation(
    async (payload) => {
      const { phoneNumber } = payload;
      const { data } = await axios.post(API_URL + "/auth/login/", {
        mobile: phoneNumber,
        device: "pwa",
      });
      return data;
    },
    {
      onSuccess: (response) => {},
    },
    {
      onError: (error) => {},
    }
  );
};

const VerifyOtp = () => {
  return useMutation(
    async (payload) => {
      const { mobile, otp } = payload;
      const { data } = await axios.post(API_URL + "/auth/mobile/otp/verify/", {
        mobile,
        otp,
      });
      return data;
    },
    {
      onSuccess: (response) => {
        localStorage.setItem("accessToken", response.data.token.access);
        localStorage.setItem("user", JSON.stringify(response.data.user));
        localStorage.setItem("refreshToken", response.data.token.refresh);
      },
    },
    {
      onError: (error) => {},
    }
  );
};

const Registration = () => {
  return useMutation(
    async (payload) => {
      const { data } = await axios.post(API_URL + "/auth/register/", {
        name: payload.name,
        mobile: payload.mobile,
        image: payload.image,
        location: payload.location,
      });
      return data;
    },
    {
      onSuccess: (response) => {},
    },
    {
      onError: (error) => {},
    }
  );
};

const useAuthQuery = {
  SendOtp,
  VerifyOtp,
  Registration,
};
export default useAuthQuery;
