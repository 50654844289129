import React from "react";

const BookmarkNotFound = ({title}) => {
  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
      }}
    >
      <img src="/images/file.png" alt="not found" />
      <p>{title}</p>
    </div>
  );
};

export default BookmarkNotFound;
