import React from "react";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  selectBox: {
    width: "100% !important",
    borderRadius: "70px !important",
    boxShadow: "0px 2.5px 5px rgba(22, 107, 142, 0.25)",
    "& .MuiFormControl-root": {
      width: "100% !important",
      borderRadius: "70px !important",
      boxShadow: "0px 2.5px 5px rgba(22, 107, 142, 0.25)",
    },
    "& .MuiInputBase-root": {
      borderRadius: "70px !important",
      boxShadow: "0px 2.5px 5px rgba(22, 107, 142, 0.25)",
    },
  },
  searchBar: {
    width: "100% !important",
    height: "38px",
    "& .MuiFormControl-root": {
      width: "100% !important",
      height: "38px",
    },
    "& .MuiInputBase-root": {
      borderRadius: "10px !important",
      height: "38px",
    },
    "& .MuiInputAdornment": {},
    "& .MuiSvgIcon-root": {
      color: "white",
      background: " #2182B1",
      borderRadius: "20px",
      width: " 24px",
      height: "24px",
      padding: "4px",
    },
  },
  categoryLocSearch: {
    display: "flex",
    paddingLeft: "14px",
    height: "35px",
    alignItems: "center",
    border: "1px solid rgba(33, 130, 177, 1)",
    borderRadius:"6px"
  },
  businessLocation: {
    outline: "unset",
    border: "unset",
    width: "100%",
    height: "3rem",
    borderRadius: "50px",
    padding: "1rem",
    boxShadow: "-2px 0px 5px rgba(22, 107, 142, 0.25)",
    marginBottom: "10px",
  },
  cropButton: {
    width: "100% !important",
    color: "white !important",
    borderRadius: "12px !important",
    height: "48px !important",
    textTransform: "none !important",
  },
  languageButton: {
    width: " 13rem",
    height: "44px !important",
    borderRadius: "18px !important",
    color: "black",
    fontSize: "12px !important",
    fontWeight: "700 !important",
  },
});

export default useStyles;
