import { createContext, useState } from "react";

export const variableContext = createContext();

const Variable = ({ children }) => {
  const [mobile, setMobile] = useState("");
  const [latitude, setLatitude] = useState("");
  const [longitude, setLongitude] = useState("");
  const [radius, setRadius] = useState(50);
  const [locationName, setLocationName] = useState("Location");

  return (
    <variableContext.Provider
      value={{
        mobile,
        setMobile,
        latitude,
        setLatitude,
        longitude,
        setLongitude,
        radius,
        setRadius,
        locationName,
        setLocationName,
      }}
    >
      {children}
    </variableContext.Provider>
  );
};

export default Variable;
