import React, { useEffect, useState } from "react";
import "./MyListingCard.css";
import { SlOptionsVertical } from "react-icons/sl";
import { Box, Button, Typography } from "@mui/material";
import DeleteAlert from "../../components/alert/DeleteAlert";
import { useHistory } from "react-router-dom";
import star from "../../assets/logo/star.png";
import useBusinessQuery from "../../store/Bussiness/useBusinessQuery";
import EditModal from "../../pages/myListing/Listing/Edit business/EditModal";
import BookmarkNotFound from "../../pages/myListing/bookmark/bookmarkNotFound/BookmarkNotFound";
import locations from "../../assets/locations.svg";
import category2 from "../../assets/category2.svg";
import LoginReward from "../reward/LoginReward";
import thumbnail from "../../assets/thumbnail.png";
import SuccessModal from "../../pages/myListing/Listing/Add business/SuccessModal";
const MyListingCard = () => {
  const history = useHistory();
  const local = JSON.parse(localStorage.getItem("user"));

  const [successModalOpen, setSuccessModalOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const [id, setId] = useState("");
  const [planId, setPlanId] = useState("");
  const [plan, setPlan] = useState({});
  const [basicPlan, setBasicPlan] = useState(false);
  const [planName, setPlanName] = useState("");
  const [toggleAdd, setToggleAdd] = useState(false);
  const [openSuccess, setOpenSuccess] = useState(false);

  const { data: bussinessList, isSuccess } =
    useBusinessQuery.Get_my_bussiness();

  const { data: currentPlan, isSuccess: planSuccess } =
    useBusinessQuery.Get_my_currentplan(planId);

  useEffect(() => {
    if (isSuccess) {
      setPlanId(bussinessList?.data?.results[0]?.id);
    }
  }, [isSuccess]);

  useEffect(() => {}, [planId]);

  useEffect(() => {
    if (planSuccess && currentPlan) {
      const data = currentPlan.data.selected_plan;
      setPlan({
        data: data?.name,
        date: data?.days + " " + data?.duration_unit,
      });
      setPlanName(data?.name);
      setBasicPlan(data?.is_basic);
    }
  }, [planSuccess, currentPlan]);

  const handleClose = (value) => {
    if (value == "true") {
      setOpenSuccess(true);
    }
    setOpen(false);
  };

  const handleClick = () => {
    if (local?.id) {
      history.push("/add-business");
    } else {
      setToggleAdd(true);
    }
  };

  const handleCloseLogin = () => {
    setToggleAdd(false);
  };

  const handleSuccess = () => {
    setOpenSuccess(false);
    history.push("/");
  };

  return (
    <>
      <div style={{ height: "100%" }}>
        <DeleteAlert
          Toggle={successModalOpen}
          setToggle={setSuccessModalOpen}
        />
        {bussinessList &&
          bussinessList.data.results.map((item, i) => (
            <div className="list-card" key={i}>
              <div style={{ padding: "1rem" }}>
                <div className="first-row">
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "flex-start",
                        width: "150px",
                        height: "110px",
                      }}
                    >
                      <img
                        src={item.image ? item.image : thumbnail}
                        width="100%"
                        height="100%"
                        style={{ borderRadius: "12px" }}
                        alt=""
                      />
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        flexDirection: "column",
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: "16px",
                          fontWeight: "500",
                          paddingLeft: "3px",
                        }}
                      >
                        {item.name}
                      </Typography>
                      {item?.district_name && (
                        <div
                          style={{
                            display: "flex",
                            alignItems: "start",
                            gap: "11px",
                          }}
                        >
                          <img
                            src={locations}
                            alt="Location"
                            width="11.74px"
                            height="17.64px"
                            style={{ marginTop: "3px" }}
                          />
                          <Typography
                            style={{ fontSize: "14.29px", fontWeight: "400" }}
                          >
                            {item.district_name && item.district_name}
                          </Typography>
                        </div>
                      )}

                      <div
                        style={{
                          display: "flex",
                          marginTop: "3px",
                          gap: "5.72px",
                        }}
                      >
                        <img
                          src={category2}
                          alt="Location"
                          width="16.05px"
                          height="16px"
                        />
                        <Typography
                          style={{ fontSize: "14.29px", fontWeight: "400" }}
                        >
                          {item.category_name}{" "}
                        </Typography>
                      </div>
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "end",
                      cursor: "pointer",
                      position: "absolute",
                      right: "21px",
                    }}
                  >
                    <Button
                      onClick={() => {
                        setOpen(true);
                        setId(item.id);
                      }}
                      style={{ minWidth: "0px", textTransform: "none" }}
                    >
                      Edit
                    </Button>
                  </div>
                </div>
                <hr />
                <div className="second-row">
                  {/* <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      textAlign: "center",
                    }}
                  >
                    <Typography className="calls-typo">
                      {item.views}
                    </Typography>
                    <Typography className="likes-typo">Views</Typography>
                  </div> */}
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      textAlign: "center",
                    }}
                  >
                    <Typography className="calls-typo">
                      {item.calls}
                    </Typography>
                    <Typography className="likes-typo">Calls</Typography>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      textAlign: "center",
                    }}
                  >
                    <Typography className="calls-typo">
                      {item.likes}
                    </Typography>
                    <Typography className="likes-typo">Likes</Typography>
                  </div>
                </div>
                <hr />

                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    paddingTop: "5px",
                  }}
                >
                  <Typography className="listing-typo">Current Plan</Typography>
                  <Typography className="listing-typo">Status</Typography>
                  <Typography className="listing-typo">Expiry Date</Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    marginTop: "10px",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      width: "100px",
                    }}
                  >
                    <img src={star} />
                    <Typography
                      className="listing-typo1"
                      sx={{ marginLeft: "6px" }}
                    >
                      {plan.data ? plan.data : ""}
                    </Typography>
                  </Box>
                  <Typography
                    className="listing-typo1"
                    sx={{ textAlign: "center" }}
                  >
                    {item?.status == "Pending"
                      ? "Waiting for admin's approval"
                      : item?.status}
                  </Typography>
                  <Typography
                    className="listing-typo1"
                    sx={{ width: "100px", textAlign: "center" }}
                  >
                    {item?.expire_within && item.expire_within + " days"}
                  </Typography>
                </Box>
              </div>
              <Button
                style={{
                  background: "#166B8E",
                  color: "white",
                  fontSize: "16px",
                  fontWeight: "600",
                  borderRadius: "0px 0px 16px 16px",
                  width: "100%",
                  textTransform: "none",
                }}
              >
                Upgrade Now
              </Button>
            </div>
          ))}
        {(bussinessList && bussinessList.data.results.length == 0) ||
        !bussinessList ? (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              height: "55vh",
            }}
          >
            <BookmarkNotFound title="No business added yet!" />
            <Button
              style={{
                width: "169px",
                height: "48px",
                borderRadius: "12px",
                background: "rgba(14, 58, 83, 1)",
                textTransform: "none",
                color: "white",
              }}
              onClick={handleClick}
            >
              Add Now
            </Button>
          </div>
        ) : null}
      </div>
      {open && (
        <EditModal
          open={open}
          handleClose={handleClose}
          id={id}
          basicPlan={basicPlan}
          planName={planName}
        />
      )}
      {toggleAdd ? (
        <LoginReward
          toggle={toggleAdd}
          setToggle={setToggleAdd}
          handleClose={handleCloseLogin}
        />
      ) : null}
      {openSuccess ? (
        <SuccessModal
          open={openSuccess}
          handleClose={handleSuccess}
          title="Go to home"
          message="Deleted Successfully"
        />
      ) : null}
    </>
  );
};

export default MyListingCard;
