import React, { Fragment, useEffect, useState } from "react";
import { BsArrowRight } from "react-icons/bs";
import { Box, Button, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useHistory, useParams } from "react-router-dom";
import { RiDeleteBin5Line } from "react-icons/ri";
import "../EditBusiness.css";
import useBusinessQuery from "../../../../../store/Bussiness/useBusinessQuery";
import useImageQuery from "../../../../../store/Images/useImageQuerys";
import ImageRemovalModal from "../../Add business/ImageRemovalModal";
import theme from "../../../../../utils/theme";
import ErrorModal from "../../Add business/ErrorModal";
import SuccessModal from "../../Add business/SuccessModal";
import axios from "axios";
import UploadModal from "../../../../../components/UploadModal";
import S3 from "react-aws-s3";
import { Buffer } from "buffer";
window.Buffer = Buffer;

const EditCoverPhoto = () => {
  const history = useHistory();
  const { id } = useParams();

  const [cover, setCover] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [imageId, setImageId] = useState("");
  const [plan, setPlan] = useState({});
  const [coverPhoto, setCoverPhoto] = useState(false);
  const [openSucces, setOpenSucces] = useState(false);
  const [openError, setOpenError] = useState(false);
  const [uploadModal, setUploadModal] = useState(false);
  const [message, setMessage] = useState("");

  let accessToken = localStorage.getItem("accessToken");
  const envConfig = process.env;

  const config = {
    bucketName: envConfig.REACT_APP_S3_BUCKET,
    region: envConfig.REACT_APP_S3_REGION,
    dirName: "master-click",
    accessKeyId: envConfig.REACT_APP_S3_ACCESS_KEY_ID,
    secretAccessKey: envConfig.REACT_APP_S3_SECRET_ACCESS_KEY,
  };
  const ReactS3Client = new S3(config);

  const { data: subscriptionDetail, isSuccess: businessSuccess } =
    useBusinessQuery.Get_my_currentplan(id);

  const { data: getCover, isSuccess: coverSuccess } =
    useImageQuery.Get_cover(id);

  const {
    mutateAsync: addCover,
    isSuccess: success,
    isError: addIsError,
    error: addError,
  } = useImageQuery.Add_cover();

  const { mutateAsync: deleteCover } = useImageQuery.Del_cover();

  const handleCoverImage = async (e) => {
    if (e.target.files.length == 0) {
      return;
    } else {
      let url = null;
      setUploadModal(true);
      url = e.target.files[0];
      const data = await ReactS3Client.uploadFile(url);
      url = data.location;
      try {
        await addCover({
          id,
          cover_image: url,
          is_active: true,
        });
      } catch (error) {
        handleCloseUpload();
      }
    }
    setCoverPhoto(false);
  };

  //   const formData = new FormData();
  //   formData.append("image", e.target.files[0]);

  //   const fileName = e.target.files[0]?.name;
  //   const fileExtension = fileName?.split(".")?.pop()?.toLowerCase();

  //   let contentType = "image/jpeg";
  //   if (fileExtension && fileExtension == "png") {
  //     contentType = "image/png";
  //   } else if (fileExtension && fileExtension == "svg") {
  //     return;
  //   } else if (
  //     (fileExtension && fileExtension == "jpg") ||
  //     (fileExtension && fileExtension == "jpeg")
  //   ) {
  //     contentType = "image/jpeg";
  //   }

  //   try {
  //     setUploadModal(true);

  //     const response = await axios.post(
  //       "https://dev2.enfono.com/api_master_mart/api/v1/common/image_coverter/",
  //       formData,
  //       {
  //         headers: {
  //           "Content-Type": contentType,
  //         },
  //       }
  //     );
  //     if (
  //       response &&
  //       response?.data &&
  //       response?.data?.data &&
  //       response?.data?.status == "ok"
  //     ) {
  //       try {
  //         await addCover({
  //           id,
  //           cover_image: response?.data?.data,
  //           is_active: true,
  //         });
  //       } catch (error) {}
  //     }
  //   } catch (error) {
  //     handleCloseUpload();
  //     setOpenError(true);
  //   }
  //   setCoverPhoto(false);
  // };

  const handleCloseUpload = () => {
    setUploadModal(false);
  };

  const handleClose = async (value) => {
    if (value) {
      try {
        await deleteCover({
          businessId: id,
          id: imageId,
        });
      } catch (error) {}
    }
    setOpenModal(false);
  };

  const handleNext = () => {
    setOpenSucces(true);
  };

  const handleErrorModal = () => {
    setMessage("");
    setOpenError(false);
  };

  const handleSuccessModal = () => {
    if (plan.catalogue_images) {
      history.push(`/edit-catelogueimages/${id}`);
    } else {
      history.push("/my-list");
    }
    setOpenSucces(false);
  };

  useEffect(() => {
    if (coverSuccess && getCover) {
      setCover([...getCover.data.results]);
      handleCloseUpload();
    }
  }, [coverSuccess, getCover]);

  useEffect(() => {
    if (businessSuccess && subscriptionDetail) {
      setPlan(subscriptionDetail?.data?.selected_plan?.subscription_features);
    }
  }, [businessSuccess]);

  useEffect(() => {
    handleCloseUpload();
    if (addError && addIsError) {
      setMessage(addError?.data?.message);
      setOpenError(true);
    }
  }, [addError, success, addIsError]);

  return (
    <Fragment>
      <div
        style={{
          backgroundColor: "#fff",
          boxShadow: "-2px 0px 5px rgba(22, 107, 142, 0.25)",
          color: "black",
          display: "flex",
          width: "100%",
          alignItems: "center",
          gap: "1rem",
          flexDirection: "row",
          padding: "1rem",
          position: "fixed",
          top: 0,
          zIndex: "100",
        }}
      >
        <CloseIcon
          onClick={() => history.push("/my-list")}
          style={{
            color: "black",
          }}
        />
        <p style={{ color: "black" }}>Edit Cover Photo</p>
      </div>

      <div
        style={{
          marginTop: "50px",
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        {cover.map((item, i) => (
          <div
            style={{
              position: "relative",
              display: "flex",
              justifyContent: "center",
              top: "20px",
            }}
            key={i}
          >
            {" "}
            <img
              src={item.cover_image}
              width={"92%"}
              height={"224px"}
              alt={item.business}
              style={{
                borderRadius: "20px",
                border: "4px solid rgba(22, 107, 142, 1)",
                marginBottom: "10px",
              }}
            />
            <Button
              className="delete-btn"
              onClick={() => {
                setImageId(item.id);
                setOpenModal(true);
              }}
            >
              <RiDeleteBin5Line />
            </Button>
          </div>
        ))}
      </div>
      {cover.length == 0 || coverPhoto ? (
        <div
          style={{
            marginTop: "2rem",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <input
            style={{
              display: "none",
            }}
            type="file"
            id="file"
            onChange={(e) => handleCoverImage(e)}
          />
          <label
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              cursor: "pointer",
              color: "#677eff",
              width: "92%",
              height: "3rem",
              border: "none",
              color: "info",
              backgroundColor: "#fff",
              border: "solid 3px #166B8E",
              borderRadius: "35px",
              padding: "8rem",
            }}
            htmlFor="file"
          >
            <span
              style={{
                width: "100%",
                borderRadius: "30px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <img src="/images/img-icon.png" />
              <p style={{ color: "#2182B1" }}>
                Add Cover Image for your business
              </p>
            </span>
          </label>
        </div>
      ) : null}

      {cover.length !== plan?.cover_images ? (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            margin: "2rem",
          }}
        >
          <Button
            variant="contained"
            style={{
              backgroundColor: theme.palette.secondary.main,
              borderRadius: "12px",
              height: "3rem",
            }}
            onClick={() => setCoverPhoto(true)}
          >
            Add {plan?.cover_images - cover.length} more images
          </Button>
        </div>
      ) : null}

      <Button
        startIcon={<BsArrowRight />}
        onClick={handleNext}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
          backgroundColor: theme.palette.secondary.main,
          borderRadius: "12px",
          height: "3rem",
          position: "fixed",
          bottom: "25px",
          right: "15px",
          minWidth: "0px",
          width: "50px",
          height: "50px",
          color: "white",
        }}
      ></Button>
      {openModal ? (
        <ImageRemovalModal open={openModal} handleClose={handleClose} />
      ) : null}
      {openError ? (
        <ErrorModal
          open={openError}
          handleClose={handleErrorModal}
          message={message}
        />
      ) : null}
      {openSucces ? (
        <SuccessModal
          open={openSucces}
          handleClose={handleSuccessModal}
          title="Ok"
          message="Cover photo edited successfully"
        />
      ) : null}
      {uploadModal ? (
        <UploadModal open={uploadModal} handleClose={handleCloseUpload} />
      ) : null}
    </Fragment>
  );
};

export default EditCoverPhoto;
