import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import terms from "../assets/terms.png";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "300px",
  background: "white",
  borderRadius: "15px",
  boxShadow: "inset 0 0 10px 6px rgb(165 156 162 / 88%)",
  height: "80vh",
  overflowY: "scroll",
  padding: "25px",
};

function TermsAndCondition({ open, handleClose }) {
  return (
    <Modal open={open} onClose={handleClose}>
      <Box sx={style}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            gap: "20px",
            marginBottom: "12px",
          }}
        >
          <Typography
            sx={{
              textAlign: "center",
              fontWeight: "700",
              fontSize: "20px",
              lineHeight: "22px !important",
            }}
          >
            Terms and <br></br> Conditions update
          </Typography>

          <img src={terms} width="100px" height="100px" alt="No Image" />
        </Box>
        <Typography>
          Any complaints or grievances regarding the use or information posted
          on this Site, may be sent to info@masterclick.in
        </Typography>
        <Typography>
          Any complaints or grievances regarding the use or information posted
          on this Site, may be sent to info@masterclick.in
        </Typography>
        <Typography>
          *This Site www.masterclick.provides the Site Owner Services to you
          ("you" or "your") subject to the terms of this legal agreement between
          you and Site Owner.To use the Services (defined below), you must first
          agree to and accept these Terms of Service and Use Agreement
          ("Terms").Where such express opportunity to agree to the Terms is not
          present, The User hereby agrees to familiarize himself/herself with
          the Terms as well as other terms and guidelines found throughout this
          Site and abide by them. If the User is dissatisfied with the Site or
          any portion thereof, or with any of these Terms, the User's sole
          remedy is to discontinue using this Site
        </Typography>
        <Typography>
          *.The User understands and agrees that Site Owner is a NETWORK SERVICE
          PROVIDER and an INTERMEDIARY within the meaning of Sec. 79 of the
          Indian Information Technology Act, 2000 and that the Site merely
          provides hosting services to its Registered Users and viewing
          facilities to persons browsing / visiting the Site. All the
          information posted therein has been posted by Registered Users and is
          third party user generated content. The Site Owner neither initiates
          the transmission nor selects the sender and receiver of the
          transmission nor selects / modifies the information contained in the
          transmission. The Site Owner has no control over the third party user
          generated content and shall not be liable for such content.
        </Typography>
        <Typography>
          * By using the Site and Services you are representing that you are of
          legal age (18 years and over) and of legal If you are not able to make
          the representations in the preceding sentence you are prohibited from
          using the Site and Services, By using the Services on behalf of any
          third party you are representing to us that you are an authorized
          representative of that third party and that your use of the Services
          represents their acceptance of the Terms
        </Typography>
        <Typography>* CHANGES TO THESE TERMS</Typography>
        <Typography>
          *.We may periodically modify and supplement these Terms and the notice
          provided to you will be the updating of these Terms. You are
          responsible for regularly checking these Terms for revisions. All
          amended Terms become effective upon our posting to the Site, and any
          use of the Site or Services after such revisions have been posted
          signifies your consent and agreement to the modified Terms.
        </Typography>
        <Typography>
          * From time to time and without prior notice to you, we may change,
          expand and improve the Site and Services. We may also, at any time,
          cease to continue operating part or all of the Services or selectively
          disable certain aspects of the Services or portions of the Site. Any
          modification or elimination of the Services will be done in our sole
          and absolute discretion and without an ongoing obligation or liability
          to you, and your use of the Site and Services do not entitle you to
          the continued provision or availability of the Site and Services.
        </Typography>
        <Typography>
          * You may be required to register and create an account ("Account")
          with Site Owner in order to access certain features of the Site and
          Services. Information gathered through the registration process and
          information related to your account will be subject to these Terms as
          well as to our Privacy Policy. You represent and warrant that all
          information provided by you when creating an Account is true, accurate
          and complete and that you will maintain, at all times, true, accurate
          and complete information related to your Account. You represent and
          warrant that you are at least 18 years old. Information related to
          your Account should be maintained by you in a confidential manner, as
          you are solely responsible for the usage of your Account by any third
          parties with respect to the Services. It is your responsibility to
          advise us if you are aware of any unauthorized access to your Account
          or if your Account information has been made available by you to
          third-parties in a manner that may result in unauthorized usage of the
          Account. In our sole and absolute discretion, we may terminate your
          Account for any reason (including for reasons related to unlawful or
          unauthorized usage) and we are under no obligation to retain a record
          of your Account or any data or information that you may have stored by
          means of the Account and the Services.from the Site without the prior
          written consent of Site Owner. In addition, you will not use the
          Material for any unauthorized non-commercial marketing and promotional
          campaigns, target or mass solicitation campaigns or political
          campaigning. You acknowledge that your use of the Site and services to
          enable the transmission of unsolicited voice messages, facsimiles or
          emails is in violation of these terms and may be in violation of
          united federal and state laws and regulations that may subject you to
          penalties under applicable laws.
        </Typography>
        <Typography>
          from the Site without the prior written consent of Site Owner. In
          addition, you will not use the Material for any unauthorized
          non-commercial marketing and promotional campaigns, target or mass
          solicitation campaigns or political campaigning. You acknowledge that
          your use of the Site and services to enable the transmission of
          unsolicited voice messages, facsimiles or emails is in violation of
          these terms and may be in violation of united federal and state laws
          and regulations that may subject you to penalties under applicable
          laws.
        </Typography>
        <Typography>
          Software or routine to interfere or attempt to interfere with the
          proper functioning and display of the Site and proper operation and
          usage of the Services by any other authorized users and third parties.
        </Typography>
        <Typography>
          • You are not permitted to script searches or search results of the
          Site in a manner that results in the automated display of Material on
          a third party Site.
        </Typography>
        <Typography>
          *.You are under no obligation to use or continue to use the Site and
          Services and may temporarily or permanently cease using the Site and
          Services without notice to Site Owner. Any use of the Site and
          Services, or any portion thereof, in violation of the foregoing shall
          constitute a violation of these Terms and may result in, among other
          things, termination or suspension of your rights to use the Site or
          any portion thereof. If you see any objectionable content on the Site,
          please contact us promptly in writing to the address listed in section
          21.4 of these Terms. <br />* use any meta tags or any other "hidden
          text" utilizing any Site Owner name or mark, and related marks or any
          marks in our Intellectual Property portfo
        </Typography>
        <Typography>CONTENT LICENSE FROM YOU</Typography>
        <Typography>
          *.You agree and acknowledge that Site Owner and its affiliates may
          modify, adapt, reformat, and otherwise alter or make use of your User
          Generated Content in such manner as may be required to conform User
          Generated Content to standards, protocols, formats and requirements
          related to the Site and Services and any medium by which they are
          accessible currently or prospectively. You agree and acknowledge that
          Site Owner and its affiliates are licensed to transmit or distribute
          the User Generated Content in all formats and mediums over various
          networks.
        </Typography>
        <Typography>
          *.Site Owner is under no obligation to review any User Generated
          Content submitted, posted or otherwise displayed on the Site and
          assumes no responsibility or liability relating to any such User
          Generated Content. You may not imply that any User Generated Content
          is any way sponsored or endorsed by , Site Owner.
        </Typography>
        <Typography>
          *.Site Owner reserves the right, but not the obligation, to refuse to
          post or remove any User Generated Content at our sole and absolute
          discretion. Reasons we may refuse to post or remove such User
          Generated Content may include, without limitation, any of the
          following:
        </Typography>
        <Typography>
          *Any content that is unlawful, harmful, offensive, threatening,
          abusive, harassing, invasive of privacy or publicity rights,
          defamatory, libelous, vulgar, obscene, pornographic, indecent, lewd,
          suggestive, scantily-clad, profane, hateful, racially, ethnically or
          otherwise objectionable or inappropriate material of any kind,
          including, but not limited to, any material which encourages conduct
          that would constitute a criminal *Any content that is unlawful,
          harmful, offensive, threatening, abusive, harassing, invasive of
          privacy or publicity rights, defamatory, libelous, vulgar, obscene,
          pornographic, indecent, lewd, suggestive, scantily-clad, profane,
          hateful, racially, ethnically or otherwise objectionable or
          inappropriate material of any kind, including, but not limited to, any
          material which encourages conduct that would constitute a criminal{" "}
          <br /> Accusations and other assertions of unlawful conduct, including
          occurrences of illegal activity, malpractice, purposeful overcharging,
          or health code violations, or false, misleading, deceptive or
          fraudulent advertising, including savings claims that do not offer
          provable savings
        </Typography>
        <Typography>
          * Any content that may infringe any patent, trademark, trade secret,
          copyright or other intellectual or proprietary right of any party
        </Typography>
        <Typography>
          * Any content that makes reference to or involves gambling, casinos,
          off-track betting, or other gambling of any form.
        </Typography>
        <Typography>
          1.Any content that involves alcohol, tobacco or weaponry, or that
          promotes or has a connotation to any illegal activity.
        </Typography>
        <Typography>
          2.Reviews submitted by the reviewed business's owners, employees(past
          or present), agents, affiliates and competitors.
        </Typography>
        <Typography>
          3.Reviews that are exclamatory or otherwise appear to by purposefully
          deceptive, as determined by Site Owner in its sole discretion.
        </Typography>
        <Typography>
          4.Messages that are promotional or commercial in nature, or are
          inappropriate based on the applicable subject matter, location or
          topic.
        </Typography>
        <Typography>
          5.Language that violates the standards of this Site, the Services and
          policies of Site Owner and its affiliates as determined by Site Owner
          in its sole discretion
        </Typography>
        <Typography>
          6.Any content that impersonates, disguises or conceals any person or
          entity or otherwise misrepresents a user's affiliation with a person
          or entity.
        </Typography>
        <Typography>
          7.Content that is not readable, that is encrypted or that contains
          viruses, malware, trojan horses, worms, time bombs, cancelbots or
          other computer programming routines and code that are intended to
          interfere with, damage, erase, intercept or appropriate any system,
          data or personal information.
        </Typography>
        <Typography>
          8.Any content that links (or includes embedded links) to any of the
          foregoing, or that link to social networking sites
        </Typography>
        <Typography>
          NO ASSURANCES/ NO ENDORSEMENTS MADE BY SITE OWNER/ CONTENT DISCLAIMER
        </Typography>
        <Typography>
          * Site Owner communicates information provided and created by
          advertisers, content partners, software developers, publishers,
          marketing agents, employees, users, resellers and other Third Parties.
          We have no control over the accuracy of such information provided by
          Third Parties, and Material on the Site may include technical
          inaccuracies or typographical errors.
        </Typography>
        <Typography>
          * We make no guarantees, nor can we be responsible for any information
          accessible on the Site or included in the Material, including the
          currency, content, quality, copyright compliance or legality of such
          information, nor are we responsible for any resulting loss or damage
          relating to the use of such information. We reserve the right, in our
          sole discretion and without any obligation, to make improvements to,
          or correct any error or omissions in, any portion of the information
          accessible on the Site. Where appropriate, we will endeavor to update
          information listed on the Site on a timely basis, but shall not be
          liable for any delay or inaccuracies related to such updates.
        </Typography>
        <Typography>
          * Site Owner does not make any representations regarding the
          availability and performance of the Site or any of the Third Party
          Sites to which we link. When you click on advertiser banners, sponsor
          links, or other external links from the Site, your browser
          automatically may direct you to a new browser window that is not
          hosted or controlled by Site Owner. Site Owner and its affiliates are
          not responsible for the content, functionality, or technological
          safety of these external sites.
        </Typography>
        <Typography>
          * The Site, Services and any Third Party Sites may contain content
          provided by advertisers or other Third Parties that may not be
          suitable for children. Site Owner does not knowingly collect personal
          information from children through the Site.
        </Typography>
        <Typography>PAYMENT</Typography>
        <Typography>
          *Where Subscription Fees accrue it shall be payable at or within such
          time as stated in the invoice(s) issued by the Site Owner to the User.
          The Subscription Fees shall be paid by the User on demand. In case the
          user disputes the same for any reason whatsoever, he shall make the
          payment towards the Subscription Fees accrued till the resolution of
          the dispute. In the event of Site Owner deciding the dispute in the
          User's favour, the Company shall refund to the User any excess amount
          paid by the User free of interest. In case of any delay in the payment
          by the User of any sums due, Site Owner reserves the right to collect
          outstanding amounts from the date payment is due to the date of final
          payment by the User. <br /> Owner does and may take measures to remove
          Material and User Generated Content at the request of the advertiser
          who has decided to "opt-out"12.3.Site Owner disclaims, , any and all
          warranties, representations and endorsements, express or implied,
          including any warranties for any information, goods, or services,
          obtained through, advertised or received through any links provided by
          or through the Site; or regarding the security, reliability,
          timeliness, accuracy and performance of the Site, services and
          material.
        </Typography>
        <Typography>
          by or through the Site; or regarding the security, reliability,
          timeliness, accuracy and performance of the Site, services and
          material.
        </Typography>
        <Typography>PRIVACY POLICY</Typography>
        <Typography>* Personal Information</Typography>
        <Typography>
          * Non-Account Activity. No information / registration details are
          required to access the Site.
        </Typography>
        <Typography>
          * Account-Related Activity. Certain other activities on the Site like
          uploading information require the User to have a Registered User
          Account.
          <br /> The User is required to provide some personal information when
          the User creates an account, which personal information includes the
          User's mobile no/email address, which is used to protect the User's
          account from unauthorized access. A Registered User Account allows the
          User to access many of the Site's services that require registration.
        </Typography>
        <Typography>
          * Content Uploaded to Site. Any personal information or information
          that the User voluntarily discloses online becomes publicly available
          and can be collected and used by others
        </Typography>
        <Typography>
          * The User may update or correct the User's personal profile
          information at any time.
        </Typography>
        <Typography>TERMINATION</Typography>
        <Typography>
          * We may terminate this Agreement, or terminate or suspend your access
          to the Services, at any time, with or without notice or cause. Upon
          termination or suspension, your right to access the Site and use the
          Service will immediately cease. We may discontinue, temporarily or
          permanently, the Services, with or without notice.
        </Typography>
        <Typography>
          * We reserve the right to terminate, suspend, or restrict your access
          to the Site and your use of the Services, in whole or in part,
          unilaterally and without notice, in the event that you violate any of
          the Terms. If asked to do so, you agree that you will not attempt to
          access the Site or attempt to utilize the Services by any alias or
          other disguised or fraudulent identity.
        </Typography>
        <Typography>
          * The Site, these Terms and the User's use of the Site is governed
          solely by Indian law irrespective of where the whole or any part of
          the data or Site may be physically located and without regard to
          conflict of law principles in other jurisdictions. Use of this Site is
          unauthorized and prohibited in any jurisdiction that does not give
          effect to all provisions of these Terms including without limitation,
          this paragraph. The Site Owner accepts no liability whatsoever, direct
          or indirect for non-compliance by the User with the laws of any
          country. The User agrees that the Courts in Manjeri, (Malappuram Dt)
          Kerala, India, alone shall have jurisdiction with regard to any
          dispute between the User and the Site Owner excluding all other Courts
          anywhere in the world that may otherwise have had such jurisdiction.
        </Typography>
        <Typography>
          * Any waiver of any provision of the Terms will be effective only if
          in writing and signed by you and Site Owner. Our failure to enforce
          our rights and remedies available to us with respect your breach of
          these Terms shall not constitute a waiver of such breach nor of any
          prior, concurrent, or subsequent breach of the same or any other
          provision of
        </Typography>
        <Typography>
          We recommend that you retain a copy of these Terms in either
          electronic or tangible format for your subsequent reference. If you
          would like a printed copy of these Terms, it is your responsibility to
          use the print function available to you by means of your personal
          technology resources.
        </Typography>
      </Box>
    </Modal>
  );
}

export default TermsAndCondition;
