import { useMutation, useQuery, useQueryClient } from "react-query";
import { AddService, deleteService, editservice, getService } from "./serviceUrls";

const Get_service = (id) => {
  return useQuery(["serviceList"], () => getService(id));
};

const Add_service = () => {
  const queryClient = useQueryClient();
  return useMutation((data) => AddService(data), {
    onMutate: async (newData) => {
      queryClient.cancelQueries("serviceList");
      const prev = await queryClient.getQueryData("serviceList");
      return {
        prev,
        newData,
      };
    },
    onSettled: () => {
      queryClient.invalidateQueries("serviceList");
    },
    onError: (err, _, context) => {
      queryClient.setQueryData("serviceList", context.prev);
    },
  });
};
const Edit_service = () => {
  const queryClient = useQueryClient();
  return useMutation((data) => editservice(data), {
    onMutate: async (newData) => {
      queryClient.cancelQueries("serviceList");
      const prev = await queryClient.getQueryData("serviceList");
      return {
        prev,
        newData,
      };
    },
    onSettled: () => {
      queryClient.invalidateQueries("serviceList");
    },
    onError: (err, _, context) => {
      queryClient.setQueryData("serviceList", context.prev);
    },
  });
};

const Del_service = () => {
  const queryClient = useQueryClient();
  return useMutation((data) => deleteService(data), {
    onMutate: async (newData) => {
      queryClient.cancelQueries("serviceList");
      const prev = await queryClient.getQueryData("serviceList");
      return {
        prev,
        newData,
      };
    },
    onSettled: () => {
      queryClient.invalidateQueries("serviceList");
    },
    onError: (err, _, context) => {
      queryClient.setQueryData("serviceList", context.prev);
    },
  });
};

const useServiceQuery = {
  Get_service,
  Add_service,
  Del_service,
  Edit_service
};

export default useServiceQuery;
