import { Box, Modal, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import Slider from "@mui/material/Slider";
import { variableContext } from "../../context/VariableContex";
const style = {
  width: "100%",
  display: "flex",
  padding: "6px 0px",
  borderRadius: "12px",
  justifyContent: "space-between",
  gap: "2px",
  alignItems: "center",
};

function SliderModal() {
  const { setRadius, radius } = useContext(variableContext);

  const [value, setValue] = useState(10);

  const handleChange = (e, value) => {
    setValue(value);
  };

  const handleChangeCommitted = (e, value) => {
    setRadius(value);
  };

  useEffect(() => {
    setValue(radius);
  }, [radius]);

  return (
    <Box style={style}>
      <Slider
        value={value}
        aria-label="Default"
        valueLabelDisplay="auto"
        min={10}
        max={200}
        onChange={handleChange}
        onChangeCommitted={handleChangeCommitted}
        sx={{
          width: "78%",
          color: "white",
          "& .MuiSlider-rail": {
            height: "1px",
          },
          "& .MuiSlider-thumb": {
            height: "15px",
            width: "15px",
            "&:after": {
              border: "2px solid white !important",
              width: "25px !important",
              height: "25px !important",
              left: "48% !important",
              backgroundColor: "#ffffff70 !important",
            },
          },
        }}
      />{" "}
      <Typography sx={{ color: "white" }}>{`${value} Kms`}</Typography>
    </Box>
  );
}

export default SliderModal;
