import React, { Fragment, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ErrorModal from "../../Add business/ErrorModal";
import SuccessModal from "../../Add business/SuccessModal";
import useBusinessQuery from "../../../../../store/Bussiness/useBusinessQuery";
import { FaSave } from "react-icons/fa";
import { Box, Button, Typography } from "@mui/material";
import { RiDeleteBin5Line } from "react-icons/ri";
import ImageRemovalModal from "../../Add business/ImageRemovalModal";
import axios from "axios";
import UploadModal from "../../../../../components/UploadModal";
import S3 from "react-aws-s3";
import { Buffer } from "buffer";
window.Buffer = Buffer;

function EditShopImage() {
  const { id } = useParams();
  const history = useHistory();

  const [shopImage, setShopImage] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [openSucces, setOpensuccess] = useState(false);
  const [openError, setOpenError] = useState(false);
  const [plan, setPlan] = useState({});
  const [uploadModal, setUploadModal] = useState(false);

  const envConfig = process.env;

  const config = {
    bucketName: envConfig.REACT_APP_S3_BUCKET,
    region: envConfig.REACT_APP_S3_REGION,
    dirName: "master-click",
    accessKeyId: envConfig.REACT_APP_S3_ACCESS_KEY_ID,
    secretAccessKey: envConfig.REACT_APP_S3_SECRET_ACCESS_KEY,
  };
  const ReactS3Client = new S3(config);

  const { data: subscriptionDetail, isSuccess: subscriptionSuccess } =
    useBusinessQuery.Get_my_currentplan(id);

  const { data: businessDetails, isSuccess: businessSuccess } =
    useBusinessQuery.Get_my_bussiness_details(id);

  const {
    mutateAsync: EditBusiness,
    isSuccess: editSuccess,
    isError: editError,
  } = useBusinessQuery.Edit_business();

  useEffect(() => {
    if ((businessSuccess, businessDetails)) {
      setShopImage(businessDetails.data.image);
    }
  }, [businessSuccess, businessDetails]);

  const handleCloseUpload = () => {
    setUploadModal(false);
  };

  

  useEffect(() => {
    if (subscriptionSuccess || subscriptionDetail) {
      setPlan(subscriptionDetail?.data?.selected_plan?.subscription_features);
    }
  }, [subscriptionSuccess, subscriptionDetail]);

  const handleClose = async (value) => {
    if (value) {
      setShopImage("");
    }

    setOpenModal(false);
  };

  const handleSuccessModal = () => {
    if (plan.cover_images) {
      history.push(`/edit-coverimages/${id}`);
    } else if (plan.catalogue_images) {
      history.push(`/edit-catelogueimages/${id}`);
    } else {
      history.push("/my-list");
    }

    setOpensuccess(false);
  };

  const handleErrorModal = () => {
    setOpenError(false);
  };

  const handlePosterImage = async (e) => {
    if (e.target.files.length == 0) {
      return;
    } else {
      let url = null;
      setUploadModal(true);
      url = e.target.files[0];
      if (url !== null) {
        const data = await ReactS3Client.uploadFile(url);
        url = data.location;
        setShopImage(url);
        handleCloseUpload();
      }
    }
  };
  const handleAdd = async () => {
    try {
      let payload = { id, image: shopImage };
      if (businessDetails.data.name) {
        payload.name = businessDetails.data.name;
      }
      if (businessDetails.data.mobile_number) {
        payload.mobile_number = businessDetails.data.mobile_number;
      }
      if (businessDetails.data.type) {
        payload.type = businessDetails.data.type;
      }
      if (businessDetails.data.category) {
        payload.category = businessDetails.data.category;
      }
      if (businessDetails.data.country) {
        payload.country = businessDetails.data.country;
      }
      if (businessDetails.data.state) {
        payload.state = businessDetails.data.state;
      }
      if (businessDetails.data.district) {
        payload.district = businessDetails.data.district;
      }

      if (businessDetails.data.about_us) {
        payload.about_us = businessDetails.data.about_us;
      }
      if (businessDetails.data.post_office) {
        payload.post_office = businessDetails.data.post_office;
      }
      if (businessDetails.data.pin_code) {
        payload.pin_code = businessDetails.data.pin_code;
      }
      if (businessDetails.data.longitude) {
        payload.longitude = businessDetails.data.longitude;
      }
      if (businessDetails.data.latitude) {
        payload.latitude = businessDetails.data.latitude;
      }
      if (businessDetails.data.sub_category_id) {
        payload.sub_category = businessDetails.data.sub_category_id;
      }
      if (businessDetails.data.google_location_link) {
        payload.google_location_link =
          businessDetails.data.google_location_link;
      }
      if (businessDetails.data.whatsapp_number) {
        payload.whatsapp_number = businessDetails.data.whatsapp_number;
      }
      if (businessDetails.data.facebook) {
        payload.facebook = businessDetails.data.facebook;
      }
      if (businessDetails.data.instagram) {
        payload.instagram = businessDetails.data.instagram;
      }
      if (businessDetails.data.youtube) {
        payload.youtube = businessDetails.data.youtube;
      }

      if (businessDetails.data.website) {
        payload.website = businessDetails.data.website;
      }
      if (businessDetails.data.banner_image) {
        payload.banner_image = businessDetails.data.banner_image;
      }

      await EditBusiness(payload);
    } catch (error) {}
  };

  useEffect(() => {
    if (editSuccess) {
      setOpensuccess(true);
    }
    if (editError) {
      setOpenError(true);
    }
  }, [editSuccess, editError]);

  return (
    <Fragment>
      <div
        style={{
          backgroundColor: "#fff",
          boxShadow: "-2px 0px 5px rgba(22, 107, 142, 0.25)",
          color: "black",
          display: "flex",
          width: "100%",
          alignItems: "center",
          gap: "1rem",
          flexDirection: "row",
          padding: "1rem",
          position: "fixed",
          top: 0,
          zIndex: "100",
        }}
      >
        <ArrowBackIcon
          style={{
            color: "black",
          }}
          onClick={() => history.push("/")}
        />
        <p style={{ color: "black" }}>Edit Shop Image</p>
      </div>

      <div
        style={{
          marginTop: "70px",
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        {shopImage ? (
          <div
            style={{
              position: "relative",
              display: "flex",
              justifyContent: "center",
              top: "20px",
            }}
          >
            <img
              src={shopImage}
              width={"92%"}
              height={"224px"}
              style={{
                borderRadius: "20px",
                border: "4px solid rgba(22, 107, 142, 1)",
                marginBottom: "10px",
              }}
              alt="No Image"
            />
            <Button
              className="delete-btn"
              onClick={() => {
                setOpenModal(true);
              }}
            >
              <RiDeleteBin5Line />
            </Button>
          </div>
        ) : (
          ""
        )}
      </div>
      {!shopImage ? (
        <div
          style={{
            marginTop: "5rem",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <input
            style={{
              display: "none",
            }}
            type="file"
            id="file"
            accept=".jpg,.jpeg,.png"
            onChange={(e) => handlePosterImage(e)}
          />
          <label
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              cursor: "pointer",
              color: "#677eff",
              width: "92%",
              height: "3rem",
              border: "none",
              color: "info",
              backgroundColor: "#fff",
              border: "solid 3px #166B8E",
              borderRadius: "35px",
              padding: "8rem",
            }}
            htmlFor="file"
          >
            <i className=" fs-3 fa-solid fa-image"></i>
            <span
              style={{
                width: "100%",
                borderRadius: "30px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <img src="/images/img-icon.png" />
              <p style={{ color: "#2182B1" }}>Add shop image</p>
            </span>
          </label>
        </div>
      ) : (
        ""
      )}

      <Box
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginTop: "15px",
        }}
      >
        <Typography
          sx={{
            color: "#FF0000",
            fontSize: "12px !important",
            fontWeight: "600",
          }}
        >
          Note
        </Typography>
        <Typography
          sx={{
            color: "#000000",
            fontSize: "12px !important",
            fontWeight: "600",
          }}
        >
          : You can add shop image later
        </Typography>
      </Box>

      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
          margin: "2rem",
          position: "fixed",
          bottom: 0,
          width: "82%",
        }}
      >
        <div className="next-btn">
          <FaSave style={{ fontSize: "1.5rem" }} onClick={handleAdd} />
        </div>
      </div>
      {openModal ? (
        <ImageRemovalModal open={openModal} handleClose={handleClose} />
      ) : null}
      {openError ? (
        <ErrorModal open={openError} handleClose={handleErrorModal} />
      ) : null}
      {openSucces ? (
        <SuccessModal
          open={openSucces}
          handleClose={handleSuccessModal}
          title="Ok"
          message="Shop image edited successfully"
        />
      ) : null}
      {uploadModal ? (
        <UploadModal open={uploadModal} handleClose={handleCloseUpload} />
      ) : null}
    </Fragment>
  );
}

export default EditShopImage;
