import { Box, Modal, Radio, Typography } from "@mui/material";
import React, { useEffect } from "react";
import useCommonQuery from "../../store/common/useCommonQuery";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "300px",
  background: "white",
  boxShadow: "0px 0px 8px gainsboro",
  maxHeight: "90vh",
  border: "1px solid gainsboro",
  overflowY: "scroll",
  borderRadius: "30px",
  paddingTop: "10px",
};

function CategoryModal({ open, handleClose, setOpen, categoryId }) {
  const [selectedId, setSelectedId] = React.useState("");
  const { data: categoryList } = useCommonQuery.Get_category({
    search: "",
    limit: 2000,
  });

  const handleChange = (data) => {
    setSelectedId(data.id);
    handleClose(data);
  };

  useEffect(() => {
    setSelectedId(categoryId);
  }, []);

  return (
    <Modal open={open} onClose={() => setOpen(false)}>
      <Box sx={style}>
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Typography
            sx={{ textAlign: "center", fontSize: "20px", fontWeight: "600" }}
          >
            Select Category
          </Typography>

          {categoryList &&
            categoryList.data.results.map((item,i) => (
              <>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    padding: "0px 10px",
                  }}
                  onClick={() => handleChange(item)}
                  key={i}
                >
                  <Typography sx={{ fontsize: "14px", fontWeight: "600" }}>
                    {item.name}
                  </Typography>
                  <Radio
                    value={item.id}
                    checked={item.id == selectedId ? true : false}
                    name="radio-buttons"
                    inputProps={{ "aria-label": "A" }}
                  />
                </div>
                <hr />
              </>
            ))}
        </Box>
        <Typography
          sx={{
            position: "sticky",
            bottom: "0px",
            background: "rgb(33, 130, 177)",
            color: "white",
            textAlign: "center",
            paddingBottom:"10px"
          }}
        >
          If what you are looking for is not available,please select "other
          category"
        </Typography>
      </Box>
    </Modal>
  );
}

export default CategoryModal;
