import { Box, Typography } from "@mui/material";
import React, { Fragment, useState } from "react";
import app from "../../assets/app.png";
import gmail from "../../assets/gmail.png";
import insta from "../../assets/insta.png";
import meta from "../../assets/meta.png";
import yout from "../../assets/yout.png";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { useHistory } from "react-router-dom";
import TermsAndCondition from "../TermsAndCondition";
import About from "../About";

function BottomIcons() {
  const [openTerms, setOpenTerms] = useState(false);
  const [openAbout, setOpenAbout] = useState(false);

  const handleTermsAndCondition = () => {
    setOpenTerms(false);
  };

  const handleCloseAbout = () => {
    setOpenAbout(false);
  };

  const history = useHistory();
  return (
    <Fragment>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-evenly",
          alignItems: "center",
          margin: "10px 0px",
          padding: "0px 70px",
        }}
      >
        <div className="">
          <a
            style={{
              color: "white",
              textDecoration: "none",
              display: "flex",
            }}
            href={`https://wa.me/918943999997?text=Hello`}
          >
            <img src={app} alt="not found" width="25px" height="25px" />
          </a>
        </div>
        <div className="">
          <a
            style={{
              color: "white",
              textDecoration: "none",
              display: "flex",
            }}
            href="https://instagram.com/masterclickinfo?utm_source=qr&igshid=ZDc4ODBmNjlmNQ%3D%3D"
          >
            <img src={insta} alt="not found" width="25px" height="25px" />
          </a>
        </div>
        <div className="">
          <a
            style={{
              color: "white",
              textDecoration: "none",
              display: "flex",
            }}
            href="https://www.facebook.com/profile.php?id=100094627446669&mibextid=ZbWKwL"
          >
            <img src={meta} alt="not found" width="25px" height="25px" />
          </a>
        </div>
        <div className="">
          <a
            style={{
              color: "white",
              textDecoration: "none",
              display: "flex",
            }}
            href="https://www.youtube.com/@masterbooks6521/featured"
          >
            <img src={yout} alt="not found" width="25px" height="25px" />
          </a>
        </div>
        <div className="">
          <a
            style={{
              color: "white",
              textDecoration: "none",
              display: "flex",
            }}
          >
            <img src={gmail} alt="not found" width="25px" height="25px" />
          </a>
        </div>
      </Box>
      <Box sx={{ display: "flex", justifyContent: "space-evenly" }}>
        <div
          className="bottom_container_box"
          onClick={() => setOpenTerms(true)}
        >
          <ChevronRightIcon className="bottom_container_icon" />{" "}
          <Typography className="bottom_container">
            Terms and condition
          </Typography>
        </div>

        <div
          className="bottom_container_box"
          onClick={() => history.push("/subscription-plan")}
        >
          <ChevronRightIcon className="bottom_container_icon" />{" "}
          <Typography className="bottom_container">Subscription</Typography>
        </div>

        <div
          className="bottom_container_box"
          onClick={() => setOpenAbout(true)}
        >
          <ChevronRightIcon className="bottom_container_icon" />{" "}
          <Typography className="bottom_container">About Us</Typography>
        </div>
      </Box>
      {openTerms ? (
        <TermsAndCondition
          open={openTerms}
          handleClose={handleTermsAndCondition}
        />
      ) : null}
      {openAbout ? (
        <About open={openAbout} handleClose={handleCloseAbout} />
      ) : null}
    </Fragment>
  );
}

export default BottomIcons;
