import { red } from "@mui/material/colors";
import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#2182B1",
      // main: "##19857b",
    },
    secondary: {
      main: "#0E3A53",
      // main: "#19857b",
    },
    error: {
      main: "#F09630",
      // main: red.A400,
    },
    background: {
      default: "#fff",
      secondary: "#000",
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
          .pac-container {
            z-index: 1500 !important;
          }
        `,
    },
  },
});

export default theme;
