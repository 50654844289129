import React, { useContext, useEffect, useState } from "react";
import "./CompleteRegistration.css";
import {
  Box,
  Button,
  FormControl,
  Grid,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useHistory } from "react-router-dom";
import theme from "../../../../utils/theme";
import RegistrationSuccessModal from "../../../../components/registrationModal/RegistrationSuccessModal";
import RegistrationErrorModal from "../../../../components/registrationModal/RegistrationErrorModal";
import ReactGoogleAutocomplete from "react-google-autocomplete";
import useStyles from "../../../../SharedClass/sharedClass";
import { useFormik } from "formik";
import * as Yup from "yup";
import useAuthQuery from "../../../../store/auth/useAuthQuery";
import { variableContext } from "../../../../context/VariableContex";
import Flag from "../../../../assets/Flag.svg";
import axios from "axios";
import S3 from "react-aws-s3";
import { Buffer } from "buffer";
import UploadModal from "../../../../components/UploadModal";
window.Buffer = Buffer;

const modelSchema = Yup.object().shape({
  mobile: Yup.string().required("Phone number is required"),
  name: Yup.string().required("Name is required"),
});

const CompleteRegistration = () => {
  const history = useHistory();
  const classes = useStyles();
  const { setMobile, mobile, setLatitude, setLongitude, setLocationName } =
    useContext(variableContext);

  const [name, setName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [successModalOpen, setSuccessModalOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [openError, setOpenError] = useState(false);
  const [uploadModal, setUploadModal] = useState(false);

  const [file, setFile] = useState("");
  const [currentLocation, setCurrentLocation] = useState("");
  const [value, setValue] = useState("");
  const {
    mutateAsync: register,
    isSuccess,
    isError,
    error,
  } = useAuthQuery.Registration();

  const envConfig = process.env;

  const config = {
    bucketName: envConfig.REACT_APP_S3_BUCKET,
    region: envConfig.REACT_APP_S3_REGION,
    dirName: "master-click",
    accessKeyId: envConfig.REACT_APP_S3_ACCESS_KEY_ID,
    secretAccessKey: envConfig.REACT_APP_S3_SECRET_ACCESS_KEY,
  };
  const ReactS3Client = new S3(config);

  useEffect(() => {
    if (mobile) {
      setPhoneNumber(mobile);
    }
  }, [mobile]);

  const handleClose = () => {
    setName("");
    setPhoneNumber("");

    setOpenError(false);
  };

  const initialValues = {
    name,
    mobile: phoneNumber,
  };

  const Api_Key = "AIzaSyDMC5uUfcSScgVY9AGqsGQwYm8P6_LmyYg";

  const handleLocation = (place) => {
    let lat = place.geometry.location.lat();
    let long = place.geometry.location.lng();
    setLatitude(lat);
    setLongitude(long);
    setLocationName(place.name);
    setCurrentLocation(place.name);
  };

  const handleImage = async (e) => {
    if (e.target.files.length == 0) {
      return;
    } else {
      try {
        let url = null;

        setUploadModal(true);
        url = e.target.files[0];
        if (url !== null) {
          const data = await ReactS3Client.uploadFile(url);
          url = data.location;
          setFile(url);
          handleCloseUpload();
        }
      } catch (error) {
        handleCloseUpload();
      }
    }
  };
  const handleCloseUpload = () => {
    setUploadModal(false);
  };
  const formik = useFormik({
    initialValues,
    validationSchema: modelSchema,
    enableReinitialize: true,
    onSubmit: async (values, { setStatus, setSubmitting, resetForm }) => {
      setPhoneNumber(values.mobile);
      try {
        await register({
          name: values.name,
          mobile: values.mobile,
          image: file,
          location: currentLocation,
        });
      } catch (error) {
        setStatus("Something went wrong");
        setSubmitting(false);
        resetForm();
      }
    },
  });

  useEffect(() => {
    if (isSuccess) {
      setMobile(phoneNumber);
      setSuccessModalOpen(true);
    }
  }, [isSuccess]);

  useEffect(() => {
    if (isError) {
      setMessage(error?.response?.data?.message);
      setOpenError(true);
    }
  }, [isError]);
  return (
    <>
      <RegistrationSuccessModal
        Toggle={successModalOpen}
        setToggle={setSuccessModalOpen}
      />
      {openError ? (
        <RegistrationErrorModal
          Toggle={openError}
          handleClose={handleClose}
          message={message}
          title="Error"
        />
      ) : null}

      <div
        className="completeRegistration-bg-screen"
        style={{ backgroundColor: theme.palette.primary.main }}
      >
        <div className="">
          <ArrowBackIcon
            onClick={() => history.push("/")}
            style={{
              color: "#fff",
              position: "relative",
              top: "2rem",
              left: "1rem",
            }}
          />
        </div>
        <form onSubmit={formik.handleSubmit} noValidate>
          <div className="completeRegistration-bottomBox">
            <div className="completeRegistration-title">
              <Typography
                style={{
                  fontSize: "31px",
                  fontWeight: "600",
                }}
              >
                Complete Registration
              </Typography>
              <Typography style={{ fontSize: "16px", fontWeight: "400" }}>
                Complete your sign-up and start discovering the best local
                businesses near you.
              </Typography>
            </div>

            <div className="completeRegistration-form-body">
              <Typography className="title">Name</Typography>
              <input
                placeholder="Name"
                className="reg-inp"
                {...formik.getFieldProps("name")}
              />
              {formik.touched.name && formik.errors.name ? (
                <div className="error-message">{formik.errors.name}</div>
              ) : (
                ""
              )}
              <Typography className="title">Phone Number</Typography>
              <div className="dropdown-mob-num">
                <FormControl>
                  <Select
                    value={value}
                    displayEmpty
                    sx={{
                      height: "51px",
                      borderTopLeftRadius: "70px",
                      borderBottomLeftRadius: "70px",
                      "& .MuiSelect-select": {
                        marginTop: "4px",
                      },
                      "& .MuiSelect-icon.Mui-disabled": {
                        color: "black !important",
                      },
                    }}
                    disabled
                  >
                    <MenuItem value="" sx={{ marginTop: "4px" }}>
                      <img src={Flag} />
                    </MenuItem>
                  </Select>
                </FormControl>
                <input
                  className="inp-number"
                  {...formik.getFieldProps("mobile")}
                />
              </div>
              {formik.touched.mobile && formik.errors.mobile ? (
                <div className="error-message">{formik.errors.mobile}</div>
              ) : (
                ""
              )}
            </div>
            <Box className="completeRegistration-form-body">
              <Grid container columnSpacing={2}>
                <Grid item xs={12}>
                  <Typography className="title">Current Location</Typography>
                </Grid>

                <Grid item xs={12}>
                  <ReactGoogleAutocomplete
                    apiKey={Api_Key}
                    options={{
                      types: [
                        "neighborhood",
                        "locality",
                        "sublocality",
                        "postal_code",
                        "point_of_interest",
                      ],
                      componentRestrictions: { country: "in" },
                      fields: [
                        "address_components",
                        "geometry",
                        "icon",
                        "name",
                        "place_id",
                        "formatted_address",
                      ],
                    }}
                    name="location"
                    onPlaceSelected={(place) => handleLocation(place)}
                    style={{
                      width: "100%",
                      height: "48px",
                      border: "1px solid white",
                      borderRadius: "50px",
                      paddingLeft: "15px",
                      boxShadow: "-2px 0px 5px rgba(22, 107, 142, 0.25)",
                    }}
                  />
                </Grid>
              </Grid>
            </Box>
            <div className="completeRegistration-form-body">
              <Typography className="title" style={{ marginTop: "10px" }}>
                Profile Image
              </Typography>
              <input
                placeholder="Name"
                className="reg-inp"
                type="file"
                onChange={(e) => handleImage(e)}
              />
            </div>
            <div className="completeRegistration-btn-body2">
              <Button
                style={{ backgroundColor: theme.palette.secondary.main }}
                className="completeRegistration-btn2"
                variant="contained"
                type="submit"
              >
                Complete Registration
              </Button>
            </div>
          </div>
        </form>
        {uploadModal ? (
          <UploadModal open={uploadModal} handleClose={handleCloseUpload} />
        ) : null}
      </div>
    </>
  );
};

export default CompleteRegistration;
