import { Box, Button, Modal, Typography } from "@mui/material";
import React from "react";
import Error from "../../../../assets/Error.png";
import "./AddBusiness.css";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%",
  background: "white",
  padding: "24px 30px",
  borderRadius: "24px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  height: "252px",
};

const ErrorModal = ({ open, handleClose,message = " Oops,Something went wrong! Please try again later"}) => {
  return (
    <Modal open={open}>
      <Box style={style}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <img src={Error} width="55px" height="55px" />
          <Typography
            sx={{
              color: "rgba(240, 150, 48, 1)",
              fontSize: "16px",
              fontWeight: "600",
            }}
          >
            Error
          </Typography>
        </Box>
        <Typography
          sx={{
            fontSize: "16px",
            fontWeight: "600",
            color: "rgba(14, 58, 83, 1)",
            textAlign: "center",
            textTransform:"capitalize"
          }}
        >
         {message}
        </Typography>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Button className="try-button" onClick={() => handleClose()}>
            Try Again
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default ErrorModal;
