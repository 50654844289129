import DataService from "../Dataservice";

const getProfile = async () => {
  let url = "/auth/register/update/";
  const data = await DataService.get(url);
  return data?.data;
};
const updateProfile = async (values) => {
  let url = "/auth/register/update/";
  const data = await DataService.patch(url, values);
  return data?.data;
};

export { getProfile ,updateProfile};
