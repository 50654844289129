import React, { useEffect, useState } from "react";
import "./authHome.css";
import { Button } from "@mui/material";
import theme from "../../../utils/theme";
import { useHistory } from "react-router-dom";
import blue from "../../../assets/blue.png";
import TermsAndCondition from "../../../components/TermsAndCondition";

const AuthHome = () => {
  
  const history = useHistory();
  const [openTerms, setOpenTerms] = useState(false);

  const handleTermsAndCondition = () => {
    setOpenTerms(false);
  };

  const user = JSON.parse(localStorage.getItem("user"));
 
 

  useEffect(() => {
    if (user?.id) {
      history.push("/");
    }
  }, [user]);

  return (
    <>
      <div className="">
        <div className="bg-screen">
          <div className="home-bg">
            <img width="221px" height="40px" src={blue} alt="no image" />
          </div>
          <div className="bottomBox">
            <div className="btn-body">
              <Button
               id="installApp"
                style={{
                  backgroundColor: theme.palette.secondary.main,
                  boxShadow: "2px 2px 2px rgb(14, 58, 83)",
                }}
                className="lag-btn3"
                variant="contained"

                onClick={() => history.push("/sign-in")}
              >
                Sign In
              </Button>
              <Button
                style={{
                  backgroundColor: theme.palette.background.default,
                  color: theme.palette.secondary.main,
                  boxShadow: "2px 2px 2px #ffff",
                }}
                className="lag-btn3"
                variant="contained"
                onClick={() => history.push("/sign-up")}
              >
                Sign Up
              </Button>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                color: "#fff",
              }}
            >
              or
            </div>
            <div className="btn-body2">
              <Button
                onClick={() => history.push("/")}
                className="lag-btn2"
                variant="outlined"
              >
                Continue without Login
              </Button>
            </div>
            <div
              style={{ display: "flex", justifyContent: "center" }}
              className="btn-body2"
            >
              <Button
                className="lag-btn4"
                variant="outlined"
                onClick={() => setOpenTerms(true)}
              >
                Terms and conditions
              </Button>
             
            </div>
          </div>
        </div>
      </div>
      {openTerms ? (
        <TermsAndCondition
          open={openTerms}
          handleClose={handleTermsAndCondition}
        />
      ) : null}
    </>
  );
};

export default AuthHome;
