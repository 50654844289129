import * as React from "react";
import IndexRouter from "./router/indexRouter";
import { BrowserRouter as Router } from "react-router-dom";
import "./App.css";
import video from "../src/assets/video.mp4";
import InstallModal from "./components/InstallModal/Index";

function App() {
  const [loading, setLoading] = React.useState(true);
  // const [openModal, setOpenModal] = React.useState(false);
  // const [open, setOpen] = React.useState(false);

  React.useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 3200);
  }, [loading]);

  window.addEventListener("beforeinstallprompt", async (e) => {
    const relatedApps = await navigator.getInstalledRelatedApps();
    const PWAisInstalled = relatedApps.length > 0;
    if (!PWAisInstalled) {
      let deferredPrompt = e;

      const installButton = document.createElement("button");
      installButton.textContent = "Continue To App";
      installButton.style.position = "fixed";
      installButton.style.top = "50%";
      installButton.style.padding = "10px 20px";
      installButton.style.color = "white";
      installButton.style.border = "none";
      installButton.style.borderRadius = "5px";
      installButton.style.cursor = "pointer";
      installButton.style.width = "100%";
      installButton.style.height = "50px";
      installButton.style.backgroundColor = "rgb(33, 130, 177)";
      installButton.style.fontSize = "14px";
      installButton.style.fontWeight = "bold";
      installButton.style.zIndex = "9999";

      installButton.addEventListener("click", () => {
        deferredPrompt.prompt();

        deferredPrompt.userChoice.then((choiceResult) => {
          if (choiceResult.outcome === "accepted") {
            console.log("App installed");
          } else {
            console.log("App installation declined");
          }

          installButton.style.display = "none";
        });
      });

      document.body.appendChild(installButton);
    }
  });

  return (
    <React.Fragment>
      <Router>
        <IndexRouter />
      </Router>
    </React.Fragment>
  );
}

export default App;
