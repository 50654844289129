import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import React from "react";
import { Button } from "@mui/material";
import "./RegistrationSuccessModal.css";
import theme from "../../utils/theme";

const style = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};

const RegistrationErrorModal = (props) => {
  return (
    <div>
      <Modal
        style={style}
        open={props.Toggle}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="modal-body">
          <img src="/images/Error.png" />
          <Typography
            style={{ color: theme.palette.error.main }}
            id="modal-modal-title"
            variant="h6"
            component="h2"
          >
           {props.title}
          </Typography>
          <Typography
            id="modal-modal-description"
            style={{ textAlign: "center", textTransform: "capitalize" }}
          >
            {props.message}
          </Typography>
          <Button
            className="btn-reg-success"
            variant="contained"
            style={{ backgroundColor: theme.palette.error.main }}
            onClick={() => props.handleClose()}
          >
            Try Again
          </Button>
        </Box>
      </Modal>
    </div>
  );
};

export default RegistrationErrorModal;
